import gql from 'graphql-tag';

export default gql`
  query Map {
    currentCampsite @client {
      id
      name
      lat
      lng
      zoomLevel
      zoomTo
    }
  }
`;
