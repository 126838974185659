import React from 'react';
import PropTypes from 'prop-types';

import StyledFlexRow from './FlexRow.style';

const propTypes = {
  children: PropTypes.node.isRequired,
  invert: PropTypes.bool,
  chunk: PropTypes.bool,
};

const defaultProps = {
  invert: false,
  chunk: false,
};

function FlexRow({ children, invert, chunk }) {
  return (
    <StyledFlexRow invert={invert} chunk={chunk}>
      {children}
    </StyledFlexRow>
  );
}

FlexRow.propTypes = propTypes;
FlexRow.defaultProps = defaultProps;

export default FlexRow;
