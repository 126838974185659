import styled from 'styled-components';
import zIndex from '../../config/zIndex';

export const Container = styled.div`
  position: relative;
`;

export const Wrapper = styled.div`
  position: absolute;
  max-height: 300px;
  width: 100%;
  z-index: ${zIndex.POPOVER};
`;

export const List = styled.ul`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  margin: 0;
  overflow-y: auto;
  padding: 1rem 0.5rem;
`;

export const Button = styled.button`
  width: 100%;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem 1rem;
  text-align: left;
  background-color: ${({ active, theme }) => (active ? theme.COLOR_GRAY_LIGHTER : theme.COLOR_WHITE)};
  transition: all 250ms ease;

  &:hover {
    background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHTER};
  }
`;
