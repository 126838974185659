import gql from 'graphql-tag';

export default gql`
  query UkItineraries {
    UkItineraries @rest(
      type: "UkItineraries",
      path: "Tour/AllSuggestedUkItineraries"
    ) {
      suggestedUkItineraries {
        id
        backgroundImageUrl
        campsiteIds
        country
        descriptionTitle
        descriptionText
        idealFor
          name
          icon
        itineraryCategory
        itineraryLength
        itineraryLengthTitle
        location
          location
          lon
          zoomLevel
        name
        whatToExpectTitle
        whatToExpect
      }
    }
  }
`;
