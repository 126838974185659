import gql from 'graphql-tag';
import { stringify } from 'query-string';

export function getCampsiteLocationsOptions({ query }) {
  function pathBuilder({ args }) {
    return `campsite/locations?${stringify(args)}&page=0&pageSize=10000`;
  }

  return {
    variables: {
      pathBuilder,
      features: query.features,
      types: query.types,
    },
  };
}

export default gql`
  query MapMarkers {
    campsiteLocations (
      features: $features,
      types: $types
    ) @rest(
      type: "Campsite",
      pathBuilder: $pathBuilder
    ) {
      id
      eventTypes
      name
      lat
      lon
      type
      zoomLevel
    }
  }
`;
