import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { compose, graphql, withApollo } from 'react-apollo';
import { withRouter } from 'next/router';
import { format } from 'date-fns';

import { isTabletOrSmaller } from '../../lib/helpers/layout';
import { formatMMtoM } from '../../lib/format';
import queryHelper from '../../lib/queryHelper';
import getCampsiteType, { checkToShowPricesAndRatings, isCampsiteOverseas } from '../../lib/campsiteTypes';
import updateRouterQuery from '../../lib/updateRouterQuery';
import { closeDetailsQuery } from '../../lib/campsiteSearch';
import scrollEvent from '../../lib/scrollEvent';
import isLoggedIn, { isLoggedInMember } from '../../lib/isLoggedIn';

import theme from '../../styles/config/theme';

import withPhase from '../../hocs/withPhase';
import { dictionaryItem, mapDictionaryItems } from '../../hocs/withDictionary';

import PricesReviewsBookNow from './PricesReviewsBookNow';
import ListingResultHeader from './ListingResultHeader';
// eslint-disable-next-line no-unused-vars
import PricesAndDirections from './PricesAndDirections';
import AddCampsiteToCompareBtn from '../Comparison/AddCampsiteToCompareBtn';
import CampsiteFeature from '../CampsiteFeature';
import CampsiteDetailsTopFeatures from '../CampsiteDetails';
import CampsiteType from '../CampsiteType';
import Carousel from '../Carousel';
import ErrorTypeMessage from '../ErrorTypeMessage';
import ListingResultLoading from './ListingResultLoading';
import PriceCampsiteQuery from '../Price/PriceCampsiteQuery';
import Note from '../Note';
import ReadMore from '../ui/ReadMore';
import SiteCard from '../SiteCard';
import SpecialOffers from '../SpecialOffers';
import Text from '../ui/Text';
import FlexTable, { FlexCell, FlexRow } from '../ui/FlexTable';
import { Flex } from '../ui/Grid/Row.style';
import CampsiteFeatureLink from '../CampsiteFeature/CampsiteFeatureLink';
import { List } from '../CampsiteFeature/CampsiteFeature.style';
import {
  campsiteFeatureIds,
  getCampsiteFeatureByTag,
  getCampsiteFeatureById,
  mergeFeatures,
} from '../../lib/helpers/campsites';
import FEATURE_TYPES from '../../config/featureTypes';
import fetchPolicy from '../../constants/FetchPolicy';

import ListingResultStyled, {
  Body,
  Tile,
  TileIcon,
  TileNumber,
  Tiles,
  TileText,
  Title,
  ViewOnMap,
} from './ListingResult.style';

import { DATE_FORMAT_INPUT } from '../../config/locale';

import svgArrowLeft from '../../static/images/icons/ArrowLeft.svg';
import svgArrowRight from '../../static/images/icons/ArrowRight.svg';
import ToursEvent from '../../static/images/icons/ToursEvent.svg';
import CampingEvent from '../../static/images/icons/CampingEvent.svg';
import GlampingEvent from '../../static/images/icons/GlampingEvent.svg';

import GET_CAMPSITE, {
  getCampsiteOptions,
} from '../../config/graphql/getCampsite';
import GET_CONFIGURATION from '../../config/graphql/getConfiguration';
import GET_USER from '../../config/graphql/getUser';
import scrollToTop from '../../lib/scrollToTop';
import { dataLayerManager } from '../../lib/dataLayer';
import IbePropTypes from '../../IbePropTypes';
import { ids as campsiteIds } from '../../config/campsiteTypes';
import RevealAddress from './RevealAddress';
import routes from '../../constants/routes';
import PLACE_EVENT_TYPES, { SINGLE_EVENT_TYPE } from '../../config/eventTypes';
import { navigateTo, POST_TYPES } from '../../lib/helpers/navigation';
import { FEATURE_FLAGS } from '../../config/featureFlags';
import { formatHourToTime } from '../../lib/helpers/availability';
import { addTiming, startTiming, types } from '../../lib/timings';
import { DEFAULT_POI_ZOOM_LEVEL } from '../CampsiteMap/MapMarkers';
import { campsiteImageKeys, mapAlternativeImages } from '../Picture/pictureHelper';

class ListingResult extends Component {
  static propTypes = {
    client: PropTypes.shape(IbePropTypes.client).isRequired,
    configuration: PropTypes.shape(IbePropTypes.configuration),
    data: PropTypes.shape({
      error: PropTypes.shape(IbePropTypes.apolloError),
    }),
    error: PropTypes.shape(IbePropTypes.apolloError),
    onMount: PropTypes.func,
    loading: PropTypes.bool,
    price: PropTypes.shape(IbePropTypes.price),
    router: PropTypes.shape(IbePropTypes.router),
    query: PropTypes.shape(IbePropTypes.query),
    campsite: PropTypes.shape(IbePropTypes.campsite),
    onQueryChange: PropTypes.func.isRequired,
    reviews: PropTypes.string,
    SHOW_PITCH_TYPES: PropTypes.bool.isRequired,
    siteCode: PropTypes.string,
    toggleBasket: PropTypes.func,
    campsiteId: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired,
    selectedEvent: PropTypes.shape(IbePropTypes.event),
    onSelectEvent: PropTypes.func.isRequired,
    onProductTypeMismatch: PropTypes.func,
    user: PropTypes.shape(IbePropTypes.user),
    toggleMobileMap: PropTypes.func.isRequired,
    isMobileMapOpen: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    campsite: undefined,
    configuration: {},
    data: null,
    error: null,
    onMount() {},
    loading: false,
    price: {
      cheapestMemberPrice: null,
      offers: null,
      cheapestStandardPrice: null,
    },
    router: {
      query: {
        siteCode: '',
      },
    },
    query: null,
    reviews: '',
    siteCode: '',
    selectedEvent: null,
    toggleBasket: () => {},
    onProductTypeMismatch: null,
    user: null,
  };

  constructor(props) {
    super(props);

    this.observer = null;
    this.headerElement = null;
    this.headerElementHeight = null;

    this.state = {
      revealAddress: this.checkToHideAddress(),
      headerOffset: 0,
    };
  }

  componentDidMount() {
    if (this.props.campsite) {
      const result = { ...this.props.campsite };
      this.props.onMount(result);
    }

    if ('IntersectionObserver' in window) {
      this.headerElement = document.querySelector('.header');
      if (this.headerElement) {
        this.headerElementHeight = this.headerElement.getBoundingClientRect().height;
        this.setState({ headerOffset: this.headerElementHeight });
        this.observer = new IntersectionObserver(this.observerCallback, {
          threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
        });
        this.observer.observe(this.headerElement);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Handle loading
    if (nextProps.loading !== this.props.loading) return true;
    if (nextProps.reviews !== this.props.reviews) return true;
    if (nextProps.selectedEvent?.id !== this.props.selectedEvent?.id) return true;
    if (
      nextProps.selectedEvent?.cheapestMemberPrice !==
      this.props.selectedEvent?.cheapestMemberPrice
    ) return true;

    // Handle open or closing availability drawer
    if (nextProps.router.query.siteCode !== this.props.router.query.siteCode) {
      return true;
    }

    // Handle campsite change
    if (!nextProps.campsite) return false;
    if (nextProps.campsite.id !== this.props.campsite.id) return true;

    // Handle change in start and end dates, this ends up updaing apollo with the updated
    // price for the date
    if (nextProps.start !== this.props.start) return true;
    if (nextProps.end !== this.props.end) return true;

    if (nextState.revealAddress !== this.state.revealAddress) return true;
    if (nextProps.user !== this.props.user) return true;
    if (nextState.headerOffset !== this.state.headerOffset) return true;

    return false;
  }

  componentDidUpdate(prevProps) {
    const { campsite, onSelectEvent, router } = this.props;
    const isOverseas = router.query?.isOverseas === 'true';
    const queryEventType = Number(router.query?.eventType);
    if (prevProps.campsite?.id !== campsite?.id) {
      const defaultEvent = campsite.events.find(
        (event) => event.eventType === queryEventType ||
          (event.queryEventType === SINGLE_EVENT_TYPE.id && isOverseas),
      );

      const hasEventPitch = campsite.pitches.find(
        (event) => event.eventType === queryEventType,
      );
      const hasTouringPitch = campsite.pitches.find(
        (event) => event.eventType === PLACE_EVENT_TYPES.TOURING,
      );

      const firstEvent = campsite?.events?.[0];
      if (!hasEventPitch) {
        const defaultType =
          firstEvent?.eventType === SINGLE_EVENT_TYPE.id
            ? PLACE_EVENT_TYPES.TOURING.id
            : firstEvent?.eventType;
        updateRouterQuery(routes.sites, {
          eventType: hasTouringPitch
            ? PLACE_EVENT_TYPES.TOURING.id
            : defaultType,
        });
      }
      // TEMP Performance Hooks, TODO can be safely removed at any time
      addTiming(types.CAMPSITE_CARD, 'Displayed');

      onSelectEvent((defaultEvent || campsite?.events?.[0]) ?? null);
    }
  }

  componentWillUnmount() {
    if ('IntersectionObserver' in window && this.observer && this.headerElement) {
      this.observer.unobserve(this.headerElement);
    }
  }

  observerCallback = (entries) => {
    const [entry] = entries;
    this.setState({ headerOffset: this.headerElementHeight * entry.intersectionRatio });
  }

  checkToHideAddress = () => {
    const loggedInMember = isLoggedInMember(this.props.user);
    const loggedIn = isLoggedIn();

    // members can see all site type
    if (loggedInMember) {
      return false;
    }

    // you can see an address if you are logged and not a member on UK_AFFILIATED_SITES
    if (
      !loggedInMember &&
      loggedIn &&
      this.props.campsite?.type === campsiteIds.UK_AFFILIATED_SITES
    ) {
      return false;
    }

    return !checkToShowPricesAndRatings(this.props.campsite?.type) && !this.state.revealAddress;
  };

  handleCloseDetails = () => {
    const query = closeDetailsQuery(this.props.query);

    updateRouterQuery(routes.sites, query);
    this.props.onQueryChange(query);

    setTimeout(() => {
      scrollEvent();
    }, 350);
  };

  handleViewOnMap = () => {
    if (!this.props.isMobileMapOpen) {
      this.props.toggleMobileMap();
    }

    const query = closeDetailsQuery(this.props.query);

    query.poi_lat = this.props.campsite.location.lat;
    query.poi_lng = this.props.campsite.location.lon;
    query.zoomLevel = DEFAULT_POI_ZOOM_LEVEL;

    updateRouterQuery(routes.sites, query);
    this.props.onQueryChange(query);

    scrollToTop();

    setTimeout(() => {
      scrollEvent();
    }, 350);
  };

  handleBookNowClick = (siteCode) => {
    const { onProductTypeMismatch, onQueryChange, campsite } = this.props;

    startTiming(types.AVAILABILITY);

    const clickAction = () => {
      scrollToTop();
      onQueryChange({ siteCode });
      updateRouterQuery(routes.sites, { siteCode, reviews: false });
    };

    const isOverseas = isCampsiteOverseas(campsite?.type);
    const showProductMismatchDialog = onProductTypeMismatch?.(
      isOverseas,
      clickAction,
    );
    if (!showProductMismatchDialog) {
      clickAction();
    }
  };

  handleReviewsClick = () => {
    this.props.onQueryChange({ siteCode: null });
    updateRouterQuery(routes.sites, { siteCode: null, reviews: true });
  };

  handleViewProductDetail = (campsite, price) => {
    dataLayerManager.pushProductDetails(dataLayerManager.category.SITES, {
      ...campsite,
      ...price,
    });
  };

  handleFileClick = (url) => {
    navigateTo(url, { type: POST_TYPES.FILE });
  };

  // At narrow widths, there's no space for an end of row tooltip
  // so we'll detect the last colymn and flip the popper to the left
  isEndOfRow = (index) => {
    if (isTabletOrSmaller()) {
      return Math.abs(index % 2) === 1;
    }
    return false;
  };

  formatFileSize = (size) => {
    if (size < 1024) {
      return `${size || 0}kb`;
    }
    return `${Math.floor(size / 1024)}mb`;
  };

  revealAddress = () => {
    const dictionary = mapDictionaryItems(this.props.client, {
      title: 'ListingResult__LoginPopUp__ViewAddress__Title',
      prompt: isLoggedIn()
        ? 'ListingResult__LoginPopUp__ViewAddressAlreadyLoggedIn__Prompt'
        : 'ListingResult__LoginPopUp__ViewAddress__Prompt',
    });

    dataLayerManager.pushRevealCampsiteAddress();

    this.props.client.writeData({
      data: {
        loginPopUp: {
          __typename: 'LoginPopUp',
          open: true,
          redirectTo: '',
          title: dictionary.title,
          prompt: dictionary.prompt,
        },
      },
    });
  };

  render() {
    const {
      SHOW_PITCH_TYPES,
      campsite,
      configuration,
      data,
      error,
      loading,
      router: { query },
      siteCode,
      toggleBasket,
      selectedEvent,
    } = this.props;
    const { headerOffset } = this.state;

    const hideAddress = this.checkToHideAddress();

    if (loading) {
      return (
        <ListingResultStyled loading={loading}>
          <SiteCard
            toggleBasket={toggleBasket}
            handleDrawerToggle={this.handleCloseDetails}
            scroll={false}
            type="back"
          >
            <ListingResultLoading />
          </SiteCard>
        </ListingResultStyled>
      );
    }

    if (error || !campsite) {
      return (
        <ListingResultStyled>
          <SiteCard
            toggleBasket={toggleBasket}
            handleDrawerToggle={this.handleCloseDetails}
            scroll={false}
            type="back"
          >
            <ErrorTypeMessage error={error || data.error} />;
          </SiteCard>
        </ListingResultStyled>
      );
    }

    const mergedFeatures = mergeFeatures(
      campsite.features,
      selectedEvent?.features,
    );
    const activeQuote = query.siteCode && campsite.siteCode === query.siteCode;
    const campsiteType = getCampsiteType(
      configuration.campsiteTypes,
      campsite.type,
    );

    const disabledFacilities = getCampsiteFeatureByTag(
      mergedFeatures,
      FEATURE_TYPES.DISABLED_FILTERABLE,
    );

    const idealFor = getCampsiteFeatureByTag(
      mergedFeatures,
      FEATURE_TYPES.IDEAL_FOR,
    );

    const nearby = getCampsiteFeatureByTag(
      mergedFeatures,
      FEATURE_TYPES.NEARBY,
    );

    const thingsToDo = getCampsiteFeatureByTag(
      mergedFeatures,
      FEATURE_TYPES.THINGS_TO_DO_ON_SITE,
    );

    const qtyTouring = campsite.qtyTouringPitchesOnSite;
    const qtyCamping = campsite.qtyTentPitchesOnSite;
    const qtyGlamping = campsite.qtyPodsOnSite;

    const carouselImages = getCampsiteFeatureByTag(
      mergedFeatures,
      FEATURE_TYPES.GALLERY,
      false,
    ).map((f) => mapAlternativeImages(f.value, f.alternativeImages, campsiteImageKeys));

    const showPricesAndDirections =
      isLoggedIn() &&
      campsite?.placeUrl &&
      campsite.type === campsiteIds.CERTIFICATED_LOCATIONS;

    return (
      <ListingResultStyled
        inView={!siteCode}
        loading={loading}
        offset={headerOffset}
      >
        <SiteCard
          handleDrawerToggle={this.handleCloseDetails}
          type="back"
          toggleBasket={toggleBasket}
        >
          <ListingResultHeader revealAddress={!hideAddress} {...campsite} />

          <PriceCampsiteQuery
            campsiteId={this.props.campsiteId}
            end={this.props.end}
            start={this.props.start}
            pitchId={
              query.bookingId && query.componentId
                ? query.componentId
                : undefined
            }
            onCompleted={({ campsitePrice }) => {
              if (!campsitePrice) return;
              // Price data
              const [eventPrice] = campsitePrice.data;
              const events = eventPrice?.events ?? [];
              const priceUpdatedSelectedEvent =
                events.find((e) => e.id === selectedEvent?.id) ||
                events.find(
                  (e) => e.eventType ===
                    Number(query.eventType || PLACE_EVENT_TYPES.TOURING.id),
                );
              this.props.onMount({
                ...this.props.campsite,
                price: priceUpdatedSelectedEvent?.cheapestMemberPrice,
              });

              this.handleViewProductDetail(campsite, {
                memberPrice: priceUpdatedSelectedEvent?.cheapestMemberPrice,
                price: priceUpdatedSelectedEvent?.cheapestStandardPrice,
              });
            }}
          >
            {(priceResult) => {
              if (priceResult.error || !priceResult) return null;
              const price = priceResult.events?.find(
                (e) => e.id === selectedEvent?.id,
              );
              const offersExist = price?.offers
                ? !!price?.offers?.length
                : false;
              const hasLowAvailability = !!price?.hasLowAvailability;
              return (
                <Fragment>
                  <>
                    {checkToShowPricesAndRatings(campsite?.type) ? (
                      <PricesReviewsBookNow
                        activeQuote={
                          query.siteCode === campsite.siteCode ||
                          !!query.bookingId
                        }
                        campsiteType={campsite.type}
                        disabled={activeQuote}
                        error={price?.error}
                        handleBookNowClick={this.handleBookNowClick}
                        handleReviewsClick={this.handleReviewsClick}
                        hasLowAvailability={hasLowAvailability}
                        memberPrice={price?.cheapestMemberPrice}
                        noOfReviews={campsite.numberOfReviews}
                        rating={campsite.rating}
                        reviewsAreDisabled={!!this.props.reviews}
                        siteCode={campsite.siteCode}
                        standardPrice={price?.cheapestStandardPrice}
                        selectedEvent={selectedEvent}
                        loading={priceResult.loading}
                      />
                    ) : (
                      <RevealAddress
                        onRevealClick={this.revealAddress}
                        noOfReviews={campsite.numberOfReviews}
                        rating={campsite.rating}
                        reviewsAreDisabled={!!this.props.reviews}
                        handleReviewsClick={this.handleReviewsClick}
                        hideAddress={hideAddress}
                        campsiteType={campsite.type}
                      />
                    )}
                  </>

                  <Carousel
                    indicatorRight={svgArrowRight}
                    indicatorLeft={svgArrowLeft}
                    id={campsite.id}
                    images={carouselImages}
                  />

                  <Note
                    backgroundColor={campsiteType.color}
                    align="center"
                    color={theme.COLOR_WHITE}
                    text={campsiteType.text}
                  />

                  <Body>
                    {showPricesAndDirections && (
                      <PricesAndDirections url={campsite?.placeUrl} />
                    )}

                    {offersExist && <SpecialOffers offers={price?.offers} />}

                    <ViewOnMap
                      size="large"
                      block
                      marginTop={showPricesAndDirections || offersExist}
                      onClick={this.handleViewOnMap}
                      dictionary={dictionaryItem('ListingResult', 'ViewOnMap')}
                    />

                    <Title
                      dictionary={dictionaryItem(
                        'ListingResult',
                        'WhyStayHere',
                      )}
                      tag={2}
                      size={4}
                    />
                    <ReadMore
                      text={
                        getCampsiteFeatureById(
                          campsite?.features,
                          campsiteFeatureIds.WEBSITE_DESCRIPTION,
                        ).value
                      }
                      limit={180}
                    />

                    <AddCampsiteToCompareBtn
                      campsiteId={campsite.id}
                      campsite={{
                        ...campsite,
                        memberPrice:
                          price?.cheapestMemberPrice ??
                          selectedEvent?.cheapestMemberPrice,
                        standardPrice:
                          price?.cheapestStandardPrice ??
                          selectedEvent?.cheapestStandardPrice,
                      }}
                      parentLoading={loading}
                      selectedEvent={selectedEvent}
                    />

                    <Title
                      dictionary={dictionaryItem('ListingResult', 'Facilities')}
                      tag={2}
                      size={4}
                    />

                    <CampsiteDetailsTopFeatures features={mergedFeatures} />

                    <Title
                      dictionary={dictionaryItem('ListingResult', 'Glance')}
                      tag={2}
                      size={4}
                    />

                    {/* TODO: Move into component with flag */}
                    {SHOW_PITCH_TYPES && (
                      <Tiles>
                        {qtyTouring > 0 && (
                          <Tile>
                            <TileIcon
                              color={theme.COLOR_BRAND_PRIMARY}
                              icon={ToursEvent}
                              style={{ marginBottom: '0.5rem' }}
                              originalShape
                            />
                            <TileText>Touring</TileText>
                            <TileNumber>{qtyTouring}</TileNumber>
                          </Tile>
                        )}
                        {qtyCamping > 0 && (
                          <Tile>
                            <TileIcon
                              color={theme.COLOR_BRAND_PRIMARY}
                              icon={CampingEvent}
                              style={{ marginBottom: '0.5rem' }}
                            />
                            <TileText>Camping</TileText>
                            <TileNumber>{qtyCamping}</TileNumber>
                          </Tile>
                        )}
                        {qtyGlamping > 0 && (
                          <Tile>
                            <TileIcon
                              color={theme.COLOR_BRAND_PRIMARY}
                              icon={GlampingEvent}
                              style={{ marginBottom: '0.5rem' }}
                            />
                            <TileText>Glamping</TileText>
                            <TileNumber>{qtyGlamping}</TileNumber>
                          </Tile>
                        )}
                      </Tiles>
                    )}

                    {!SHOW_PITCH_TYPES && (
                      <Tiles>
                        <Tile>
                          <TileIcon
                            color={theme.COLOR_BRAND_PRIMARY}
                            icon={ToursEvent}
                            style={{ marginBottom: '0.5rem' }}
                            originalShape
                          />
                          <TileText>Pitches</TileText>
                          <TileNumber>{campsite.qtyTotal}</TileNumber>
                        </Tile>
                      </Tiles>
                    )}

                    <FlexTable>
                      {SHOW_PITCH_TYPES && (
                        <FlexRow>
                          <FlexCell flex={1}>Total Pitches</FlexCell>
                          <FlexCell flex={2}>
                            <div>
                              {campsite.qtyTotal !== 0 && (
                                <Fragment>
                                  <b>{campsite.qtyTotal}</b>{' '}
                                  {SHOW_PITCH_TYPES &&
                                    campsite.qtyHardstandingOnSite > 0 &&
                                    `(${campsite.qtyHardstandingOnSite} hardstanding)`}
                                </Fragment>
                              )}
                              {campsite.qtyTotal === 0 && (
                                <b>
                                  <Text
                                    dictionary={dictionaryItem(
                                      'ListingResult',
                                      'TotalPitches',
                                    )}
                                  />
                                </b>
                              )}
                            </div>
                          </FlexCell>
                        </FlexRow>
                      )}
                      <FlexRow>
                        <FlexCell flex={1}>Type of site</FlexCell>
                        <FlexCell flex={2}>
                          <b>
                            <CampsiteType type={campsite.type} />
                          </b>
                        </FlexCell>
                      </FlexRow>
                      <FlexRow>
                        <FlexCell flex={1}>Open to</FlexCell>
                        <FlexCell flex={2}>
                          <b>
                            {campsite.isMemberOnly
                              ? 'Members'
                              : 'Members and Non-Members'}
                          </b>
                        </FlexCell>
                      </FlexRow>
                      <FlexRow>
                        <FlexCell flex={1}>Opening</FlexCell>
                        <FlexCell flex={2}>
                          <div>
                            {selectedEvent?.features?.filter(
                              (feature) => feature.name === 'Open All Year',
                            ).length ? (
                              <div>
                                <b>
                                  <Text
                                    dictionary={dictionaryItem(
                                      'ListingResult',
                                      'OpenAllYear',
                                    )}
                                  />
                                </b>
                              </div>
                            ) : (
                              selectedEvent?.openDates?.map((date) => (
                                <div key={uuid()}>
                                  <b>
                                    {format(date.fromDate, DATE_FORMAT_INPUT)}{' '}
                                    &ndash;{' '}
                                    {format(date.toDate, DATE_FORMAT_INPUT)}
                                  </b>
                                </div>
                              ))
                            )}
                          </div>
                        </FlexCell>
                      </FlexRow>
                      {!isCampsiteOverseas(campsite?.type) &&
                        (!!selectedEvent?.earliestArrival ||
                          selectedEvent?.earliestArrival === 0) && (
                          <FlexRow>
                            <FlexCell flex={1}>Earliest arrival time</FlexCell>
                            <FlexCell flex={2}>
                              <b>
                                {formatHourToTime(
                                  selectedEvent?.earliestArrival,
                                )}
                              </b>
                            </FlexCell>
                          </FlexRow>
                      )}
                      <FlexRow>
                        <FlexCell flex={1}>Max outfit length</FlexCell>
                        <FlexCell flex={2}>
                          {selectedEvent?.maxOutfitLength === 0 && (
                            <b>
                              <Text
                                dictionary={dictionaryItem(
                                  'ListingResult',
                                  'MaxOutfitLength',
                                )}
                              />
                            </b>
                          )}
                          {selectedEvent?.maxOutfitLength !== 0 && (
                            <b>
                              {`${formatMMtoM(
                                selectedEvent?.maxOutfitLength,
                              )}m`}
                            </b>
                          )}
                        </FlexCell>
                      </FlexRow>
                    </FlexTable>

                    {idealFor.length > 0 && (
                      <Title
                        dictionary={dictionaryItem('ListingResult', 'IdealFor')}
                        tag={2}
                        size={4}
                      />
                    )}
                    <FlexTable>
                      <FlexRow>
                        {getCampsiteFeatureByTag(
                          mergedFeatures,
                          FEATURE_TYPES.IDEAL_FOR,
                        ).map((feature, index) => (
                          <FlexCell
                            key={feature.id}
                            colMobile={2}
                            colDesktop={3}
                          >
                            <CampsiteFeature
                              hasIcon
                              hasDescription
                              isRightColumn={this.isEndOfRow(index)}
                              {...feature}
                            />
                          </FlexCell>
                        ))}
                      </FlexRow>
                    </FlexTable>

                    {thingsToDo.length > 0 && (
                      <Title
                        dictionary={dictionaryItem(
                          'ListingResult',
                          'ThingsToDo',
                        )}
                        tag={2}
                        size={4}
                      />
                    )}
                    <FlexTable>
                      <FlexRow>
                        {thingsToDo.map((feature, index) => (
                          <FlexCell
                            key={feature.id}
                            colMobile={2}
                            colDesktop={3}
                          >
                            <CampsiteFeature
                              hasIcon
                              hasDescription
                              isRightColumn={this.isEndOfRow(index)}
                              {...feature}
                            />
                          </FlexCell>
                        ))}
                      </FlexRow>
                    </FlexTable>

                    {disabledFacilities.length > 0 && (
                      <Title
                        dictionary={dictionaryItem(
                          'ListingResult',
                          'DisabledFacilities',
                        )}
                        tag={2}
                        size={4}
                      />
                    )}
                    <FlexTable>
                      <FlexRow>
                        {disabledFacilities.map((feature, index) => (
                          <FlexCell
                            key={feature.id}
                            colMobile={2}
                            colDesktop={3}
                          >
                            <CampsiteFeature
                              hasIcon
                              hasDescription
                              isRightColumn={this.isEndOfRow(index)}
                              {...feature}
                            />
                          </FlexCell>
                        ))}
                      </FlexRow>
                    </FlexTable>

                    {nearby.length > 0 && (
                      <Title
                        dictionary={dictionaryItem('ListingResult', 'Nearby')}
                        tag={2}
                        size={4}
                      />
                    )}
                    <FlexTable>
                      <FlexRow>
                        {nearby.map((feature, index) => (
                          <FlexCell
                            key={feature.id}
                            colMobile={2}
                            colDesktop={3}
                          >
                            <CampsiteFeature
                              hasIcon
                              hasDescription
                              isRightColumn={this.isEndOfRow(index)}
                              {...feature}
                            />
                          </FlexCell>
                        ))}
                      </FlexRow>
                    </FlexTable>

                    {!!selectedEvent?.files?.length && (
                      <Title
                        dictionary={dictionaryItem('ListingResult', 'Links')}
                        tag={2}
                        size={4}
                      />
                    )}
                    <Flex>
                      <List>
                        {selectedEvent?.files?.map((file) => {
                          const fileSize = this.formatFileSize(file.filesizeKb);
                          const label = `${
                            file.name
                          } (${file.fileExtension?.toUpperCase()}), ${fileSize}`;
                          return (
                            <CampsiteFeatureLink
                              key={`file-${file.name}`}
                              label={label}
                              onClick={() => this.handleFileClick(file.url)}
                            />
                          );
                        })}
                      </List>
                    </Flex>
                  </Body>
                </Fragment>
              );
            }}
          </PriceCampsiteQuery>
        </SiteCard>
      </ListingResultStyled>
    );
  }
}

export default compose(
  withApollo,
  withRouter,
  queryHelper(GET_CONFIGURATION),
  queryHelper(GET_CAMPSITE, getCampsiteOptions),
  graphql(GET_USER, {
    options: () => ({
      fetchPolicy: fetchPolicy.CACHE_ONLY,
    }),
    props: ({ data }) => ({
      user: data.user,
    }),
    skip: (props) => !isLoggedIn(),
  }),
)(withPhase(ListingResult, FEATURE_FLAGS.SHOW_PITCH_TYPES));
