import React from 'react';
import PropTypes from 'prop-types';

import IbePropTypes from '../../IbePropTypes';
import theme from '../../styles/config/theme';

const Picture = ({ image, children }) => {
  if (!image) return <></>;

  if (image.alternativeUrls) {
    return (
      <picture>
        {image.alternativeUrls.desktop.length > 0 && (
          <source
            media={`(min-width: ${theme.DESKTOP}px)`}
            srcSet={image.alternativeUrls.desktop.join(', ')}
          />
        )}
        {image.alternativeUrls.tablet.length > 0 && (
          <source
            media={`(min-width: ${theme.TABLET}px)`}
            srcSet={image.alternativeUrls.tablet.join(', ')}
          />
        )}
        {image.alternativeUrls.mobileLarge.length > 0 && (
          <source
            media={`(min-width: ${theme.MOBILE_LARGE}px)`}
            srcSet={image.alternativeUrls.mobileLarge.join(', ')}
          />
        )}
        {image.alternativeUrls.mobile.length > 0 && (
          <source
            media={`(min-width: ${theme.MOBILE}px)`}
            srcSet={image.alternativeUrls.mobile.join(', ')}
          />
        )}
        {children}
      </picture>
    );
  }

  return children;
};

Picture.propTypes = {
  image: PropTypes.shape(IbePropTypes.optimizedImage).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Picture.defaultProps = {
  children: undefined,
};

export default Picture;
