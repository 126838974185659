import styled from 'styled-components';

import { Button as StyledButton } from '../ui';

export const RightButton = styled(StyledButton)`
  position: absolute;
  transform: translate3d(0, -50%, 0);
  top: 50%;
  right: 0%;
`;

export const LeftButton = styled(StyledButton)`
  position: absolute;
  transform: translate3d(0, -50%, 0);
  top: 50%;
  left: 0%;
`;

export const CarouselWrapper = styled.div`
  overflow: hidden;
  position: relative;
  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHTEST};;
`;

export const Pagination = styled.div`
  position: absolute;
  display: flex;
  bottom: 4%;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  padding-top: 1rem;
`;

export const Item = styled.button`
  border: 1px solid ${({ theme }) => theme.COLOR_WHITE};
  ${({ theme, active }) => (active ? `background-color: ${theme.COLOR_WHITE}` : '')};
  padding: 0;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  cursor: pointer;
  & + & {
    margin-left: 1.5rem;
  }
`;
