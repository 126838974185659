import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { format } from 'date-fns';
import { v4 as uuid } from 'uuid';

import Rating from '../Rating';
import queryHelper from '../../lib/queryHelper';
import { standardPropTypes, standardDefaultProps } from '../../lib/standardProptypes';

import {
  Article,
  Header,
  TitleAndDate,
  Author,
  Main,
} from './ReviewArticles.style';
import Title from '../ui/Title';
import Text from '../ui/Text';
import { LoadingBlock } from '../ui/Loading';

import GET_REVIEWS, { getReviewsOptions } from '../../config/graphql/getReviews';

class Reviews extends PureComponent {
  static propTypes = {
    // NOTE: props used for query but not needed in view
    /* eslint-disable react/no-unused-prop-types */
    page: PropTypes.string,
    pageSize: PropTypes.string,
    /* eslint-enable react/no-unused-prop-types */
    reviews: PropTypes.shape({
      count: PropTypes.number,
      data: PropTypes.arrayOf(PropTypes.shape()),
    }),
    parentLoading: PropTypes.bool,
    prevCount: PropTypes.number,
    reviewCount: PropTypes.func,
    onLoadingComplete: PropTypes.func,
    ...standardPropTypes,
  };

  static defaultProps = {
    page: 0,
    pageSize: 3,
    reviews: {
      count: 0,
      data: [],
    },
    parentLoading: false,
    prevCount: 3,
    reviewCount: () => {},
    onLoadingComplete: () => {},
    ...standardDefaultProps,
  };

  componentDidUpdate(nextProps) {
    this.props.reviewCount(this.props.reviews.count || 0);
    this.props.onLoadingComplete(this.props.loading);
  }

  amountThatWillShow = (count, pageSize, page) => {
    const parsedCount = Number(count);
    const parsedPageSize = Number(pageSize);
    const parsedPage = Number(page);
    const totalPages = Math.ceil(parsedCount / parsedPageSize);

    if (!parsedCount) return parsedPageSize;
    if (parsedPage > totalPages) return 0;

    return parsedPage >= totalPages ?
      parsedCount % parsedPageSize || parsedPageSize : parsedPageSize;
  }

  render() {
    if (this.props.error) {
      return <div>ERROR - Please reload and try again</div>;
    }

    if (!this.props.loading && !this.props.reviews.count) {
      return <div>There are currently no reviews for this campsite.</div>;
    }

    if (this.props.loading || this.props.parentLoading) {
      return (
        <Fragment>
          {[...Array(this.amountThatWillShow(
            this.props.prevCount,
            this.props.pageSize,
            this.props.page,
          ))].map(page => (
            <Article
              key={uuid()}
            >
              <Header>
                <TitleAndDate loading>
                  <LoadingBlock width="15rem" style={{ marginBottom: '0.8em' }} />
                  <LoadingBlock width="8rem" />
                </TitleAndDate>
                <LoadingBlock width="6rem" />
              </Header>
              <Main>
                <div>
                  <LoadingBlock />
                  <LoadingBlock />
                  <LoadingBlock />
                  <LoadingBlock />

                  <Author>
                    <LoadingBlock width="15rem" />
                  </Author>
                </div>
              </Main>
            </Article>
          ))}
        </Fragment>
      );
    }

    return (
      <Fragment>
        {this.props.reviews.data.map(review => (
          <Article
            key={uuid()}
          >
            <Header>
              <TitleAndDate>
                <Title tag={4} size={4}>
                  <strong>{review.title}</strong>
                </Title>
                <Text>
                  {format(review.reviewDate, 'DD MMMM YYYY')}
                </Text>
              </TitleAndDate>
              <Rating
                rating={review.rating}
                style={{ marginLeft: '0 0 auto', height: '1rem' }}
                header={false}
              />
            </Header>
            <Main>
              <Text>
                {review.text}
                <Author>
                  {review.reviewer}
                </Author>
              </Text>
            </Main>
          </Article>
        ))}
      </Fragment>
    );
  }
}

export default compose(queryHelper(GET_REVIEWS, getReviewsOptions))(Reviews);
