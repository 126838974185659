import styled from 'styled-components';
import { above } from '../../styles/libs';

export const CompareBtn = styled.div`
  display: none;
  position: relative;

  ${({ theme }) => above(theme.TABLET)`
    display: block;
  `}
`;

export const TooltipAction = styled.div`
  align-self: flex-start;
`;
