import styled from 'styled-components';

import { above } from '../../../styles/libs';

export const RadioGroup = styled.div`
  display: flex;
  padding: 0 0 0.5rem;
`;

export const RadioGroupItem = styled.div`
  flex: 0 0 auto;
  margin-right: 0.5rem;

  ${({ theme }) => above(theme.DESKTOP)`
    margin-right: 1.5rem;
  `};
`;
