import React from 'react';
import PropTypes from 'prop-types';

import StyledFlexTable from './FlexTable.style';

const propTypes = {
  children: PropTypes.node.isRequired,
};

function FlexTable({ children }) {
  return (
    <StyledFlexTable>
      {children}
    </StyledFlexTable>
  );
}

FlexTable.propTypes = propTypes;

export default FlexTable;
