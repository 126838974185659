import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { dictionaryItem } from '../../hocs/withDictionary';

import theme from '../../styles/config/theme';

const defaultProps = {
  handleDrawerToggle() { },
};

const propTypes = {
  handleDrawerToggle: PropTypes.func,
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.COLOR_WHITE};
  padding: 2rem;
`;

function ListingResultDetails(props) {
  return (
    <Container>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button
        dictionary={dictionaryItem('ListingResultDetails', 'Back')}
        type="button"
        onClick={props.handleDrawerToggle}
      />
      <hr />
      <div>Listing result details.</div>
    </Container>
  );
}

ListingResultDetails.defaultProps = defaultProps;
ListingResultDetails.propTypes = propTypes;

export default ListingResultDetails;
