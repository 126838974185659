import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import { differenceInDays } from '../../lib/dates';
import GET_CAMPSITE_PRICE from '../../config/graphql/getCampsitePrice';

export function livePrice(start, end) {
  if (!((start && end && differenceInDays(end, start) > 0)) ||
  (start && !end) || (!start && end)) return {};
  return { start, end };
}

class PriceCampsiteQuery extends PureComponent {
  static propTypes = {
    campsiteId: PropTypes.string,
    children: PropTypes.func.isRequired,
    end: PropTypes.string,
    pitchId: PropTypes.string,
    skip: PropTypes.bool,
    start: PropTypes.string,
    onCompleted: PropTypes.func,
  };

  static defaultProps = {
    campsiteId: '',
    end: '',
    skip: false,
    start: '',
    onCompleted: null,
    pitchId: '',
  };

  render() {
    const {
      campsiteId,
      children,
      end,
      skip,
      start,
      onCompleted,
      pitchId,
    } = this.props;

    const variables = {
      campsiteId,
      pitchId,
      ...livePrice(start, end),
    };

    return (
      <Query
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange
        query={GET_CAMPSITE_PRICE}
        skip={skip}
        onCompleted={onCompleted}
        variables={variables}
      >
        {({ data, error, loading }) => {
          const price = data && data.campsitePrice ? data.campsitePrice.data[0] : {};
          return children({ ...price, error, loading });
        }}
      </Query>
    );
  }
}

export default PriceCampsiteQuery;
