import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { dictionaryItem } from '../../../hocs/withDictionary';
import generateMonthOptions from '../../../lib/helpers/tours';

import {
  FormGroup, FormGroupItem, Label, Select,
} from './ToursInputs.style';

function ToursInputs({
  disabled,
  durations,
  duration,
  departureMonth,
  handleDepartureMonthSelect,
  handleDurationSelect,
}) {
  const monthOptions = generateMonthOptions(12);

  return (
    <FormGroup>
      <FormGroupItem>
        <Label
          dictionary={dictionaryItem('CampsiteSearchForm', 'DepartureMonth')}
          htmlFor="departureMonths"
        />
        <Select
          id="departureMonths"
          disabled={disabled}
          name="departureMonths"
          onChange={(e) => handleDepartureMonthSelect(e.target.value)}
          value={Number(departureMonth) || ''}
        >
          <option value="">Please select</option>
          {monthOptions.map(item => (
            <option value={item.month} key={item.label}>{item.label}</option>
          ))}
        </Select>
      </FormGroupItem>
      <FormGroupItem>
        <Label
          dictionary={dictionaryItem('CampsiteSearchForm', 'Duration')}
          htmlFor="duration"
        />
        <Select
          id="duration"
          disabled={disabled}
          name="duration"
          onChange={(e) => handleDurationSelect(e.target.value)}
          value={Number(duration) || ''}
        >
          <option value="">Please select</option>
          {durations.map(item => (
            <option value={item.key} key={item.value}>{item.value}</option>
          ))}
        </Select>
      </FormGroupItem>
    </FormGroup>
  );
}

ToursInputs.propTypes = {
  handleDepartureMonthSelect: PropTypes.func.isRequired,
  handleDurationSelect: PropTypes.func.isRequired,
  durations: PropTypes.arrayOf(PropTypes.shape({})),
  duration: PropTypes.string,
  departureMonth: PropTypes.string,
  disabled: PropTypes.bool,
};

ToursInputs.defaultProps = {
  durations: [],
  duration: '',
  departureMonth: '',
  disabled: false,
};

export default memo(ToursInputs);
