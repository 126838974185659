import gql from 'graphql-tag';

export function getReviewsOptions({ campsiteId, page, pageSize }) {
  return {
    variables: {
      id: campsiteId,
      page,
      pageSize,
      skip: !campsiteId,
    },
  };
}

export default gql`
  query Reviews (
    $id: String!,
    $page: String!,
    $pageSize: String!,
    $skip: Boolean
  ) {
    reviews (
      id: $id,
      page: $page,
      pageSize: $pageSize
    ) @rest(
      type: "Reviews",
      path: "Campsite/reviews?Id={args.id}&Page={args.page}&PageSize={args.pageSize}"
    ) @skip(if: $skip) {
      count
      data {
        title
        text
        rating
        reviewDate
        reviewer
      }
    }
  }
`;
