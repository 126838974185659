import styled, { css } from 'styled-components';

import Note from '../Note';

import StyledButtonBrand from '../ui/Button/ButtonBrand.style';
import zIndex from '../../config/zIndex';

// Components
import ErrorMessage from '../ErrorMessage';
import Text from '../ui/Text';

export default styled.div`
  display: flex;
  flex-direction: column;
  width: 13.5rem;
  line-height: 1.5;
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border-bottom: 0.25rem solid ${({ color, theme }) => color || theme.COLOR_GRAY_LIGHT};
  transform: translate3d(-50%, calc(-100% + -0.3rem), 0);
  position: relative;
  z-index: ${zIndex.POPOVER};
  cursor: default;

  &::after {
    content: "";
    position: absolute;
    background-color: ${({ color, theme }) => color || theme.COLOR_GRAY_LIGHT};
    width: 0.75rem;
    height: 0.75rem;
    transform: translate3d(-50%, 0.5rem, 0) rotate(45deg);
    left: 50%;
    bottom: 0;
    z-index: -1;
  }
`;

export const LoadingAnimation = styled.div`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  text-align: center;
  padding: 0.5rem;
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  display: none !important;
`;

export const CampsiteImg = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  width: 100%;

  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ${({ theme }) => theme.RATIO_5_3};
  }
`;

export const Image = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHT};
`;

export const Img = styled.img`
  height: 100%;
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
`;

export const CampsiteContent = styled.main`
  padding: 1rem;
  background-color: ${({ theme }) => theme.COLOR_WHITE};
`;

export const FromToDate = styled.main`
padding: 1rem 1rem 0 1rem;
text-align: center;
background-color: ${({ theme }) => theme.COLOR_WHITE};
`;

export const CampsiteHeader = styled.header`
  position: relative;
  text-align: center;
  background-color: ${({ theme }) => theme.COLOR_WHITE};
`;

export const CampsiteTitle = styled.h3`
  font-weight: bold;
  margin: 1rem 0 0;
  padding: 0 1rem;
`;

export const Footer = styled.footer`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  position: relative;
  padding: 0 1rem 1rem;
`;

export const MoreDetails = styled(StyledButtonBrand)`
  ${({ siteDetailsVisible }) => siteDetailsVisible && css`
    display: none;
  `}
  cursor: pointer;
  ${({ hasOffers }) => hasOffers && css`
    margin-top: 1rem;
  `}

  ${({ campsiteColor }) => campsiteColor && css`
    background-color: ${campsiteColor};
  `}

  width: 100%;
`;

export const OfferNote = styled(Note)`
  padding: 0;
  min-height: auto;
`;

export const OfferTitle = styled(OfferNote)`
  margin-top: 0.5rem;
  font-weight: bold;
  text-align: center;
`;

export const ReviewButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  padding: 0.5rem 0 0 0;
  margin: 0;
  border: none;
  background: none;
  outline: none;

  &:not(:disabled) {
    cursor: pointer;
  }
`;

export const LowAvailability = styled(Text)`
  width: 100%;
  margin-top: -0.75rem;
  font-size: 0.85rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.COLOR_LAKE_GREEN_ORIGINAL};
  text-align: center;
`;

export const Category = styled.div`
  padding: 0.3rem;
  text-align: center;
  ${({ theme, backgroundColor }) => css`
    background-color: ${backgroundColor};
    color: ${theme.COLOR_WHITE}
  `}
`;
