import moment from 'moment';
import { DATE_FORMAT_DISPLAY } from '../../config/locale';
import { locationWithinBounds } from '../query';
import { sortObjectsByKey } from '../sortArrayOfObjects';
import { defaultFilterableTypes } from '../../config/campsiteTypes';
import PLACE_EVENT_TYPES, { SINGLE_EVENT_TYPE } from '../../config/eventTypes';

export const TOURS_SORT_TYPES = {
  SITE_NAME: 0,
  PRICE_DSC: 1,
  PRICE_ASC: 2,
  DISTANCE_ASC: 4,
};

export const DURATION_TYPES = {
  WITHIN_THAN_SEVEN_DAYS: 1,
  MORE_THAN_SEVEN_DAYS: 2,
};

export const addTypesToToursQuery = (query) => {
  if (!query.suggestedUkItineraryId) {
    return query;
  }
  return {
    ...query,
    types: defaultFilterableTypes,
  };
};

export const filterLocationsByEventType = (locations, eventType) => locations.filter((location) => {
  if (!location.eventTypes) {
    return true;
  }

  // Filter camping/touring and always show overseas(single event type)
  return location.eventTypes.includes(eventType || PLACE_EVENT_TYPES.TOURING.id) ||
    location.eventTypes.includes(SINGLE_EVENT_TYPE.id);
});

export const filterLocations = (locations, ukItineraries, ukItineraryId, eventType) => {
  if (!ukItineraryId || !ukItineraries.find((item) => item.id?.toString() === ukItineraryId)) {
    return filterLocationsByEventType(locations, Number(eventType));
  }
  const activeUkItinerary = ukItineraries.find((item) => item.id?.toString() === ukItineraryId);
  return locations.filter((location) => activeUkItinerary.campsiteIds.includes(location.id));
};

export const sortTours = (tours = [], sortType) => {
  if (
    sortType === TOURS_SORT_TYPES.PRICE_ASC ||
    sortType === TOURS_SORT_TYPES.PRICE_DSC
  ) {
    return sortObjectsByKey(
      tours,
      'fromPrice',
      sortType === TOURS_SORT_TYPES.PRICE_DSC,
    );
  }
  if (sortType === TOURS_SORT_TYPES.SITE_NAME) {
    return sortObjectsByKey(tours, 'name');
  }
  return tours;
};

const generateMonthOptions = (numMonths) => {
  let curMonth = moment().month() + 1;
  let curYear = moment().year();
  const options = [];

  [...Array(numMonths).keys()].forEach(() => {
    const monthString = moment(curMonth, 'M').format('MMMM');
    options.push({
      label: `${monthString} ${curYear}`,
      month: curMonth,
      year: curYear,
    });
    if (curMonth + 1 > 12) {
      curMonth = 1;
      curYear += 1;
    } else {
      curMonth += 1;
    }
  });
  return options;
};

export const generateDateRange = (date, numNights) => {
  if (!date || !numNights) {
    return null;
  }
  const startDate = moment(date).format(DATE_FORMAT_DISPLAY);
  const endDate = moment(date)
    .add(numNights, 'days')
    .format(DATE_FORMAT_DISPLAY);
  return `${startDate} - ${endDate}`;
};

export const filterDatesByMonth = (dates = [], month) => dates.filter((dateItem) => {
  if (month === undefined || month === '') {
    return dates;
  }
  const dateMonth = moment(dateItem).month();
  return dateMonth === (Number(month) - 1);
});

export const getDistance = (location1, location2) => {
  // https://cloud.google.com/blog/products/maps-platform/how-calculate-distances-map-maps-javascript-api
  const R = 3958.8;
  const rlat1 = location1.lat * (Math.PI / 180);
  const rlat2 = location2.lat * (Math.PI / 180);
  const difflat = rlat2 - rlat1;
  const difflon = (location2.lng - location1.lng) * (Math.PI / 180);

  const d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2),
      ),
    );
  return d;
};

export const nearestTours = ({ tours, coords }) => tours.sort((a, b) => {
  const distance1 = getDistance(coords, { lat: a.location?.lat, lng: a.location?.lon });
  const distance2 = getDistance(coords, { lat: b.location?.lat, lng: b.location?.lon });
  return distance1 - distance2;
}).slice(0, 2);

export const searchTours = ({
  tours,
  departureMonth,
  duration,
  bounds,
  location,
}) => {
  let results = [...tours];
  if (departureMonth) {
    results = results.filter((resultItem) => {
      const tourMonths = resultItem.departureDates.map(
        (tourDepartureDate) => `${moment(tourDepartureDate).month() + 1}`,
      );
      return tourMonths.includes(departureMonth);
    });
  }
  if (duration) {
    results = results.filter((resultItem) => {
      if (Number(duration) === DURATION_TYPES.WITHIN_THAN_SEVEN_DAYS) {
        return resultItem.numberOfNights <= 7;
      }
      return resultItem.numberOfNights > 7;
    });
  }
  if (location && bounds) {
    results = results.filter((resultItem) => {
      const withinBounds = locationWithinBounds(
        {
          lat: resultItem.location?.lat,
          lng: resultItem.location?.lon,
        },
        bounds,
      );
      return withinBounds;
    });
  }
  return results;
};

export default generateMonthOptions;
