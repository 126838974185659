import React, { memo } from 'react';
import { compose, withApollo } from 'react-apollo';
import PropTypes from 'prop-types';

import IbePropTypes from '../../IbePropTypes';

// Utils
import formatPrice from '../../lib/formatPrice';
import { dictionaryItem, getDictionaryItem } from '../../hocs/withDictionary';

// Styles
import StyledInfoWindowPrices, {
  CampsitePriceWrapper,
  CampsiteMembershipPrice,
  CampsitePrice,
  Closed,
  Error,
  Full,
  Price,
} from './InfoWindowPrices.style';

import { LoadingBlock } from '../ui/Loading';

function Loading() {
  return (
    <CampsitePriceWrapper>
      <CampsiteMembershipPrice>
        <LoadingBlock />
        <LoadingBlock />
      </CampsiteMembershipPrice>
      <CampsitePrice>
        <LoadingBlock />
        <LoadingBlock />
      </CampsitePrice>
    </CampsitePriceWrapper>
  );
}

function InfoWindowPrices({
  error,
  isOpen,
  isTours,
  loading,
  memberPrice,
  standardPrice,
  client,
}) {
  if (!isOpen) {
    return (
      <Closed dictionary={dictionaryItem('InfoWindowPrices', 'Closed')} />
    );
  }

  if (error) {
    return (
      <Error dictionary={dictionaryItem('InfoWindowPrices', 'Error')} />
    );
  }

  if (loading) {
    return <Loading />;
  }

  if (!memberPrice) {
    return (
      <Full dictionary={dictionaryItem('InfoWindowPrices', 'Full')} />
    );
  }

  return (
    <StyledInfoWindowPrices>
      <CampsitePriceWrapper>
        <CampsiteMembershipPrice>
          <div>
            <div>{getDictionaryItem(client, 'InfoWindowPrices__MembersFrom')}</div>
            <Price>
              <strong>{formatPrice(memberPrice, undefined, isTours)} </strong>
              <span>{getDictionaryItem(client, 'InfoWindowPrices__PerNight')}</span>
            </Price>
          </div>
        </CampsiteMembershipPrice>
        {!!standardPrice &&
          <CampsitePrice>
            <div>{getDictionaryItem(client, 'InfoWindowPrices__NonMembersFrom')}</div>
            <Price>
              <strong>{formatPrice(standardPrice, undefined, isTours)} </strong>
              <span>{getDictionaryItem(client, 'InfoWindowPrices__PerNight')}</span>
            </Price>
          </CampsitePrice>
        }
      </CampsitePriceWrapper>
    </StyledInfoWindowPrices>
  );
}

InfoWindowPrices.propTypes = {
  error: PropTypes.shape({}),
  isOpen: PropTypes.bool,
  isTours: PropTypes.bool,
  loading: PropTypes.bool,
  memberPrice: PropTypes.number,
  standardPrice: PropTypes.number,
  client: PropTypes.shape(IbePropTypes.client).isRequired,
};

InfoWindowPrices.defaultProps = {
  error: null,
  isOpen: true,
  isTours: false,
  loading: false,
  memberPrice: null,
  standardPrice: null,
};

export default compose(
  withApollo,
  memo,
)(InfoWindowPrices);
