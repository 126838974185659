import styled, { css } from 'styled-components';

import Drawer from '../../ui/Drawer';
import { above, below } from '../../../styles/libs';
import detectIE from '../../../lib/detectIE';
import zIndex from '../../../config/zIndex';

/**
 * Locks the height of the element to the viewport height
 * which allows for overlaying Drawers and Site Cards
 */
const lockHeight = inView => (...styles) => {
  if (inView) return css`${styles}`;
  return css`
    position: absolute;
    height: 100vh;
    overflow: hidden;
  `;
};

export default styled(Drawer)`
  width: 100%;
  position: absolute;
  z-index: ${zIndex.BELOW_HEADER};

  ${({ inView, theme, offset }) => css`
    background-color: ${theme.COLOR_WHITE};

    ${below(theme.DESKTOP)`
      position: ${inView ? 'relative' : 'absolute'};
    `}

    ${above(theme.TABLET)`
      width: ${theme.TWO_PANEL_WEIGHTED_RIGHT_2};
      max-width: 600px;
    `};

    ${above(theme.DESKTOP)`
      ${lockHeight(inView)`
        position: sticky;
        ${detectIE() && css`
          position: relative;
        `}
        top: 0;
      `}

      width: ${theme.TWO_PANEL_WEIGHTED_RIGHT_1};
      height: calc(100vh - ${offset}px);
      z-index: 1;
    `};
  `};
`;
