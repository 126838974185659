import styled from 'styled-components';

import { above } from '../../../styles/libs';

export default styled.div`
  background-color: transparent;
  border-radius: 3px;
  color: ${({ theme }) => theme.COLOR_TEXT_PRIMARY};
  font-size: 0.75rem;
  padding: 0.375rem 0.75rem;

  ${({ theme }) => above(theme.TABLET)`
    font-size: 0.875rem;
  `}
`;
