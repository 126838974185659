import styled, { css } from 'styled-components';
import detectIE from '../../lib/detectIE';

export const ReviewsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 2rem;

  ${detectIE() && css`
    display: block;
  `};
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const Footer = styled.footer`
`;

export const Main = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
