import React, {
  useEffect,
  useState,
  Suspense,
  useRef,
} from 'react';
import PropTypes from 'prop-types';

import ConditionalWrapper from '../ConditionalWrapper';
import CarouselItem from './CarouselItem';
import {
  CarouselWrapper,
  Pagination,
  Item,
  LeftButton,
  RightButton,
} from './Carousel.style';
import { Icon } from '../ui';
import theme from '../../styles/config/theme';
import { addTiming, types } from '../../lib/timings';
import IbePropTypes from '../../IbePropTypes';
import svgArrowLeft from '../../static/images/icons/ArrowLeft.svg';
import svgArrowRight from '../../static/images/icons/ArrowRight.svg';

const Flickity = React.lazy(() => import('react-flickity-component'));

const getCarouselItems = ({ images, isMounted, isFlickityReady }) => {
  const uniqueImages = [...new Map(images.map(i => [i.baseUrl, i])).values()];
  const renderedItems = uniqueImages?.filter(
    (_, index) => index === 0 || (index > 0 && isMounted),
  );

  return renderedItems?.map((item, index) => (
    <CarouselItem
      key={item.baseUrl}
      image={item}
      hide={index !== 0 && !isFlickityReady}
      isFlickityReady={isFlickityReady}
      isFirst={index === 0}
    />
  ));
};

const Carousel = ({
  images,
  indicatorLeft,
  indicatorRight,
  indicatorsColor,
  indicatorSize,
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const [isFlickityReady, setIsFlickityReady] = useState(false);
  const [paginationIndex, setPaginationIndex] = useState(0);
  const flickity = useRef();

  const flickityRef = (ref) => {
    flickity.current = ref;
  };

  const onFlickityReady = () => {
    if (flickity.current) setIsFlickityReady(true);
    // TEMP Performance Hooks, TODO can be safely removed at any time
    addTiming(types.CAMPSITE_CARD, 'Carousel Ready');
  };

  const onFlickitySelect = (index) => {
    setPaginationIndex(index);
  };

  const handlePaginationClick = (index) => {
    flickity.current.select(index);
  };

  const handleArrowClick = (isRight) => {
    if (isRight) {
      flickity.current.select(paginationIndex + 1);
    } else {
      flickity.current.select(paginationIndex - 1);
    }
  };

  useEffect(() => {
    // we need to use resize here to force flickity to re-render
    // and activate lazy load on the first cell.
    // if (isFlickityReady && flickity.current) flickity.current.resize();
  }, [isFlickityReady]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <CarouselWrapper>
      {images?.length > 0 && (
        <ConditionalWrapper
          condition={isMounted && images?.length > 1}
          wrapper={(children) => (
            <Suspense
              fallback={
                <>{getCarouselItems({ images, isMounted, isFlickityReady })}</>
              }
            >
              <Flickity
                flickityRef={flickityRef}
                options={{
                  lazyLoad: true,
                  prevNextButtons: false,
                  pageDots: false,
                  draggable: true,
                  on: {
                    ready: onFlickityReady,
                    select: onFlickitySelect,
                  },
                }}
              >
                {children}
              </Flickity>
              {paginationIndex > 0 &&
                <LeftButton
                  ariaLabel="Previous"
                  type="button"
                  onClick={() => handleArrowClick(false)}
                >
                  <Icon
                    color={indicatorsColor}
                    icon={indicatorLeft}
                    size={indicatorSize}
                  />
                </LeftButton>
              }
              {paginationIndex >= 0 && paginationIndex < children.length - 1 &&
                <RightButton
                  ariaLabel="Next"
                  type="button"
                  onClick={() => handleArrowClick(true)}
                >
                  <Icon
                    color={indicatorsColor}
                    icon={indicatorRight}
                    size={indicatorSize}
                  />
                </RightButton>
              }
              <Pagination>
                {
                children.map((child, index) => (
                  <Item
                    aria-label="Select slide"
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    active={index === paginationIndex}
                    onClick={() => handlePaginationClick(index)}
                  />
                ))
                }
              </Pagination>
            </Suspense>
          )}
        >
          {getCarouselItems({ images, isMounted, isFlickityReady })}
        </ConditionalWrapper>
      )}
    </CarouselWrapper>
  );
};

Carousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.optimizedImage)),
  indicatorLeft: PropTypes.node,
  indicatorRight: PropTypes.node,
  indicatorsColor: PropTypes.string,
  indicatorSize: PropTypes.string,
};

Carousel.defaultProps = {
  images: [],
  indicatorLeft: svgArrowLeft,
  indicatorRight: svgArrowRight,
  indicatorsColor: theme.COLOR_WHITE,
  indicatorSize: '1em',
};

export default Carousel;
