import styled from 'styled-components';

import IconComponent from '../ui/Icon/Icon';

import StyledButtonBordered from '../ui/Button/ButtonBordered.style';
import StyledBadge from '../ui/Badge/Badge.style';

export default styled(StyledButtonBordered)`
  background-color: ${({ theme }) => theme.COLOR_WHITE};

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHTEST};
      color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
    }
  }
`;

export const Badge = styled(StyledBadge)`
  background-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
  color: ${({ theme }) => theme.COLOR_WHITE};
  margin-left: 0.5rem;
`;

export const Icon = styled(IconComponent)`
  color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
  font-size: 1.125rem;
  margin-right: 0.5rem;
`;
