import gql from 'graphql-tag';

export function getToursOptions({ router }) {
  return {
    variables: {
      skip: router.query?.isTours !== 'true',
    },
  };
}

export default gql`
  query Tours {
    Tours @rest(
      type: "Tours",
      path: "Tour/AllEscortedEuropeanTours"
    ) {
      tours {
        id
        name
        location
        gallery
        description
        departureDates
        tourHighlights
        whatsIncludedIntroText
        whatsIncluded
        numberOfNights
        fromPrice
        fromPriceSuffix
        itinerary
      }
    }
  }
`;
