import React, { Fragment } from 'react';

import theme from '../../styles/config/theme';

import {
  Body,
  CampsiteDetails,
  ColCTA,
  Header,
  BookingDetails,
} from './ListingResult.style';

import { LoadingBlock, LoadingBlockButton } from '../ui/Loading';
import Note from '../Note';
import { Col, Row } from '../ui/Grid';
import Carousel from '../Carousel';

function ListingResultLoading() {
  return (
    <Fragment>
      <Header>
        <CampsiteDetails>
          <LoadingBlock size="1.375rem" />
          <LoadingBlock />
        </CampsiteDetails>
      </Header>

      <BookingDetails>
        <Row align="center" size="small">
          <Col>
            <div>
              <LoadingBlock size="1.25rem" width="9rem" />
            </div>
            <div>
              <LoadingBlock size="0.625rem" width="3rem" />
            </div>
          </Col>
          <ColCTA>
            <LoadingBlockButton
              block
              maxWidth="12rem"
              size="large"
              type="button"
              disabled
              style={{ float: 'right' }}
            />
          </ColCTA>
        </Row>
      </BookingDetails>

      <Carousel />

      <Note
        backgroundColor={theme.COLOR_GRAY}
        align="center"
        color={theme.COLOR_WHITE}
      />

      <Body>
        <LoadingBlock
          width="7rem"
          style={{
            marginTop: '2rem',
            marginBottom: '1rem',
          }}
        />
        <LoadingBlock size="0.9375rem" />
        <LoadingBlock size="0.9375rem" />
        <LoadingBlock size="0.9375rem" />
        <LoadingBlock size="0.9375rem" width="75%" />
      </Body>
    </Fragment>
  );
}

export default ListingResultLoading;
