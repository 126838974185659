import { USER_LAST_LOCATION, USER_LOCATION_ACCEPTANCE } from '../../lib/constants';
import {
  BROWSER_PERMISSIONS,
  GEOLOCATION_FAILURE_CODES,
  USER_LOCATION_PERMISSIONS,
} from '../../lib/location';
import {
  StorageType,
  getGenericStorageItem,
  hasGenericStorageItem,
  removeGenericStorageItem,
  setGenericStorageItem,
} from '../../lib/storage';
import { GOOGLE_MAP_TILE_SIZE_PX } from './constants';

const mapBrowserStatusToLocalStatus = (status) => {
  if (status.state === BROWSER_PERMISSIONS.GRANTED) {
    return USER_LOCATION_PERMISSIONS.ACCEPTED;
  }
  if (status.state === BROWSER_PERMISSIONS.PROMPT) {
    return USER_LOCATION_PERMISSIONS.NOT_KNOWN;
  }
  return USER_LOCATION_PERMISSIONS.DECLINED;
};

// Checks our access to the user permission, without invoking the
// browser prompt, essentially used to stop invasive UI requests in browsers
// which handle location permission persistence differently (i.e. Safari, Firefox vs Chrome)
export const checkUserLocationPermissions = () => {
  if (!window) return USER_LOCATION_PERMISSIONS.NOT_KNOWN;
  const { navigator } = window;
  if (!navigator?.permissions?.query) return USER_LOCATION_PERMISSIONS.NOT_KNOWN;
  return navigator.permissions.query({ name: 'geolocation' }).then((status) => {
    const resultingStatus = mapBrowserStatusToLocalStatus(status);
    setGenericStorageItem(
      USER_LOCATION_ACCEPTANCE,
      resultingStatus,
      StorageType.LOCAL_STORAGE,
    );
    return resultingStatus;
  });
};

export const geolocationSuccess = async (position, successCallback) => {
  if (!position) return;

  const currentLocationPermission = await checkUserLocationPermissions();
  const hasPermission = currentLocationPermission === USER_LOCATION_PERMISSIONS.ACCEPTED;
  if (!hasPermission) {
    setGenericStorageItem(
      USER_LOCATION_ACCEPTANCE,
      USER_LOCATION_PERMISSIONS.ACCEPTED,
      StorageType.LOCAL_STORAGE,
    );
    // Notify other components that we have updated session storage
    window.dispatchEvent(new Event('storage'));
  }

  const { coords } = position;

  const parsedLocation = {
    lat: coords?.latitude,
    lng: coords?.longitude,
  };

  // Store the user's last known location - needed in Safari as we
  // get incorrect reporting on our location permissions
  setGenericStorageItem(
    USER_LAST_LOCATION,
    JSON.stringify(parsedLocation),
    StorageType.SESSION_STORAGE,
  );

  if (successCallback) {
    successCallback(parsedLocation);
  }
};

export const geolocationFailure = (error, failureCallback) => {
  // Check why geolocation failed, usually due to timeout or user blocking the request
  // Codes - https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError#instance_properties
  removeGenericStorageItem(USER_LAST_LOCATION, StorageType.SESSION_STORAGE);
  switch (error?.code ?? error) {
    case GEOLOCATION_FAILURE_CODES.DENIED: {
      setGenericStorageItem(
        USER_LOCATION_ACCEPTANCE,
        USER_LOCATION_PERMISSIONS.DECLINED,
        StorageType.LOCAL_STORAGE,
      );
      break;
    }
    default: {
      setGenericStorageItem(
        USER_LOCATION_ACCEPTANCE,
        USER_LOCATION_PERMISSIONS.NOT_KNOWN,
        StorageType.LOCAL_STORAGE,
      );
    }
  }
  // Notify other components that we have updated session storage
  window.dispatchEvent(new Event('storage'));
  if (failureCallback) {
    failureCallback(error);
  }
};

export const getUserLocation = (
  successCallback = () => {},
  failureCallback = () => {},
) => {
  if (!window) return;
  if (!('geolocation' in navigator)) return;
  navigator.geolocation.getCurrentPosition(
    (position) => geolocationSuccess(position, successCallback),
    (error) => geolocationFailure(error, failureCallback),
  );
};

export const getUserLocationAsync = () => {
  if (!('geolocation' in navigator)) return null;
  return new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition((position) => {
    geolocationSuccess(position);
    const { coords } = position;
    if (!position) reject();
    resolve({
      lat: coords?.latitude,
      lng: coords?.longitude,
    });
  }, (error) => {
    geolocationFailure(error);
    reject(error);
  }, {
    timeout: 10000,
  }));
};

export const getLastKnownLocation = () => {
  const location = getGenericStorageItem(USER_LAST_LOCATION);
  if (!location) return null;
  return JSON.parse(location);
};

// Safari mis-reports our access to location permissions, so we never really
// know if we have permission or not. Current solution to this is to store
// the last known location which is cleared when the user session is reset.
// If we have this, we can assume we have permission.
// Bug open in Webkit is linked on CAMC23UAT-251
export const getActualLocationPermissions = (fallback = USER_LOCATION_PERMISSIONS.NOT_KNOWN) => {
  const currentPermission = getGenericStorageItem(
    USER_LOCATION_ACCEPTANCE,
    StorageType.LOCAL_STORAGE,
  );
  if (currentPermission === USER_LOCATION_PERMISSIONS.ACCEPTED) return currentPermission;
  const haveLastKnownLocation = hasGenericStorageItem(USER_LAST_LOCATION);
  const actualLocationPermission = haveLastKnownLocation
    ? USER_LOCATION_PERMISSIONS.ACCEPTED
    : fallback;
  return actualLocationPermission;
};

/*
  Gets the position of a given map tile for a given lat/lng

  @param  lat     Number  The latitude to find the tile for
  @param  lng     Number  The latitude to find the tile for
  @param  zoom    Number  Integer value representing the map zoom level

  @returns  Object with the coordinates for the found tile
*/
function latLonToTile(lat, lng, zoom) {
  const latitudeRadians = (lat * Math.PI) / 180;
  const scale = 2 ** zoom;
  const xTile = ((lng + 180) / 360) * scale;
  const yTile =
    ((1 -
      Math.log(Math.tan(latitudeRadians) + 1 / Math.cos(latitudeRadians)) /
        Math.PI) /
      2) *
    scale;
  return { xTile, yTile };
}

/*
  Gets the lat/lng of a given map tile at a given zoom level

  @param  xTile   Number  Index of the tile in x-direction
  @param  yTile   Number  Index of the tile in y-direction
  @param  zoom    Number  Integer value representing the map zoom level

  @returns  Object with a latitude and longitude for the chosen tile
*/
function tileToLatLng(xTile, yTile, zoom) {
  const scale = 2 ** zoom;
  const longitudeDegrees = (xTile / scale) * 360 - 180;
  const latitudeRadians = Math.atan(Math.sinh(Math.PI * (1 - (2 * yTile) / scale)));
  const latitudeDegrees = (latitudeRadians * 180) / Math.PI;
  return { lat: latitudeDegrees, lng: longitudeDegrees };
}

/*
  Helper for generating bounds around a center point at a certain zoom level

  @param  center        Object  An object with 'lat' and 'lng' denoting the center of the map
    @param  lat Number
    @param  lng Number
  @param  zoomLevel     Number  Zoom level based on which we define bounds
  @param  mapDimensions Object  An object with width and height for the map element
    @param  width   Number
    @param  height  Number

  @returns  Two padding points for the NW and SE of the calculated bounds
*/
export const getBoundsFromCenterAndZoom = (center, zoomLevel, mapDimensions) => {
  const { lat, lng } = center;
  const { width: mapWidthPx, height: mapHeightPx } = mapDimensions;

  // Convert the center latitude and longitude to tile coordinates
  const centerTile = latLonToTile(lat, lng, zoomLevel);

  // Calculate the number of tiles for the given map dimensions
  const numTilesX = mapWidthPx / GOOGLE_MAP_TILE_SIZE_PX;
  const numTilesY = mapHeightPx / GOOGLE_MAP_TILE_SIZE_PX;

  const middleTileX = numTilesX / 2;
  const middleTileY = numTilesY / 2;

  // Calculate the bounds in tile coordinates
  const minTileX = centerTile.xTile - middleTileX;
  const maxTileX = centerTile.xTile + middleTileX;
  const minTileY = centerTile.yTile - middleTileY;
  const maxTileY = centerTile.yTile + middleTileY;

  // Convert the tile coordinates back to latitude and longitude
  const southwest = tileToLatLng(minTileX, maxTileY, zoomLevel);
  const northeast = tileToLatLng(maxTileX, minTileY, zoomLevel);

  return [
    northeast,
    southwest,
  ];
};
