import React from 'react';
import PropTypes from 'prop-types';

import {
  ContentWrapper, Image, ImageWrapper, Wrapper, TermsLink, Text,
} from './SpecialOfferPopUp.style';
import { dictionaryItem } from '../../hocs/withDictionary';

const propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
  termsLink: PropTypes.string,
};

const defaultProps = {
  image: '',
  text: '',
  termsLink: '',
};

function SpecialOfferPopUp(props) {
  return (
    <Wrapper>
      <ImageWrapper>
        <Image
          src={props.image.replace(/\s/g, '%20')}
        />
      </ImageWrapper>
      <ContentWrapper>
        <Text align="start">
          {props.text}
        </Text>
        {props.termsLink && (
          <TermsLink href={props.termsLink} target="_blank">
            <Text dictionary={dictionaryItem('SpecialOfferPopUp', 'TermsLink')} />
          </TermsLink>
        )}
      </ContentWrapper>
    </Wrapper>
  );
}

SpecialOfferPopUp.propTypes = propTypes;
SpecialOfferPopUp.defaultProps = defaultProps;

export default SpecialOfferPopUp;
