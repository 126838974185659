import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { ButtonIcon } from '../ui/Button';

import svgList from '../../static/images/icons/List.svg';
import svgLocationPin from '../../static/images/icons/LocationPin.svg';

function RenderIcon({ showResults, isMobileMapOpen }) {
  if (isMobileMapOpen) {
    // When map is toggle, but there is not results
    // redirect to form.
    if (!showResults) {
      return (
        <Fragment>
          <ButtonIcon icon={svgList} />
          <span>Show Form</span>
        </Fragment>
      );
    }
    // When map is toggle and there are results,
    // redirect to list.
    return (
      <Fragment>
        <ButtonIcon icon={svgList} />
        <span>Show List</span>
      </Fragment>
    );
  }
  // When map is not toggled.
  return (
    <Fragment>
      <ButtonIcon icon={svgLocationPin} />
      <span>{showResults ? 'Show Map' : 'Explore Map'}</span>
    </Fragment>
  );
}

RenderIcon.propTypes = {
  showResults: PropTypes.bool.isRequired,
  isMobileMapOpen: PropTypes.bool.isRequired,
};

export default RenderIcon;
