import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';
import Router from 'next/router';

import testingAttr from '../../lib/testingAttr';
import keyCode from '../../lib/keyCode';

import {
  Button,
  Container,
  List,
  Wrapper,
} from './Typeahead.style';

class Typeahead extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    items: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.shape(),
      PropTypes.string,
    ])),
    keypath: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    value: PropTypes.string,
    isOverseas: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    items: [],
    keypath: null,
    onChange() {},
    onClick() {},
    value: '',
  };

  static getDerivedStateFromProps(nextProps) {
    const { value } = nextProps;
    if (!value) return '';
    return { value };
  }

  constructor(props) {
    super(props);

    // Handle empty names array
    if (!props.items || !props.items.length) {
      Router.push({
        pathname: '/error',
        query: {
          error: 'names204',
        },
      });
      return null;
    }

    this.state = {
      items: props.items.length ? props.items.slice(0, 6) : [],
      activeIndex: -1,
      visible: false,
    };

    this.fuseOptions = {
      keys: ['name'],
      findAllMatches: true,
      shouldSort: true,
      // if UK show only exact matches, overseas doesn't return results with accents
      // as they aren't exact matches
      threshold: this.props.isOverseas ? 0.6 : 0,
    };

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    window.document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.document.removeEventListener('mousedown', this.handleClickOutside);
  }

  toggleDropdown = (visible = !this.state.visible) => {
    this.setState({ visible });
  }

  handleClickOutside = (e) => {
    if (!this.state.visible) return;

    if (this.containerRef && !this.containerRef.current.contains(e.target)) {
      this.toggleDropdown(false);
    }
  }

  handleFocus = (e) => {
    this.toggleDropdown(true);
    this.handleChange(e);
  }

  handleSetValue = (value) => {
    this.props.onChange(value);
  }

  handleButtonClick = (value) => {
    this.handleSetValue(value);
    this.toggleDropdown();
  }

  handleArrowInput = async (newIndex) => {
    await this.setState({ activeIndex: newIndex });
    const value = this.state.items[this.state.activeIndex];
    return this.handleSetValue(value);
  }

  handleKeyUp = (e) => {
    e.preventDefault();
    const { activeIndex } = this.state;

    if (e.which === keyCode.enter) {
      const value = this.state.items[activeIndex];
      this.handleSetValue(value);
      return this.toggleDropdown();
    }

    if (e.which === keyCode.escape || e.keyCode === keyCode.escape) {
      return this.toggleDropdown(false);
    }

    if (e.which === keyCode.arrowDown) {
      // when you are on the last item, and click arrow down
      // new selected item will be first item in the list.
      if (activeIndex >= this.state.items.length - 1) {
        return this.handleArrowInput(0);
      }
      // increase list index by 1;
      return this.handleArrowInput(activeIndex + 1);
    }

    if (e.which === keyCode.arrowUp) {
      // when you are on the first item, and click arrow down
      // new selected item will be last item in the list.
      if (activeIndex <= 0) {
        return this.handleArrowInput(this.state.items.length - 1);
      }
      // decrease list index by 1;
      return this.handleArrowInput(activeIndex - 1);
    }

    if (this.state.visible) return false;

    return this.toggleDropdown(true);
  }

  handleChange = (e) => {
    const { items } = this.props;
    const { value } = e.target;

    let filteredItems = items;

    if (value) {
      const fuse = new Fuse(items, this.fuseOptions);
      filteredItems = fuse.search(value);
    }

    this.setState({
      items: filteredItems.slice(0, 6),
    });

    this.props.onChange({
      name: value,
      id: null,
    });
  }

  render() {
    const { keypath } = this.props;

    const inputProps = {
      onBlur: this.handleBlur,
      onChange: this.handleChange,
      onFocus: this.handleFocus,
      onKeyUp: this.handleKeyUp,
      type: 'text',
      value: this.props.value,
    };

    return (
      <Container ref={this.containerRef}>
        {React.Children.map(this.props.children, child => (
          React.cloneElement(child, { ...inputProps })
        ))}
        <Wrapper>
          {this.state.visible &&
            <List>
              {this.state.items.map((item, index) => (
                <li
                  key={item.id}
                >
                  <Button
                    type="button"
                    onClick={() => this.handleButtonClick(item)}
                    active={this.state.activeIndex === index}
                    {...testingAttr(`search__typeahead-btn-${item.id}`)}
                  >
                    {keypath ? item[keypath] : keypath}
                  </Button>
                </li>
              ))}
            </List>
          }
        </Wrapper>
      </Container>
    );
  }
}

export default Typeahead;
