import styled from 'styled-components';

export const Pager = styled.nav`
  display: flex;
  width: auto;
  font-size: 0.9rem;
  margin: 2rem 0 0.5rem;
  justify-content: center;
`;

export const PageLink = styled.a`
  padding: 0.5em;
  margin: 0 0.15em;
  font-weight: ${props => (props.active ? 'bold' : null)};
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  transition: opacity 0.25s ease-in-out;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  user-select: none;
  cursor: pointer;
`;

export const PageLinkArrow = styled(PageLink)`
  position: relative;
  text-indent: -9999vw;

  &::before {
    content: '';
    top: calc(50% - 0.4em);
    left: ${props => (props.right ? 'auto' : 0)};
    right: ${props => (props.right ? 0 : 'auto')};
    position: absolute;
    width: 0.8em;
    height: 0.8em;
    border-top: 2px solid ${props => props.theme.COLOR_TEXT_BODY};
    border-right: 2px solid ${props => props.theme.COLOR_TEXT_BODY};
    transform: ${props => (props.right ? 'rotate(45deg)' : 'rotate(-135deg)')};
  }
`;
