import styled, { css } from 'styled-components';

import Button from '../Button';

export default styled.div`
  margin-bottom: 0.5rem;
`;

export const Toggle = styled(Button)`
  color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY};
  font-size: 0.8125rem;
  font-weight: 600;
  text-transform: none;

  ${({ bordered, theme }) => bordered && css`
    border-color: ${theme.COLOR_BRAND_PRIMARY_DARK};
    border-radius: 0;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  `}
`;
