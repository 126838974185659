import gql from 'graphql-tag';

export function getPoiOptions({ isPoi }) {
  return {
    variables: {
      skip: !isPoi,
    },
  };
}

export default gql`
  query Poi {
    poi @rest(
      type: "Poi",
      path: "pointsofinterest/all"
    ) {
      id
      image
      promotionalText
      offerTitle
      offerEndDateTime
      startDateTime
      endDateTime
      name
      lat
      lon
      type
      zoomLevel
      poIUrl
      features
    }
  }
`;
