import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';

import { SiteCardBack, SiteCardClose, SiteCardNavigate } from '.';

import StyledSiteCard, { BasketToggleMobile, Header, Overflow } from './SiteCard.style';

import theme from '../../styles/config/theme';
import IbePropTypes from '../../IbePropTypes';

const propTypes = {
  router: PropTypes.shape(IbePropTypes.router).isRequired,
  backgroundColor: PropTypes.string,
  toggleBasket: PropTypes.func,
  handleDrawerToggle: PropTypes.func,
  handleNext: PropTypes.func,
  children: PropTypes.node.isRequired,
  scroll: PropTypes.bool,
  type: PropTypes.string,
  visible: PropTypes.bool,
  scrollPosition: PropTypes.number,
  ref: PropTypes.func,
};

const defaultProps = {
  backgroundColor: theme.COLOR_GRAY_LIGHTER,
  toggleBasket: () => {},
  handleDrawerToggle: () => {},
  handleNext: null,
  scroll: true,
  type: 'close', // back/close
  visible: true,
  scrollPosition: null,
  ref: () => {},
};

function SiteCard(props) {
  const {
    backgroundColor,
    children,
    handleDrawerToggle,
    scroll,
    type,
    visible,
    scrollPosition,
    toggleBasket,
    handleNext,
    router,
  } = props;

  let Button = type === 'back' ?
    SiteCardBack :
    SiteCardClose;

  if (handleNext) {
    Button = SiteCardNavigate;
  }

  const hideHeader = !!router.query.bookingId;

  return (
    <StyledSiteCard
      backgroundColor={backgroundColor}
      visible={visible}
      scrollPosition={scrollPosition}
    >
      {!hideHeader &&
      <Header backgroundColor={backgroundColor}>
        <Button
          type="button"
          handleClick={handleDrawerToggle}
          handleNext={handleNext}
        />
        <BasketToggleMobile
          handleClick={toggleBasket}
        />
      </Header>}
      <Overflow
        scroll={scroll}
        ref={props.ref}
      >
        {children}
      </Overflow>
    </StyledSiteCard>
  );
}

SiteCard.propTypes = propTypes;
SiteCard.defaultProps = defaultProps;

export default withRouter(SiteCard);
