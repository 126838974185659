import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose, withApollo } from 'react-apollo';
import { withTheme } from 'styled-components';
import _chunk from 'lodash/chunk';

import { getDictionaryItem } from '../../../hocs/withDictionary';
import { Modal } from '../../ui';
import ReadMore from '../../ui/ReadMore';
import FlexTable, { FlexCell, FlexRow } from '../../ui/FlexTable';

import { List } from '../../CampsiteFeature/CampsiteFeature.style';
import CampsiteFeatureIcon from '../../CampsiteFeature/CampsiteFeatureIcon';
import ListingHighlight from '../../Listing/ListingHighlight';
import IbePropTypes from '../../../IbePropTypes';
import { dataLayerManager } from '../../../lib/dataLayer';

import ProductModalStyled, {
  LeftContentContainer,
  OverlayLayer,
  ButtonClose,
  Content,
  ContentTitle,
  ContentDescription,
  Title,
  Text,
} from '../../ui/Modal/ProductModal.style';

const propTypes = {
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  itinerary: PropTypes.shape(IbePropTypes.ukItinerary),
  handleClose: PropTypes.func.isRequired,
};

const defaultProps = {
  itinerary: null,
};

function ItineraryModal({
  client,
  itinerary,
  handleClose,
}) {
  const nightsSuffix = getDictionaryItem(client, 'SuggestedUkItinerary__NightsSuffix__Text');
  const idealForText = getDictionaryItem(client, 'SuggestedUkItinerary__IdealFor__Text');

  useEffect(() => {
    if (itinerary) {
      dataLayerManager.pushProductDetails(dataLayerManager.subCategory.UK_ITINERARY, {
        id: itinerary.id,
        name: itinerary.name,
        subCategory: dataLayerManager.subCategory.UK_ITINERARY,
      });
    }
  }, [itinerary]);

  return (
    <Modal active={!!itinerary}>
      <ProductModalStyled zIndex={199}>
        <ButtonClose
          crossWidth="1.875rem"
          onClick={handleClose}
          width="4.125rem"
          height="4.125rem"
          small
        />
        <Content
          backgroundImage={itinerary?.backgroundImageUrl}
        >
          <OverlayLayer />
          <LeftContentContainer>
            <Title tag={0} marginBottom small>{itinerary?.name}</Title>
            <Text>{itinerary?.country}</Text>
          </LeftContentContainer>
        </Content>
        <Content>
          <LeftContentContainer withPadding>
            <ContentTitle>{itinerary?.descriptionTitle}</ContentTitle>
            <ContentDescription>
              {itinerary?.descriptionText?.length > 150 ?
                <ReadMore text={itinerary?.descriptionText} limit={150} /> :
                itinerary?.descriptionText}
            </ContentDescription>
            <ContentTitle>{itinerary?.itineraryLengthTitle}</ContentTitle>
            <ContentDescription>{itinerary?.itineraryLength} {nightsSuffix}</ContentDescription>
            <ContentTitle>{itinerary?.whatToExpectTitle}</ContentTitle>
            <List highlight compact>
              {itinerary?.whatToExpect?.map((whatToExpect) => (
                <ListingHighlight key={whatToExpect} label={whatToExpect} small check compact />
              ))}
            </List>
            <ContentTitle marginTop="2rem">{idealForText}</ContentTitle>
            <FlexTable>
              {_chunk(itinerary?.idealFor, 3)?.map((idealForChunk) => (
                <FlexRow key={idealForChunk[0]?.name} chunk>
                  {idealForChunk.map((idealFor) => (
                    <FlexCell key={idealFor.name} justifyContent="flex-start" flex={1}>
                      <CampsiteFeatureIcon
                        name={idealFor.name}
                        iconUrl={idealFor.icon}
                      />
                      {idealFor.name}
                    </FlexCell>
                  ))}
                </FlexRow>
              ))}
            </FlexTable>
          </LeftContentContainer>
        </Content>
      </ProductModalStyled>
    </Modal>
  );
}

ItineraryModal.propTypes = propTypes;
ItineraryModal.defaultProps = defaultProps;

export default compose(
  memo,
  withTheme,
  withApollo,
)(ItineraryModal);
