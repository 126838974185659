import React from 'react';

import StyledSearchOffline, { Icon, Message, Title } from './SearchOffline.style';

import svgError from '../../static/images/icons/Error.svg';
import { dictionaryItem } from '../../hocs/withDictionary';

function SearchOffline() {
  return (
    <StyledSearchOffline>
      <Icon icon={svgError} />
      <div>
        <Title
          dictionary={dictionaryItem('StyledSearchOffline')}
        />
        <Message>
          You may still browse previous searches but can recieve unexpected results.
        </Message>
      </div>
    </StyledSearchOffline>
  );
}

export default SearchOffline;
