import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from '../ui/Grid';
import testingAttr from '../../lib/testingAttr';
import { dictionaryItem } from '../../hocs/withDictionary';
import { CTA } from './ListingBookNow.style';
import Rating from '../Rating';
import { ids } from '../../config/campsiteTypes';

import {
  Button,
  ColCTA,
  BookingDetails,
} from './ListingResult.style';

function RevealAddress({
  handleReviewsClick,
  onRevealClick,
  noOfReviews,
  rating,
  reviewsAreDisabled,
  hideAddress,
  campsiteType,
}) {
  const noRatingCampsiteTypes = [
    ids.UK_AFFILIATED_SITES,
    ids.OVERSEAS_TOUR,
  ];

  const showRating = noRatingCampsiteTypes.includes(campsiteType) === false;
  const noNeedToShowAnything
    = !hideAddress && noRatingCampsiteTypes.includes(campsiteType) === true;

  return (
    <>
      {!noNeedToShowAnything &&
      <BookingDetails>
        <Row align="center" size="small">
          <Col>
            {showRating &&
              <Button
                disabled={reviewsAreDisabled}
                onClick={handleReviewsClick}
              >
                <Rating
                  noOfReviews={noOfReviews}
                  rating={rating}
                  size="0.625rem"
                />
              </Button>
            }
          </Col>
          <ColCTA>
            {hideAddress &&
              <CTA
                onClick={onRevealClick}
                {...testingAttr('listing-result__reveal-address-btn')}
                dictionary={dictionaryItem('ListingResult', 'LoginPopUp', 'ViewAddress')}
              />
            }
          </ColCTA>
        </Row>
      </BookingDetails>
      }
    </>
  );
}

RevealAddress.propTypes = {
  onRevealClick: PropTypes.func.isRequired,
  handleReviewsClick: PropTypes.func.isRequired,
  noOfReviews: PropTypes.number.isRequired,
  rating: PropTypes.number.isRequired,
  reviewsAreDisabled: PropTypes.bool.isRequired,
  hideAddress: PropTypes.bool.isRequired,
  campsiteType: PropTypes.number.isRequired,
};

export default memo(RevealAddress);
