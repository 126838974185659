import React from 'react';
import PropTypes from 'prop-types';

import testingAttr from '../../lib/testingAttr';

import CampsiteFeatureIcon from '../CampsiteFeature/CampsiteFeatureIcon';

import StyledFilter, { FilterActive, FilterCount, FilterText } from './Filter.style';

import svgTick from '../../static/images/icons/Tick.svg';

const propTypes = {
  active: PropTypes.bool.isRequired,
  count: PropTypes.number,
  disabled: PropTypes.bool,
  handleToggleFilter: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  iconUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

const defaultProps = {
  count: undefined,
  disabled: false,
  iconUrl: undefined,
};

function Filter({
  active,
  count,
  disabled,
  handleToggleFilter,
  iconUrl,
  id,
  name,
  type,
}) {
  return (
    <StyledFilter
      disabled={disabled}
      onClick={() => handleToggleFilter(id, type)}
      active={active}
      {...testingAttr(`filters__filter-${id}`)}
    >
      <CampsiteFeatureIcon
        key={id}
        id={id}
        name={name}
        iconUrl={iconUrl}
      />
      <FilterText>{name}</FilterText>
      {active &&
        <FilterActive
          align="right"
          icon={svgTick}
          marginLeft="0.5rem"
        >
          {id}
        </FilterActive>
      }
      {!!count &&
        <FilterCount>{count}</FilterCount>
      }
    </StyledFilter>
  );
}

Filter.propTypes = propTypes;
Filter.defaultProps = defaultProps;

export default Filter;
