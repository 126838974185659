import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import { Overlay } from '../ui/Modal';

import Popper from '../ui/Popper';

import {
  PopUpWrapper,
  ButtonClose,
} from './PopUp.styles';

function PopUp(props) {
  return (
    <Popper open={props.open}>
      {() => (
        <Fragment>
          <PopUpWrapper
            open={props.open}
            visible={props.visible}
            width={props.width}
            minHeight={props.minHeight}
          >
            <ButtonClose onClick={props.handleCloseBtnClick}>Close</ButtonClose>
            {React.cloneElement(props.children, {
              handleCloseBtnClick: props.handleCloseBtnClick,
            })}
          </PopUpWrapper>
          {props.overlayVisible && <Overlay zIndex={197} />}
        </Fragment>
      )}
    </Popper>
  );
}

PopUp.propTypes = {
  width: PropTypes.string,
  minHeight: PropTypes.string,
  open: PropTypes.bool,
  visible: PropTypes.bool,
  children: PropTypes.node.isRequired,
  overlayVisible: PropTypes.bool,
  handleCloseBtnClick: PropTypes.func,
};

PopUp.defaultProps = {
  width: '',
  minHeight: '',
  open: false,
  visible: false,
  overlayVisible: false,
  handleCloseBtnClick() { },
};

export default memo(PopUp);
