import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'next/router';
import { Tooltip } from 'react-tooltip';

import queryHelper from '../../lib/queryHelper';
import testingAttr from '../../lib/testingAttr';

import { LoadingBlockButton } from '../ui/Loading';

import GET_CAMPSITE_COMPARISON from './graphql/getCampsiteComparison';
import UPDATE_COMPARE_MODULE_STATE from './graphql/updateCompareModuleState';
import UPDATE_CAMPSITE_COMPARISON from './graphql/updateComparisonCampsites';

import { CompareBtn, TooltipAction } from './AddCampsiteToCompareBtn.style';
import ButtonBordered from '../ui/Button/ButtonBordered.style';
import IbePropTypes from '../../IbePropTypes';

class AddCampsiteToCompareBtn extends Component {
  static propTypes = {
    campsite: PropTypes.shape(IbePropTypes.campsite).isRequired,
    selectedEvent: PropTypes.shape(IbePropTypes.event),
    comparisonCampsites: PropTypes.shape({
      campsites: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    updateComparisonCampsites: PropTypes.func.isRequired,
    updateCompareModuleState: PropTypes.func.isRequired,
    maxQuantity: PropTypes.number,
    router: PropTypes.shape(IbePropTypes.router).isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    comparisonCampsites: {
      campsites: [],
    },
    maxQuantity: 3,
    loading: true,
    selectedEvent: undefined,
  }

  removeCampsiteFromCompare = (id = this.props.campsite.id) => {
    const campsites = this.props.comparisonCampsites.campsites
      .filter(obj => obj.id !== id);

    this.props.updateComparisonCampsites({ campsites });
  }

  addCampsiteToCompare = () => {
    const campsites = [...this.props.comparisonCampsites.campsites];

    const campsiteToCompare = {
      ...this.props.campsite,
      __typename: 'ComparisonCampsite',
      arrive: this.props.router.query.start || null,
      depart: this.props.router.query.end || null,
    };

    const {
      files,
      events,
      ...campsite
    } = campsiteToCompare;

    const selectedEvent = {
      ...this.props.selectedEvent,
      features: this.props.selectedEvent?.features?.map((feature) => (
        {
          ...feature,
          __typename: 'Feature',
          value: feature.value ?? '',
        }
      )),
      files: this.props.selectedEvent?.files?.map((file) => ({
        ...file,
        __typeName: 'CampsiteFile',
      })),
    };

    campsites.push({
      ...campsite,
      features: campsite.features.map((feature) => ({
        ...feature,
        __typename: 'Feature',
      })),
      selectedEvent,
    });

    this.openComparison();
    this.props.updateComparisonCampsites({ campsites });
  }

  isAlreadyAddedToCompare = (id = this.props.campsite.id) => {
    if (!Array.isArray(this.props.comparisonCampsites.campsites)) return false;

    const camp = this.props.comparisonCampsites.campsites.find(campsite => campsite.id === id);

    if (camp) return true;
    return false;
  }

  openComparison = () => {
    this.props.updateCompareModuleState(true);
  }

  isDisabled = () => {
    if (this.isAlreadyAddedToCompare()) return true;

    if (!this.props.selectedEvent) return true;

    if (Array.isArray(this.props.comparisonCampsites?.campsites)) {
      return this.props.comparisonCampsites?.campsites.length >= this.props.maxQuantity;
    }

    return false;
  }

  render() {
    if (this.props.loading) {
      return (
        <LoadingBlockButton
          block
          size="large"
          type="button"
          disabled
        />
      );
    }

    return (
      <CompareBtn>
        {this.isDisabled() &&
          <Tooltip
            place="bottom"
            effect="solid"
          />
        }
        <TooltipAction
          data-tip={`
            <p>
              You can only compare up to ${this.props.maxQuantity} sites at a time.<br />
              Please remove a site before trying to add another.
            </p>
          `}
        >
          <Fragment>
            {
              !this.isAlreadyAddedToCompare() &&
              <ButtonBordered
                block
                disabled={this.isDisabled()}
                onClick={this.addCampsiteToCompare}
                size="large"
                {...testingAttr('add-to-compare-button')}
              >
                Compare
              </ButtonBordered>
            }
            {
              this.isAlreadyAddedToCompare() &&
              <ButtonBordered
                block
                onClick={() => this.removeCampsiteFromCompare(this.props.campsite.id)}
                size="large"
                {...testingAttr('remove-to-compare-button')}
              >
                Remove from Compare
              </ButtonBordered>
            }
          </Fragment>
        </TooltipAction>
      </CompareBtn>
    );
  }
}

export default compose(
  withRouter,
  queryHelper(GET_CAMPSITE_COMPARISON, 'comparisonCampsites'),
  graphql(UPDATE_CAMPSITE_COMPARISON, {
    props: ({ mutate }) => ({
      updateComparisonCampsites: comparisonCampsites => mutate({
        variables: { comparisonCampsites },
      }),
    }),
  }),
  graphql(UPDATE_COMPARE_MODULE_STATE, {
    props: ({ mutate }) => ({
      updateCompareModuleState: active => mutate({ variables: { active } }),
    }),
  }),
)(AddCampsiteToCompareBtn);
