import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

import { FEATURE_FLAGS } from '../../config/featureFlags';
import withPhase from '../../hocs/withPhase';
import { dictionaryItem } from '../../hocs/withDictionary';

import { CTA, CTALink } from './ListingBookNow.style';

const propTypes = {
  callToBook: PropTypes.shape({
    text: PropTypes.string,
    phoneNumber: PropTypes.string,
    toursPhoneNumber: PropTypes.string,
  }),
  campsiteType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  BOOK_UK_CAMPSITES: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isTours: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  testingAttr: PropTypes.shape(),
};

const defaultProps = {
  callToBook: {},
  campsiteType: null,
  disabled: false,
  isTours: false,
  testingAttr: {},
};

const baseUrl = 'https://www.caravanclub.co.uk/';

function ListingBookNow({ disabled, onClick, ...props }) {
  if (props.isTours) {
    return (
      <CTALink
        align="center"
        block
        href={`tel:${props.callToBook?.toursPhoneNumber}`}
        size="large"
        data-testid="button.bookNow"
        multiText
      >
        <span>{props.callToBook?.text}</span>
        <span>{props.callToBook?.toursPhoneNumber}</span>
      </CTALink>
    );
  }
  // If UK campsite bookings are disabled and site is of type UK Club Site type
  if (props.BOOK_UK_CAMPSITES && props.campsiteType === 0) {
    // TODO: Replace with current site link
    const href = `${baseUrl}`;

    return (
      <Link href={href}>
        <CTALink
          align="center"
          block
          href={href}
          size="large"
          data-testid="button.bookNow"
          dictionary={dictionaryItem('ListingBookNow', 'BookNow')}
        />
      </Link>
    );
  }

  // If UK campsite bookings are disabled and site is of type CL or Affiliate
  // TODO: 'This site is available for members only and is not bookable online:'
  if (!props.BOOK_UK_CAMPSITES && [1, 2].includes(props.campsiteType)) {
    return (
      <CTA
        block
        disabled={disabled}
        onClick={onClick}
        size="large"
        type="button"
        data-testid="button.bookNow"
        dictionary={dictionaryItem('ListingBookNow', 'BookNow')}
      />
    );
  }

  // Load the availability form - default
  return (
    <CTA
      block
      disabled={disabled}
      onClick={onClick}
      size="large"
      type="button"
      data-testid="button.bookNow"
      dictionary={dictionaryItem('ListingBookNow', 'BookNow')}
    />
  );
}

ListingBookNow.propTypes = propTypes;
ListingBookNow.defaultProps = defaultProps;

export default withPhase(ListingBookNow, FEATURE_FLAGS.BOOK_UK_CAMPSITES);
