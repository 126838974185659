import React, { Component, createRef, memo } from 'react';
import PropTypes from 'prop-types';

import {
  isValid,
  startOfDay,
  addDays,
  isBefore,
  subDays,
  format,
} from 'date-fns';
import { formatToHyphenFormat } from '../../../lib/format';
import testingAttr from '../../../lib/testingAttr';
import pickStateOrPayloadDate from '../../../lib/pickStateOrPayloadDate';
import { isTabletOrSmaller } from '../../../lib/helpers/layout';

import { dictionaryItem } from '../../../hocs/withDictionary';

import { DATE_FORMAT_INPUT } from '../../../config/locale';

import {
  Label,
  FormGroup,
  FormGroupItem,
} from '../SearchForm.style';

import {
  Datepicker,
  DateInputWrapper,
  SearchDateInput,
} from './CampsitesDatePickerWrapper.style';

import svgCalendar from '../../../static/images/icons/Calendar.svg';
import {
  dateInputValidation as dateInputValidationRegex,
} from '../../../lib/regexes';

class CampsitesDatePickerWrapper extends Component {
  static propTypes = {
    dateErrors: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      message: PropTypes.string,
    })),
    onError: PropTypes.func.isRequired,
    handleDateChangeByInput: PropTypes.func.isRequired,
    handleClear: PropTypes.func.isRequired,
    handleKeyDownDate: PropTypes.func.isRequired,
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    availabilityMaxDate: PropTypes.string.isRequired,
    handleDateChange: PropTypes.func.isRequired,
    size: PropTypes.string,
    offsetDays: PropTypes.number,
  };

  static defaultProps = {
    dateErrors: [],
    size: 'medium',
    offsetDays: 2,
  };

  state = {
    startDate: '',
    endDate: '',
    fieldFocus: '',
  }

  inputRef = createRef();

  validateDateInputs = (event) => {
    const { name, value } = event.target;
    // persist the previous errors.
    const errorArr = this.props.dateErrors.filter(error => error.type !== name);

    // go to adding errors if Regex fails and if its full date.
    // (no point for validation while user is typing)
    if (!dateInputValidationRegex.test(value) && value.length >= 10) {
      // add error while date is not valid
      const newDate = formatToHyphenFormat(value);
      if (name === 'startDate' && !isValid(new Date(newDate))) errorArr.push({ type: 'startDate', message: dictionaryItem('CampsiteDatePickerWrapper', 'Start', 'Date', 'Error') });
      if (name === 'endDate' && !isValid(new Date(newDate))) errorArr.push({ type: 'endDate', message: dictionaryItem('CampsiteDatePickerWrapper', 'End', 'Date', 'Error') });
    }

    this.props.onError(errorArr);
    // handle populating
    this.handleDateInput(event);
  }

  checkForValidDateRange = (name) => {
    const { startDate, endDate } = this.state;
    if (!(startDate && endDate)) return;

    const start = new Date(formatToHyphenFormat(startDate));
    const end = new Date(formatToHyphenFormat(endDate));

    if (isBefore(end, start)) {
      if (name === 'startDate') {
        this.setState({ endDate: '' }, () => {
          this.props.handleDateChangeByInput('end', '');
        });
      } else {
        const newStart = format(subDays(end, 1), 'DD/MM/YYYY');
        this.setState({ startDate: newStart }, () => {
          this.props.handleDateChangeByInput('start', newStart);
        });
      }
    }
  }

  handleDateInput = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    }, () => {
      if (value.length === 10 && isValid(new Date(formatToHyphenFormat(value)))) {
        this.props.handleDateChangeByInput(name === 'startDate' ? 'start' : 'end', value);
        this.checkForValidDateRange(name);
      }
    });
  }

  handleDateChange = ([start, end]) => {
    const { startDate, endDate } = this.state;

    this.setState({ fieldFocus: '' });

    if (start) {
      this.setState({ startDate: format(new Date(start), 'DD/MM/YYYY') });
    } else if (startDate) {
      this.setState({ startDate: '' });
    }

    if (end) {
      this.setState({ endDate: format(new Date(end), 'DD/MM/YYYY') });
    } else if (endDate) {
      this.setState({ endDate: '' });
    }

    this.props.handleDateChange([start, end]);
  }

  handleClear = async () => {
    await this.setState({
      startDate: '',
      endDate: '',
    });

    this.props.handleClear();
  }

  handleFocus = (event) => {
    this.setState({
      fieldFocus: event.target.name,
    });
  }

  render() {
    const { size, offsetDays } = this.props;

    return (
      <Datepicker
        anchor={this.inputRef}
        closingIgnoreList={['startDate', 'endDate']}
        fieldFocus={this.state.fieldFocus}
        endDate={isValid(new Date(formatToHyphenFormat(this.props.end))) ? formatToHyphenFormat(this.props.end) : ''}
        handleChange={() => { }}
        handleClear={this.handleClear}
        options={({
          dateRange: true,
          maxDate: this.props.availabilityMaxDate,
          minDate: startOfDay(addDays(new Date(), offsetDays)).toISOString(),
          months: 2,
          name: 'campsiteSearch_dateRange',
        })}
        onChange={this.handleDateChange}
        startDate={(isValid(new Date(formatToHyphenFormat(this.props.start))) && formatToHyphenFormat(this.props.start)) || ''}
        {...testingAttr('search__date-picker')}
      >
        {({ handleOpen }) => (
          <FormGroup>
            <FormGroupItem>
              <Label dictionary={dictionaryItem('CampsiteSearchForm', 'Arrive')} />
              <DateInputWrapper>
                <SearchDateInput
                  ariaLabel="Arrive date"
                  block
                  type="text"
                  name="startDate"
                  icon={svgCalendar}
                  inputRef={this.inputRef}
                  onChange={this.validateDateInputs}
                  onFocus={this.handleFocus}
                  onKeyDown={this.props.handleKeyDownDate}
                  onClick={handleOpen}
                  placeholder={DATE_FORMAT_INPUT}
                  readOnly={isTabletOrSmaller()}
                  required
                  size={size}
                  value={(pickStateOrPayloadDate(this.state.startDate, this.props.start) && pickStateOrPayloadDate(this.state.startDate, this.props.start)) || ''}
                  {...testingAttr('search__arrive-input')}
                />
              </DateInputWrapper>
            </FormGroupItem>
            <FormGroupItem>
              <Label dictionary={dictionaryItem('CampsiteSearchForm', 'Depart')} />
              <DateInputWrapper>
                <SearchDateInput
                  ariaLabel="Depart date"
                  block
                  type="text"
                  name="endDate"
                  icon={svgCalendar}
                  onChange={this.validateDateInputs}
                  onFocus={this.handleFocus}
                  onKeyDown={this.props.handleKeyDownDate}
                  onClick={handleOpen}
                  placeholder={DATE_FORMAT_INPUT}
                  readOnly={isTabletOrSmaller()}
                  required
                  size={size}
                  value={pickStateOrPayloadDate(this.state.endDate, this.props.end)}
                  {...testingAttr('search__depart-input')}
                />
              </DateInputWrapper>
            </FormGroupItem>
          </FormGroup>
        )}
      </Datepicker>
    );
  }
}

export default memo(CampsitesDatePickerWrapper);
