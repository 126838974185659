import styled, { css } from 'styled-components';

import Drawer from '../../ui/Drawer';
import { above, below } from '../../../styles/libs';
import zIndex from '../../../config/zIndex';
import detectIE from '../../../lib/detectIE';
/**
 * Locks the height of the element to the viewport height
 * which allows for overlaying Drawers and Site Cards
 */
const lockHeight = inView => (...styles) => {
  if (inView) return css`${styles}`;
  return css`
    position: absolute;
    height: 100vh;
    overflow: hidden;
  `;
};

export const AvailabilityDrawer = styled(Drawer)`
  width: 100%;
  position: absolute;
  z-index: ${zIndex.IMPORTANT_CONTENT};

  ${({ inView, theme }) => css`
    background-color: ${theme.COLOR_WHITE};

    ${above(theme.TABLET)`
      width: ${theme.TWO_PANEL_WEIGHTED_RIGHT_2};
      max-width: 600px;
    `};

    ${above(theme.DESKTOP)`
      ${lockHeight(inView)`
        position: sticky;
        ${detectIE() && css`
          position: relative;
        `}
        top: 0;
      `}

      width: ${theme.TWO_PANEL_WEIGHTED_RIGHT_1};
      height: 100vh;
      z-index: 1;
    `};
  `};
`;

export const ReviewsDrawer = styled(AvailabilityDrawer)``;

export const SearchFormDrawer = styled(Drawer)`
  position: relative;

  ${({ inView, theme, width }) => css`
    ${below(theme.DESKTOP)(lockHeight(inView)``)}

    ${above(theme.TABLET)`
      width: ${width || theme.TWO_PANEL_WEIGHTED_RIGHT_2};
      max-width: ${width || '600px'};
    `};

    ${above(theme.DESKTOP)`
      width: ${width || theme.TWO_PANEL_WEIGHTED_RIGHT_1};
    `};
  `}
`;

export const SearchHandle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchFormWrapper = styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  width: 100%;
`;
