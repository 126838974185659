import gql from 'graphql-tag';

export function getPinOptions({ campsiteId, isPoi, isTours }) {
  return {
    variables: {
      campsiteId,
      skip: !campsiteId || isPoi || !!isTours,
    },
  };
}

export default gql`
  query PinData($campsiteId: String!, $skip: Boolean) {
    pinInfo(campsiteId: $campsiteId)
      @skip(if: $skip)
      @rest(
        type: "CampsitePin",
        path: "Campsite/pin?id={args.campsiteId}"
      ) {
        id
        name
        isoPlaceCode
        type
        numberOfReviews
        rating
        gallery
        events {
          id
          features {
            id
            name
            tags
            iconUrl
          }
          openDates {
            fromDate
            toDate
          }
          eventType
          latestArrival
          maxOutfitLength
          cheapestMemberPrice
          cheapestStandardPrice
        }
      }
  }
`;
