import styled, { css } from 'styled-components';

import { below } from '../../../styles/libs';

import StyledButtonBordered from '../../ui/Button/ButtonBordered.style';

export const ToursButton = styled(StyledButtonBordered)`
  border-color: ${({ theme }) => theme.COLOR_WHITE};
  background-color: transparent;
  color:  ${({ theme }) => theme.COLOR_WHITE};

  ${({ active }) => active && css`
    background-color: ${({ theme }) => theme.COLOR_WHITE};
    color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};

    &:not([disabled]):focus {
      background-color: ${({ theme }) => theme.COLOR_WHITE};
      color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
    }
  `}

  ${({ theme }) => below(theme.TABLET)`
    &:not([disabled]) {
      &:hover,
      &:focus {
        background-color: ${theme.COLOR_WHITE};
        color: ${theme.COLOR_BRAND_PRIMARY_DARK};
      }
    }

    &:not(:focus) {
      &:hover svg,
      &:focus svg {
        color: ${theme.COLOR_BRAND_PRIMARY_DARK};
      }
    }
  `}
`;

export const ToursButtons = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  column-gap: 0.875rem;
  margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '2rem')};
`;
