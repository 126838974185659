import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import themeGlobal from '../../styles/config/theme';
import { addTiming, types } from '../../lib/timings';
import IbePropTypes from '../../IbePropTypes';
import Picture from '../Picture';

export const CarouselImage = styled.img`
  width: 100%;
  aspect-ratio: 16 / 9;
  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHTEST};
  opacity: 0;
  transition: opacity 0.33s;
  object-fit: cover;
  &.flickity-lazyloaded {
    opacity: 1;
  }
`;

const CarouselItem = ({
  image,
  hide = false,
  isFlickityReady = false,
  isFirst,
}) => {
  const style = {
    position: hide ? 'absolute' : 'relative',
  };

  const imageUrl = image.baseUrl || '/static/images/missing-image.svg';
  // Flickity is dynamically loaded, and before it loads we display the
  // first slide to avoid CLS and hide the rest Therefore we specifically use src here.
  // After Flickity is loaded we use its lazyLoading and let that
  // take over applying the src props.
  const props = {};

  if (isFirst) {
    props.src = imageUrl;
    style.opacity = 1;
  }

  if (!image) {
    style.opacity = 0.15;
    style.objectFit = 'none';
    style.backgroundColor = themeGlobal.COLOR_TEXT_MUTED;
  }

  const onLoad = () => {
    // TEMP Performance Hooks, TODO can be safely removed at any time
    addTiming(types.CAMPSITE_CARD, 'Carousel Image Loaded Complete', true);
  };

  return (
    <Picture key={`${imageUrl}-${isFlickityReady}`} image={image}>
      <CarouselImage
        style={style}
        data-flickity-lazyload-src={imageUrl}
        {...props}
        onLoad={onLoad}
      />
    </Picture>
  );
};

CarouselItem.propTypes = {
  image: PropTypes.shape(IbePropTypes.optimizedImage),
  hide: PropTypes.bool,
  isFlickityReady: PropTypes.bool,
  isFirst: PropTypes.bool,
};

CarouselItem.defaultProps = {
  image: undefined,
  hide: true,
  isFlickityReady: false,
  isFirst: false,
};

export default CarouselItem;
