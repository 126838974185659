import styled, { css } from 'styled-components';

import { above, below } from '../../styles/libs';
import detectIE from '../../lib/detectIE';
import zIndex from '../../config/zIndex';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: ${zIndex.TAB_CONTENT_CAMPSITE_SEARCH_WRAPPER};
  height: 100%;
  position: relative;

  ${detectIE() && css`
    height: auto;
    overflow-y: auto;
    flex: 1;
  `};
`;

export const Header = styled.div`
  flex-shrink: 0;
  top: 0;
  padding: 2.25rem 1.25rem;

  ${({ theme }) => above(theme.DESKTOP)`
    padding: 2.75rem;
  `}

  ${({ theme, sticky }) => sticky && above(theme.TABLET)`
    position: sticky;
  `}
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CampsiteSearchFooter = styled.div`
    z-index: ${zIndex.MOBILE_NAV};
    width: ${({ mapOpen }) => {
    if (mapOpen) {
      return '100%';
    }
    return 'unset';
  }};

    left: 'unset';
    ${({ theme }) => below(theme.MOBILE_LARGE)`
        left: 8.6rem;
        width: unset;
      `};
    bottom: ${({ mapOpen }) => {
    if (mapOpen) {
      return '1rem';
    }
    return '0';
  }};
  /* 4px is mobile spacing adjustment */
    margin-bottom: ${({ resizeAdjustmentAmount }) => `${resizeAdjustmentAmount + 4}px`};
    pointer-events: none;
    position: ${({ mapOpen }) => (mapOpen ? 'absolute' : 'sticky')};
  `;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ showResults, mapOpen }) => (showResults || mapOpen ? '0.5rem' : '0.5rem 0.5rem 1rem 0.5rem')};
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150%;
  }
`;
