import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import PopUp from '../PopUp/PopUp';
import SpecialOfferPopUp from '../PopUp/SpecialOfferPopUp';

import {
  Image, SpecialOffersWrapper, Button, Row, Col,
} from './SpecialOffers.style';

class SpecialOffers extends PureComponent {
  static propTypes = {
    offers: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    offers: [],
  };

  state = {
    image: '',
    text: '',
    termsLink: '',
  }

  handleClosePopUp = () => {
    this.setState({
      text: '',
      image: '',
      termsLink: '',
    });
  }

  handleOffer = (text, image, termsLink) => {
    this.setState({
      text,
      image,
      termsLink,
    });
  }

  render() {
    const hasPopupContent = !!(this.state.text && this.state.image);

    return (
      <SpecialOffersWrapper>
        <PopUp
          overlayVisible={hasPopupContent}
          visible={hasPopupContent}
          open={hasPopupContent}
          handleCloseBtnClick={this.handleClosePopUp}
          width="39rem"
          minHeight="11rem"
        >
          <SpecialOfferPopUp
            image={this.state.image}
            text={this.state.text}
            termsLink={this.state.termsLink}
          />
        </PopUp>
        <Fragment>
          <Row>
            {this.props.offers.map((offer, index) => (
              <Col key={uuid()}>
                <Button
                  ariaLabel={`Special Offer: ${offer.title}`}
                  onClick={() => this.handleOffer(offer.modalContent, offer.image, offer.termsLink)}
                  noPadding
                  type="button"
                  data-testid={`special-offers-${index}_button`}
                >
                  <Image
                    src={offer.image ? offer.image.replace(/\s/g, '%20') : 'null'}
                    // TODO: Don't add the offer to the array if it's not got matching data
                  />
                </Button>
              </Col>
            ))
            }
          </Row>
        </Fragment>
      </SpecialOffersWrapper>
    );
  }
}

export default SpecialOffers;
