import React from 'react';
import PropTypes from 'prop-types';

import { ButtonInfo } from '../../ui/Button';
import Text from '../../ui/Text';
import IbePropTypes from '../../../IbePropTypes';
import { getDictionaryItem } from '../../../hocs/withDictionary';
import svgInfo from '../../../static/images/icons/Info.svg';
import svgTick from '../../../static/images/icons/Tick.svg';

import { FilterItem } from '../FiltersList.style';
import {
  ItineraryContainer,
  ItineraryHeader,
  ItinerarySelected,
} from './Itinerary.style';

const propTypes = {
  active: PropTypes.bool,
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  onSelect: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  itinerary: PropTypes.shape(IbePropTypes.ukItinerary).isRequired,
};

const defaultProps = {
  active: false,
};

const ItineraryItem = ({
  active,
  client,
  onSelect,
  onView,
  itinerary,
}) => {
  const {
    country,
    itineraryLength,
    itineraryLengthTitle,
    name,
    id,
  } = itinerary ?? {};

  const handleSelect = (e) => {
    if (e.target.tagName === 'svg' || e.target.tagName === 'path') {
      onView(itinerary);
      return null;
    }
    onSelect(id?.toString());
    return id;
  };

  const locationLabel = getDictionaryItem(client, 'SuggestedUkItinerary__Location__Text');
  const nightsSuffix = getDictionaryItem(client, 'SuggestedUkItinerary__NightsSuffix__Text').toLowerCase();

  return (
    <FilterItem onClick={handleSelect}>
      <ItineraryContainer active={active}>
        <ItineraryHeader>
          <Text weight="bold" size="0.85rem" span>{name}</Text>
          <ButtonInfo
            id="itineraryModalToggle"
            ariaLabel="Information"
            icon={svgInfo}
            marginLeft="0.5rem"
            noPadding
            onClick={handleSelect}
            type="button"
          />
        </ItineraryHeader>
        <Text size="0.75rem">{itineraryLengthTitle}: {itineraryLength} {nightsSuffix}</Text>
        <Text size="0.75rem">{locationLabel}: {country}</Text>
        {active && (
          <ItinerarySelected icon={svgTick} />
        )}
      </ItineraryContainer>
    </FilterItem>
  );
};

ItineraryItem.propTypes = propTypes;
ItineraryItem.defaultProps = defaultProps;

export default ItineraryItem;
