import styled from 'styled-components';
import { Icon } from '../ui';
import theme from '../../styles/config/theme';

export const TextWrapper = styled.div`
  margin: 2.5rem 0 0;
`;

export const ModalIconWrapper = styled.div`
  position: relative;
`;

export const ModalIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(3);
`;

export const StyledLinkButton = styled.a`
  border: none;
  outline: none;
  text-decoration: underline;
  color: ${theme.COLOR_AMBER_RED};
  cursor: pointer;
  font-weight: bold;
`;

export const TextContent = styled.div``;
