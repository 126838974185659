import React from 'react';
import PropTypes from 'prop-types';

import { dictionaryItem } from '../../../hocs/withDictionary';
import IbePropTypes from '../../../IbePropTypes';

import ItineraryItem from './ItineraryItem';
import Text from '../../ui/Text';
import {
  FilterGroup,
  GroupTitle,
} from '../FiltersList.style';

const propTypes = {
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  itineraries: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.ukItinerary)),
  handleSelectUkItinerary: PropTypes.func.isRequired,
  handleViewItinerary: PropTypes.func.isRequired,
  activeId: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })),
};

const defaultProps = {
  itineraries: [],
  categories: [],
  activeId: '',
};

const ItineraryList = ({
  itineraries,
  handleSelectUkItinerary,
  handleViewItinerary,
  activeId,
  categories,
  client,
}) => (
  <>
    <GroupTitle dictionary={dictionaryItem('Filter', 'SuggestedUkItineraries')} withDescription />
    <Text marginBottom="1rem" dictionary={dictionaryItem('Filter', 'SuggestedUkItineraries__Description')} />
    {categories.map((category) => (
      <div key={category.name}>
        <Text weight="bold" marginBottom="0.5rem" size="1.2rem">{category.name}</Text>
        <FilterGroup data-testid={`suggestedUkItineraryCategory-${category.id}`}>
          {itineraries.filter(
            (itinerary) => (itinerary.itineraryCategory === category.id),
          ).map((itinerary) => (
            <ItineraryItem
              key={itinerary.id}
              client={client}
              itinerary={itinerary}
              active={activeId === itinerary.id?.toString()}
              onSelect={handleSelectUkItinerary}
              onView={handleViewItinerary}
              data-testid={`suggestedUkItinerary-${itinerary.id}`}
            />
          ))}
        </FilterGroup>
      </div>
    ))}
  </>
);

ItineraryList.propTypes = propTypes;
ItineraryList.defaultProps = defaultProps;

export default ItineraryList;
