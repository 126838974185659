import React from 'react';
import PropTypes from 'prop-types';
import ButtonBrand from '../ui/Button/ButtonBrand.style';
import { dictionaryItem } from '../../hocs/withDictionary';
import { POST_TYPES, navigateTo } from '../../lib/helpers/navigation';

const PricesAndDirections = ({ url }) => (
  <>
    {!!url && url.length > 0 && (
      <ButtonBrand
        size="large"
        block
        onClick={() => navigateTo(url, {
          type: POST_TYPES.NEW_WINDOW,
        })}
        dictionary={dictionaryItem('ListingResult', 'PricesAndDirections')}
      />
    )}
  </>
);

PricesAndDirections.propTypes = {
  url: PropTypes.string.isRequired,
};

export default PricesAndDirections;
