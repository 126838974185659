import styled, { css } from 'styled-components';

import StyledButtonIcon from '../../ui/Button/ButtonIcon.style';

export const ItineraryContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1rem;
  padding-right: 2rem;

  ${({ theme, active }) => css`
    padding: ${active ? 'calc(1rem - 2px) 1rem' : '1rem'};
    border: ${active ? '2px' : '0px'} solid ${theme.COLOR_BRAND_PRIMARY};
    background-color: ${theme.COLOR_WHITE};
    border-radius: 5px;
  `}

  ${({ theme }) => css`
    &:hover:not(:disabled) {
      padding: calc(1rem - 2px) 1rem;
      border: 2px solid ${theme.COLOR_BRAND_PRIMARY};
      background-color: transparent;
      cursor: pointer;
    }
  `}
`;

export const ItineraryHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const ItinerarySelected = styled(StyledButtonIcon)`
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY};
`;
