import styled from 'styled-components';

import StyledText from '../ui/Text/Text.style';

export default styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;
`;

export const ErrorText = styled(StyledText)`
  color: ${({ theme }) => theme.COLOR_TEXT_MUTED};
  padding: 1rem;
`;
