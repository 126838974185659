import styled, { css } from 'styled-components';
import Text from '../ui/Text';

import above from '../../styles/libs/above';

export default styled.div``;

export const FilterItem = styled.div`
  flex: 0 0 100%;
  padding: 0 0.75rem;
  margin-bottom: 1rem;

  ${({ theme }) => css`
    ${above(theme.TABLET)`
      flex: 0 0 50%;
    `}

    ${above(theme.DESKTOP)`
      flex: 0 0 33.333%;
      margin-bottom: 1.5rem;
    `}

    ${above(theme.DESKTOP_LARGE)`
      flex: 0 0 25%;
    `}
  `}
`;

export const GroupTitle = styled(Text)`
  font-size: 1.375rem;
  font-weight: 600;
  margin-bottom: 1rem;

  ${({ theme, branded }) => branded && css`
    text-align: center;
    margin-bottom: 4rem;
    font-size: 1.5rem;
    color: ${theme.COLOR_BRAND_PRIMARY};
  `}

  ${({ withDescription }) => withDescription && css`
    margin-bottom: 0.5rem;
  `}
`;

export const FilterGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.75rem 1.5rem;
`;
