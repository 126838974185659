import styled, { css } from 'styled-components';

import Button from '../ui/Button';
import Badge from '../ui/Badge';
import StyledButtonIcon from '../ui/Button/ButtonIcon.style';
import Text from '../ui/Text';

export default styled(Button)`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-weight: 600;
  text-align: left;
  text-transform: none;
  span {
    justify-content: flex-start;
  }
  ${({ active, theme }) => css`
    background-color: ${active ? 'transparent' : theme.COLOR_WHITE};
    border-color: ${active ? theme.COLOR_BRAND_PRIMARY : theme.COLOR_WHITE};
  `}

  &:hover:not(:disabled) {
    background-color: transparent;
    border-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY};
  }
  &:disabled {
    cursor: not-allowed;
    ${({ active, theme }) => !active && css`
      background-color: ${theme.COLOR_GRAY_LIGHT};
      border-color: transparent;
      color: ${theme.COLOR_TEXT_MUTED};
    `}
  }
`;

export const FilterIcon = styled(StyledButtonIcon)`
  background-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY};
  border-radius: 50%;
  color: ${({ theme }) => theme.COLOR_WHITE};
`;

export const FilterActive = styled(StyledButtonIcon)`
  color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY};
  ${({ marginLeft }) => (marginLeft && css`
    margin-left: ${marginLeft};
  `)}
`;

export const FilterCount = styled(Badge)`
  color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY};
  margin-left: auto;
  font-size: 0.75rem;
`;

export const FilterText = styled(Text)`
  font-size: 0.8125rem;
`;
