import styled, { css } from 'styled-components';

import Note from '../Note';

import Drawer, { DrawerContent as DrawerContentComponent } from '../ui/Drawer';
import { ButtonClose as ButtonCloseStyled } from '../PopUp/PopUp.styles';

import StyledButtonBrand from '../ui/Button/ButtonBrand.style';

import { above, below } from '../../styles/libs';
import detectiOS from '../../lib/detectiOS';

export default styled(Drawer)`
  ${({ full, theme }) => above(theme.TABLET)`
    width: ${full ? theme.ONE_PANEL : theme.TWO_PANEL};
  `};

  ${({ full, theme }) => above(theme.DESKTOP)`
    width: ${full ? theme.TWO_PANEL_WEIGHTED_RIGHT_2 : theme.TWO_PANEL_WEIGHTED_RIGHT_1};
  `};

  ${({ full, theme }) => above(theme.DESKTOP_LARGE)`
    width: ${full ? theme.TWO_PANEL : theme.TWO_PANEL_WEIGHTED_LEFT_2};
  `};

  position: fixed;
  top: 0;
  right: 0px;
  height: 100vh;
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #FFFF;

  ${({ theme }) => below(theme.TABLET)`
    height: 100%;
  `}
`;

export const DrawerWrapper = styled.div`
  overflow-x: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: ${({ zIndex }) => zIndex};
  pointer-events: none;
  ${({ theme }) => below(theme.TABLET) && !!detectiOS() && css`
    overflow-y: none;
  `}
`;

export const DrawerContent = styled(DrawerContentComponent)`
  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHTER};
  position: sticky;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CampsiteHeader = styled.header`
  position: relative;
  background-color: ${({ theme }) => theme.COLOR_WHITE};
`;

export const CampsiteTitle = styled.h3`
  font-weight: bold;
  margin: 1rem 0 0;
  padding: 0 1rem;
`;

export const CampsiteContent = styled.main`
  padding: 1rem;
  background-color: ${({ theme }) => theme.COLOR_WHITE};
`;

export const FromToDate = styled.main`
padding: 1rem 1rem 0 1rem;
background-color: ${({ theme }) => theme.COLOR_WHITE};
`;

export const Footer = styled.footer`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  position: relative;
  padding: 0 1rem 1rem;
`;

export const OfferNote = styled(Note)`
  padding: 0;
  min-height: auto;
  justify-content: start;
`;

export const OfferTitle = styled(OfferNote)`
  margin-top: 0.5rem;
  font-weight: bold;
  justify-content: start;
`;

export const MoreDetails = styled(StyledButtonBrand)`
  ${({ siteDetailsVisible }) => siteDetailsVisible && css`
    display: none;
  `}
  cursor: pointer;
  ${({ hasOffers }) => hasOffers && css`
    margin-top: 1rem;
  `}

  ${({ campsiteColor }) => campsiteColor && css`
    background-color: ${campsiteColor};
  `}

  width: 100%;
`;

export const CampsiteImg = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  width: 100%;

  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ${({ theme }) => theme.RATIO_5_3};
  }
`;

export const Image = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHT};
`;

export const Img = styled.img`
  height: 100%;
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
`;

export const Close = styled(ButtonCloseStyled)`
  z-index: 1;
  ${({ theme }) => above(theme.TABLET)`
    display: none;
  `}
`;
