import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

import CampsiteFeature from '../CampsiteFeature';
import FlexTable, { FlexCell, FlexRow } from '../ui/FlexTable';
import Icon from '../ui/Icon';
import { Toggle } from '../ui/List/List.style';
import FEATURE_TYPES from '../../config/featureTypes';
import {
  getCampsiteFeatureByTag,
} from '../../lib/helpers/campsites';
import uniqueObjectsByKey from '../../lib/uniqueObjectsByKey';
import IbePropTypes from '../../IbePropTypes';

import arrowDownSVG from '../../static/images/icons/ArrowDown.svg';
import arrowUpSVG from '../../static/images/icons/ArrowUp.svg';

// On site facilities CAMCP2IBE-494
function CampsiteDetailsTopFeatures({ features }) {
  const [expand, setExpand] = useState(false);

  const handleExpand = () => setExpand(!expand);
  const topTen = getCampsiteFeatureByTag(features, FEATURE_TYPES.TOP_TEN);
  const onSiteFacilities = getCampsiteFeatureByTag(features, FEATURE_TYPES.FACILITIES_ON_SITE);
  const onSiteFilterableFacilities =
    getCampsiteFeatureByTag(features, FEATURE_TYPES.ON_SITE_FILTERABLE);

  // Combine filtered features with map features
  // ensuring that those belonging in the top 10 are at the start of the array
  const allFeatures = uniqueObjectsByKey(
    [...topTen, ...onSiteFacilities, ...onSiteFilterableFacilities],
    'id',
  );

  // Limit features shown depending on whether the 'View all facilities' button is clicked.
  const limit = 10;
  const listingLimit = expand ? -1 : limit;
  return (
    <FlexTable>
      {allFeatures.slice(0, listingLimit).map(item => (
        <FlexRow key={item.id}>
          <FlexCell>
            <CampsiteFeature
              hasDescription
              hasIcon
              {...item}
            />
          </FlexCell>
        </FlexRow>
      ))}

      {allFeatures.length > limit && (
        <Toggle block onClick={handleExpand} size="medium" data-testid="feature-toggle">
          <span>View {expand ? 'fewer' : 'all'} facilities</span>
          <Icon
            icon={expand ? arrowUpSVG : arrowDownSVG}
            size="0.625rem"
            style={{ marginLeft: '0.5rem' }}
          />
        </Toggle>
      )}
    </FlexTable>
  );
}

CampsiteDetailsTopFeatures.propTypes = {
  features: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.feature)),
};

CampsiteDetailsTopFeatures.defaultProps = {
  features: [],
};

export default memo(CampsiteDetailsTopFeatures);
