/* eslint-disable indent */
import styled, { css } from 'styled-components';

import TitleComponent from '../ui/Title';
import InputWithIcon from '../ui/Form/InputWithIcon/InputWithIcon';
import LabelComponent from '../ui/Form/Label';
import StyledLoadingSpinner from '../ui/Loading/LoadingSpinner';

import { above } from '../../styles/libs';

export const Form = styled.form``;

export const Title = styled(TitleComponent)`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.COLOR_WHITE};

  ${({ theme }) => css`
    ${above(theme.MOBILE_MEDIUM)`
      font-size: 1.5rem;
    `}

    ${above(theme.MOBILE_LARGE)`
      font-size: 1.75rem;
    `}
  `}
`;

export const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.375rem;
`;

export const FormGroupItem = styled.div`
  flex: 1 0;

  ${({ theme }) => above(theme.DESKTOP)`
    flex: ${({ flex, width }) => {
      if (!flex || width) return 1;
      if (flex) return flex;
      return `0 0 ${width}`;
    }};
  `}

  margin-bottom: 1.5rem;
  padding: 0 0.375rem;
`;

export const Label = styled(LabelComponent)`
  color: ${({ theme }) => theme.COLOR_WHITE};
  padding-top: 0;
`;

export const SearchInput = styled(InputWithIcon)`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border: 0;
  border-radius: 0.25rem;
`;

export const LoadingSpinner = styled(StyledLoadingSpinner)`
  margin-top: 2rem;
`;
