import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { LoadingBlock } from '../ui/Loading';

import { Pager, PageLink, PageLinkArrow } from './ReviewPagination.style';

const propTypes = {
  numberOfPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  parentLoading: PropTypes.bool,
};

const defaultProps = {
  parentLoading: false,
};

const first = (e, func) => {
  e.preventDefault();
  func(0);
};

const last = (e, func, maxPage) => {
  e.preventDefault();
  func(maxPage);
};

const prev = (e, func, currentPage) => {
  e.preventDefault();
  func(currentPage === 0 ? 0 : currentPage - 1);
};

const next = (e, func, maxPage, currentPage) => {
  e.preventDefault();
  func(currentPage === maxPage ? maxPage : currentPage + 1);
};

const changePage = (e, func, page) => {
  e.preventDefault();
  func(page);
};

function ReviewPagination(props) {
  if (props.parentLoading) {
    return (
      <Pager>
        <LoadingBlock width="24rem" />
      </Pager>
    );
  }

  if (!props.numberOfPages) return null;
  const maxPage = props.numberOfPages - 1;

  return (
    <Pager>
      <PageLinkArrow
        onClick={e => prev(e, props.onPageChange, props.currentPage)}
        disabled={props.currentPage === 0}
        left
      >
        Previous
      </PageLinkArrow>
      <PageLink
        onClick={e => first(e, props.onPageChange)}
        disabled={props.currentPage === 0}
      >
        First
      </PageLink>
      <PageLink
        onClick={e => prev(e, props.onPageChange, props.currentPage)}
        disabled={props.currentPage === 0}
      >
        Previous
      </PageLink>

      {[...Array(props.numberOfPages).keys()].map(page => (
        <PageLink
          onClick={e => changePage(e, props.onPageChange, page)}
          key={uuid()}
          active={page === props.currentPage}
        >
          {page + 1}
        </PageLink>
      ))}

      <PageLink
        onClick={e => next(e, props.onPageChange, maxPage, props.currentPage)}
        disabled={props.currentPage === maxPage}
      >
        Next
      </PageLink>
      <PageLink
        onClick={e => last(e, props.onPageChange, maxPage)}
        disabled={props.currentPage === maxPage}
      >
        Last
      </PageLink>
      <PageLinkArrow
        onClick={e => next(e, props.onPageChange, maxPage, props.currentPage)}
        disabled={props.currentPage === maxPage}
        right
      >
        Next
      </PageLinkArrow>
    </Pager>
  );
}

ReviewPagination.propTypes = propTypes;
ReviewPagination.defaultProps = defaultProps;

export default ReviewPagination;
