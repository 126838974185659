import React, { useContext, useEffect, useState } from 'react';
import { DisplayToRootContext } from '../../context/DisplayToRootProvider';

const DisplayToRootContainer = () => {
  const context = useContext(DisplayToRootContext);
  const [children, setChildren] = useState(null);

  useEffect(() => {
    setChildren(context.children);
  }, [context]);

  return <>{children}</>;
};

export default DisplayToRootContainer;
