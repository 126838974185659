import styled, { css } from 'styled-components';

import ButtonBrand, { buttonBrandCSS } from '../ui/Button/ButtonBrand.style';
import { buttonSmall } from '../ui/Button/Button.style';

import { below } from '../../styles/libs';

export const CTACSS = css`
  max-width: 12rem;
  float: right;

  ${({ multiText, theme }) => multiText && css`
    display: flex;
    max-width: 100%;
    justify-content: center;
    font-weight: bold;

    span {
      padding: 0 0.25rem;
    }

    span:nth-of-type(2) {
      font-size: 1.2rem;

      ${below(theme.MOBILE_LARGE)`
        font-size: 0.8rem;
      `}
    }

    ${below(theme.MOBILE_LARGE)`
      flex-direction: column;
    `}
  `}

  ${({ theme }) => css`
    ${below(theme.MOBILE_LARGE)`
      ${buttonSmall}
    `}
  `}
`;

export const CTA = styled(ButtonBrand)`
  ${CTACSS}
`;

export const CTALink = styled.a`
  ${buttonBrandCSS}
  ${CTACSS}
`;
