import React from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';

import { dictionaryItem, getDictionaryItem } from '../../hocs/withDictionary';
import theme from '../../styles/config/theme';

import { Modal, Title } from '../ui';
import { ModalClose } from '../ui/Modal';
import {
  LimitedModalContent,
} from '../ManageMyBookings/BookingAction.style';

import errorIcon from '../../static/images/icons/Error.svg';
import IbePropTypes from '../../IbePropTypes';
import {
  ModalIconWrapper,
  TextWrapper,
  ModalIcon,
  StyledLinkButton,
  TextContent,
} from './PermissionsModal.style';
import { navigateTo } from '../../lib/helpers/navigation';

const PermissionsModal = ({
  active,
  closeModal,
  client,
  geoLocationInfoLink,
}) => {
  const textContent = getDictionaryItem(client, 'CampsiteSearch__MapLocation__Modal__RichText');
  const buttonText = getDictionaryItem(client, 'CampsiteSearch__MapLocation__Modal__Link__Text');

  const handleClick = () => {
    navigateTo(geoLocationInfoLink);
  };

  return (
    <Modal active={active} onClickOutside={closeModal} overlay>
      <LimitedModalContent size="small">
        <ModalClose onClick={closeModal} />
        <ModalIconWrapper>
          <ModalIcon icon={errorIcon} color={theme.COLOR_CYAN_DARK} />
        </ModalIconWrapper>
        <TextWrapper>
          <Title
            size={2}
            dictionary={dictionaryItem('CampsiteSearch', 'MapLocation', 'Modal')}
            marginBottom="1rem"
          />
          <TextContent>
            {textContent}
            <StyledLinkButton onClick={handleClick}>{buttonText}</StyledLinkButton>
          </TextContent>
        </TextWrapper>
      </LimitedModalContent>
    </Modal>
  );
};

PermissionsModal.defaultProps = {
  active: false,
  closeModal: () => {},
  client: {},
  geoLocationInfoLink: '/sites',
};

PermissionsModal.propTypes = {
  active: PropTypes.bool,
  closeModal: PropTypes.func,
  client: PropTypes.shape(IbePropTypes.client),
  geoLocationInfoLink: PropTypes.string,
};

export default withApollo(PermissionsModal);
