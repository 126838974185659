import React, { memo } from 'react';
import { compose, withApollo } from 'react-apollo';
import PropTypes from 'prop-types';

import {
  LocationSearchInputWrapper, SearchInput, UseMyLocation, MyLocationContentWrapper,
} from './CampsitesDatePickerWrapper.style';
import { Icon, Text } from '../../ui';

import LocationTargetIcon from '../../../static/images/icons/LocationTarget.svg';
import IbePropTypes from '../../../IbePropTypes';
import { dictionaryItem, getDictionaryItem } from '../../../hocs/withDictionary';
import { MY_LOCATION_SEARCH_KEY } from '../../../constants/search';

const LocationSearchInput = ({
  onClickUseMyLocation,
  grantedPermission,
  location,
  client,
  showUseMyLocation,
  value,
  ...props
}) => {
  // Use client dictionary item for location if its a 'my-location' search
  const inputValue = (!!value && value === MY_LOCATION_SEARCH_KEY)
    ? getDictionaryItem(client, 'CampsiteSearch__MyLocation__Text') : value;
  return (
    <LocationSearchInputWrapper>
      <SearchInput {...props} value={inputValue} hasSideButton hideFocus />
      {showUseMyLocation &&
        <UseMyLocation onClick={onClickUseMyLocation}>
          <MyLocationContentWrapper
            grantedPermission={grantedPermission}
            myLocationActive={location === MY_LOCATION_SEARCH_KEY}
          >
            <Text dictionary={dictionaryItem('CampsiteSearch', 'UseMyLocation')} />
            <Icon icon={LocationTargetIcon} size="1.5rem" />
          </MyLocationContentWrapper>
        </UseMyLocation>
      }
    </LocationSearchInputWrapper>
  );
};

LocationSearchInput.defaultProps = {
  onClickUseMyLocation: () => {},
  grantedPermission: false,
  client: {},
  location: '',
  showUseMyLocation: true,
  value: '',
};

LocationSearchInput.propTypes = {
  onClickUseMyLocation: PropTypes.func,
  grantedPermission: PropTypes.bool,
  client: PropTypes.shape(IbePropTypes.client),
  location: PropTypes.string,
  showUseMyLocation: PropTypes.bool,
  value: PropTypes.string,
};

export default compose(withApollo, memo)(LocationSearchInput);
