import React from 'react';
import PropTypes from 'prop-types';

import { ViewMore, ViewMoreButton, ViewMoreText } from './SearchResults.style';
import { dictionaryItem, getDictionaryItem } from '../../hocs/withDictionary';
import IbePropTypes from '../../IbePropTypes';

const ViewMoreResults = ({
  total,
  count,
  viewMoreLoading,
  nextPage,
  isOverseas,
  client,
}) => {
  if (total - count <= 0) return <></>;
  return (
    <ViewMore>
      <ViewMoreButton
        onClick={() => nextPage()}
        disabled={viewMoreLoading}
      >
        {
          count + 20 > total ?
            getDictionaryItem(client, 'SearchResults__LoadMoreCount__Button__Text').replace('{{count}}', total - count) :
            getDictionaryItem(client, 'SearchResults__LoadMore__Button__Text')
        }
      </ViewMoreButton>
      {!isOverseas && <ViewMoreText dictionary={dictionaryItem('SearchResults', 'ChangeSortOrderTip')} />}
    </ViewMore>
  );
};

ViewMoreResults.propTypes = {
  total: PropTypes.number,
  count: PropTypes.number,
  viewMoreLoading: PropTypes.bool,
  nextPage: PropTypes.func.isRequired,
  isOverseas: PropTypes.bool,
  client: PropTypes.shape(IbePropTypes.client).isRequired,
};

ViewMoreResults.defaultProps = {
  total: 0,
  count: 0,
  viewMoreLoading: false,
  isOverseas: false,
};

export default ViewMoreResults;
