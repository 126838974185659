import styled, { css } from 'styled-components';
import { Body as StyledBody } from '../SiteCard/SiteCard.style';
import TitleComponent from '../ui/Title';

export const Title = styled(TitleComponent)`
  font-size: 1.375rem;
  font-weight: 600;
  padding: 1.5rem 0;
`;

export const Container = styled.div`
  display: block;
  ${({ branded, theme }) => branded && css`
    padding: 0.5rem 2rem;
    background-color: ${theme.COLOR_BRAND_PRIMARY};
  `}
`;

export const Body = styled(StyledBody)`
  border-bottom: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  ${({ theme, withBackground }) => withBackground && css`
    background-color: ${theme.COLOR_GRAY_LIGHTER};
  `}
`;

export const Image = styled.div`
  display: block;
  width: 100%;
  min-height: 250px;
  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center;
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Tabs = styled.div`
  display: flex;
`;

export const Tab = styled.div`
  flex: 1;
  padding: 1rem;
  text-align: center;
  font-weight: bold;
  cursor: pointer;

  ${({ active, theme }) => active && css`
    background-color: ${theme.COLOR_GRAY_LIGHTER};
  `}
`;

export const TabContent = styled.div`
  padding: 1rem 0;
  ${({ theme }) => css`
    background-color: ${theme.COLOR_GRAY_LIGHTER};
  `}
`;

export const TabContentContainer = styled.div`
  padding: 0.5rem 1.5rem;
`;
