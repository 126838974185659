import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql, withApollo } from 'react-apollo';
import { withTheme } from 'styled-components';

import { dictionaryItem } from '../../hocs/withDictionary';

import SiteNightVoucherModal from './SiteNightVoucherModal';
import { Text, Title } from '../ui';

import SiteNightVoucherStyled, { Button, CTA, Details } from './SiteNightVoucher.style';

import GET_CONFIGURATION from '../../config/graphql/getConfiguration';
import GET_POPUP from '../PopUp/graphql/getPopUp';

import testingAttr from '../../lib/testingAttr';
import { dataLayerManager } from '../../lib/dataLayer';

class SiteNightVoucher extends PureComponent {
  static propTypes = {
    client: PropTypes.shape({
      writeData: PropTypes.func.isRequired,
    }).isRequired,
    data: PropTypes.shape({
      siteNightVoucherPopUp: PropTypes.shape({
        open: PropTypes.bool,
      }),
    }),
    error: PropTypes.shape({}),
    leftContent: PropTypes.string,
    leftAltContent: PropTypes.string,
    loading: PropTypes.bool,
    plainStyling: PropTypes.bool,
    searchListingImage: PropTypes.string,
    titleAltText: PropTypes.string,
    titleText: PropTypes.string,
    unitCost: PropTypes.number,
    theme: PropTypes.shape({
      COLOR_WHITE: PropTypes.string,
    }),
    toggleBasket: PropTypes.func.isRequired,
    onProductTypeMismatch: PropTypes.func,
  }

  static defaultProps = {
    data: {
      siteNightVoucherPopUp: { open: false },
    },
    error: null,
    leftContent: '',
    leftAltContent: '',
    loading: false,
    plainStyling: false,
    searchListingImage: '',
    titleAltText: '',
    titleText: '',
    unitCost: 0,
    theme: {},
    onProductTypeMismatch: null,
  }

  handleClick = () => {
    const { client, onProductTypeMismatch } = this.props;

    const clickAction = () => {
      dataLayerManager.pushProductClick(dataLayerManager.category.SITES, {
        name: this.props.titleText,
        id: dataLayerManager.bespokeProductIds.OVERSEAS_SITE_NIGHT_VOUCHERS,
        memberPrice: this.props.unitCost,
        category: dataLayerManager.category.SITES,
        subCategory: dataLayerManager.subCategory.EXTRA,
      }, 0);

      client.writeData({
        data: { siteNightVoucherPopUp: { open: true, __typename: 'SiteNightVoucherPopUp', minimum: 0 } },
      });
    };

    const showProductMismatchDialog = onProductTypeMismatch?.(true, clickAction);

    if (!showProductMismatchDialog) {
      clickAction();
    }
  }

  render() {
    const {
      data: {
        siteNightVoucherPopUp,
      },
      error,
      loading,
      theme,
      titleAltText,
      titleText,
      leftAltContent,
      leftContent,
      plainStyling,
      toggleBasket,
    } = this.props;

    if (error || loading || (!leftContent && !leftAltContent)) return null;

    return (
      <SiteNightVoucherStyled
        backgroundImage={this.props.searchListingImage}
        plainStyling={plainStyling}
      >
        <SiteNightVoucherModal
          active={siteNightVoucherPopUp?.open}
          toggleBasket={toggleBasket}
        />
        <Details plainStyling={plainStyling}>
          <Title
            color={theme.COLOR_WHITE}
            marginBottom
            tag={1}
            size={2}
          >
            {plainStyling ? titleAltText : titleText}
          </Title>
          <Text color={theme.COLOR_WHITE} size="1rem">
            {plainStyling ? leftAltContent : leftContent}
          </Text>
        </Details>
        <CTA>
          <Button
            {...testingAttr('search-results-campsite__siteNightVoucher-button')}
            dictionary={plainStyling ? dictionaryItem('SiteNightVoucher', 'More') : dictionaryItem('SiteNightVoucher', 'Buy')}
            onClick={this.handleClick}
            size="small"
          />
        </CTA>
      </SiteNightVoucherStyled>
    );
  }
}

export default compose(
  withApollo,
  withTheme,
  graphql(GET_CONFIGURATION, {
    props: ({ data }) => ({
      error: data.error,
      loading: data.loading,
      ...data && data.configuration && data.configuration.siteNightVoucherInfo,
    }),
  }),
  graphql(GET_POPUP),
)(SiteNightVoucher);
