import styled from 'styled-components';

import { Icon, Text } from '../ui';

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${({ theme }) => theme.MOBILE_MEDIUM}px;
  width: 100%;
  padding: 0 2rem;
`;

export const OfflineIcon = styled(Icon)`
  font-size: 5rem;
  color: ${({ theme }) => theme.COLOR_GRAY};
  margin-bottom: 1rem;
`;

export const Heading = styled(Text)`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

export const OfflineText = styled(Text)`
  color: ${({ theme }) => theme.COLOR_TEXT_MUTED};
  text-align: center;
  margin-bottom: 0.5rem;
`;
