import styled from 'styled-components';

import Text from '../ui/Text';
import IconComponent from '../ui/Icon';

import { above } from '../../styles/libs';

export default styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.COLOR_WHITE};
  padding: 0 1rem;

  ${({ theme }) => above(theme.TABLET)`
    padding: 1rem 2rem;
    max-width: ${theme.MOBILE_LARGE}px;
  `}
`;

export const Icon = styled(IconComponent)`
  font-size: 3.5rem;
  margin-right: 1rem;

  ${({ theme }) => above(theme.TABLET)`
    font-size: 4rem;
    margin-right: 1.5rem;
  `}
`;

export const Title = styled(Text)`
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 0.25em;

  ${({ theme }) => above(theme.TABLET)`
    font-size: 1rem;
  `}
`;

export const Message = styled(Text)`
  font-size: 0.75rem;

  ${({ theme }) => above(theme.TABLET)`
    font-size: 0.875rem;
  `}
`;
