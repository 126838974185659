import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../ui/Icon';

import { Back, NavigationRow } from './SiteCard.style';

import svgArrowLeft from '../../static/images/icons/ArrowLeft.svg';
import svgArrowRight from '../../static/images/icons/ArrowRight.svg';

const propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
};

function SiteCardBack({ handleClick, handleNext }) {
  return (
    <NavigationRow>
      <Back
        align="left"
        type="button"
        onClick={handleClick}
      >
        <Icon
          icon={svgArrowLeft}
          style={{ marginRight: '1rem' }}
        />
        <span>Back</span>
      </Back>
      <Back
        align="left"
        direction="row-reverse"
        type="button"
        onClick={handleNext}
      >
        <Icon
          icon={svgArrowRight}
          style={{ marginLeft: '1rem' }}
        />
        <span>Skip</span>
      </Back>
    </NavigationRow>
  );
}

SiteCardBack.propTypes = propTypes;

export default SiteCardBack;
