import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './TourItinerary.style';

import TourItineraryItem from './TourItineraryItem';
import IbePropTypes from '../../IbePropTypes';

const TourItinerary = ({ tour }) => {
  if (!tour || !tour?.itinerary.length) {
    return null;
  }
  return (
    <Container>
      {tour?.itinerary.map((itineraryItem) => (
        <TourItineraryItem itinerary={itineraryItem} />
      ))}
    </Container>
  );
};

TourItinerary.propTypes = {
  tour: PropTypes.shape(IbePropTypes.tour).isRequired,
};

export default TourItinerary;
