import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Typeahead, { PlacesTypeahead } from '../../Typeahead';
import LocationSearchInput from './LocationSearchInput';

import testingAttr from '../../../lib/testingAttr';

import {
  SearchInput,
} from './CampsitesDatePickerWrapper.style';

import { searchByType } from '../../../constants/search';
import { addTiming, types } from '../../../lib/timings';
import { USER_LOCATION_PERMISSIONS } from '../../../lib/location';

const CampsitesSearchInputWrapper = ({
  campsiteName,
  campsiteNames,
  excludeGeoLocationTypes,
  handleTypeaheadChange,
  handleLocationChange,
  handleLocationSelect,
  loading,
  location,
  minLocationSearchRadiusMilesDecimal,
  searchBy,
  size,
  isOverseas,
  handleMyLocationSelect,
  locationPermission,
  showUseMyLocation,
}) => {
  // don't render on SSR
  const [hasMounted, setHasMounted] = useState(false);
  const [showResults, setShowResults] = useState(false);
  useEffect(() => setHasMounted(true), []);

  const handleChange = (changeProps) => {
    handleLocationChange(changeProps);
    setShowResults(true);
  };

  // TEMP Performance Hooks, TODO can be safely removed at any time
  useEffect(() => {
    if (hasMounted) {
      addTiming(types.IBE, 'UI Search Load Complete', true);
    }
  }, [hasMounted]);

  return (
    <>
      {hasMounted && searchBy === searchByType.CAMPSITE && (
        <Typeahead
          onChange={handleTypeaheadChange}
          items={campsiteNames}
          keypath="name"
          value={campsiteName}
          isOverseas={isOverseas}
        >
          <SearchInput
            ariaLabel="Search by Campsite"
            block
            placeholder="Campsite"
            size={size}
            disabled={loading}
            {...testingAttr('search__name-input')}
          />
        </Typeahead>
      )}

      {searchBy === searchByType.LOCATION && (
        <PlacesTypeahead
          excludeGeoLocationTypes={excludeGeoLocationTypes}
          minLocationSearchRadiusMilesDecimal={minLocationSearchRadiusMilesDecimal}
          onChange={handleChange}
          onSelect={handleLocationSelect}
          value={location}
          showResults={showResults}
          {...testingAttr('search__location-input')}
        >
          <LocationSearchInput
            ariaLabel="Search by Location"
            block
            placeholder="Location"
            size={size}
            onClickUseMyLocation={handleMyLocationSelect}
            grantedPermission={locationPermission === USER_LOCATION_PERMISSIONS.ACCEPTED}
            location={location}
            showUseMyLocation={showUseMyLocation}
            {...testingAttr('search__location-input')}
          />
        </PlacesTypeahead>
      )}
    </>
  );
};

CampsitesSearchInputWrapper.propTypes = {
  campsiteName: PropTypes.string,
  campsiteNames: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  excludeGeoLocationTypes: PropTypes.arrayOf(PropTypes.string),
  handleTypeaheadChange: PropTypes.func.isRequired,
  handleLocationChange: PropTypes.func.isRequired,
  handleLocationSelect: PropTypes.func.isRequired,
  handleMyLocationSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.string,
  minLocationSearchRadiusMilesDecimal: PropTypes.number,
  searchBy: PropTypes.string.isRequired,
  size: PropTypes.string,
  isOverseas: PropTypes.bool,
  locationPermission: PropTypes.string,
  showUseMyLocation: PropTypes.bool,
};

CampsitesSearchInputWrapper.defaultProps = {
  campsiteName: '',
  excludeGeoLocationTypes: undefined,
  size: 'medium',
  location: '',
  minLocationSearchRadiusMilesDecimal: 30,
  isOverseas: false,
  locationPermission: USER_LOCATION_PERMISSIONS.NOT_KNOWN,
  showUseMyLocation: true,
};

export default memo(CampsitesSearchInputWrapper);
