import React from 'react';
import PropTypes from 'prop-types';

import StyledCampsiteSearchError from './CampsiteSearchError.style';

const defaultProps = {
  children: null,
  text: null,
  type: 'info',
};

const propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  text: PropTypes.string,
};

function CampsiteSearchError(props) {
  const noteProps = {
    type: props.type,
    text: props.text,
  };

  return (
    <StyledCampsiteSearchError {...noteProps}>
      {props.text ? props.text : props.children}
    </StyledCampsiteSearchError>
  );
}

CampsiteSearchError.defaultProps = defaultProps;
CampsiteSearchError.propTypes = propTypes;

export default CampsiteSearchError;
