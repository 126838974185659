import React from 'react';
import PropTypes from 'prop-types';

import { dictionaryItem } from '../../hocs/withDictionary';

import Button from '../ui/Button';

import StyledMapOffline, {
  Container,
  OfflineIcon,
  Heading,
  OfflineText,
} from './MapOffline.style';

import svgError from '../../static/images/icons/Error.svg';

const propTypes = {
  onClick: PropTypes.func.isRequired,
};

function MapOffline({ onClick }) {
  return (
    <StyledMapOffline>
      <Container>
        <OfflineIcon icon={svgError} />
        <Heading dictionary={dictionaryItem('Heading')} />
        <OfflineText dictionary={dictionaryItem('Body')} />
        <Button dictionary={dictionaryItem('MapOffline')} onClick={onClick} />
      </Container>
    </StyledMapOffline>
  );
}

MapOffline.propTypes = propTypes;

export default MapOffline;
