import styled, { css } from 'styled-components';

import detectIE from '../../../lib/detectIE';

// Import theme to use in makeColumns
import theme from '../../../styles/config/theme';

function makeColumns(col = 1) {
  return css`
    width: ${100 / col}%;
    flex: 0 0 ${100 / col}%;

    ${!!detectIE() && `
      flex: 0 0 100%;
    `}

    ${Array.from({ length: col }, (v, i) => i).map(i => `
      &:nth-child(${col * 2}n + ${col + i + 1}) {
        background-color: ${theme.COLOR_WHITE_DARK};
      }
    `)}

    &:last-child {
      width: auto;
      flex: 1;
    }
  `;
}

export default styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  font-size: 0.875rem;
  padding: 0.5rem 1.25rem;
  background-color: ${theme.COLOR_LIGHTER};
  min-height: 2.75rem;
  width: 100%;

  ${({ flex, justifyContent }) => css`
    flex: ${flex || '0 0 100%'};
    justify-content: ${justifyContent || 'space-between'};
  `}

  ${({ colMobile }) => colMobile && css`
    @media only screen and (max-width: ${theme.DESKTOP}px) {
      ${makeColumns(colMobile)}}
    }
  `}

  ${({ colDesktop }) => colDesktop && css`
    @media only screen and (min-width: ${theme.DESKTOP}px) {
      ${makeColumns(colDesktop)}
    }
  `}

  ${({ small }) => small && css`
    min-height: 1.5rem;
    padding: 0.3rem 1.25rem;
  `}
`;
