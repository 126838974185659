import styled from 'styled-components';
import { below } from '../../../styles/libs';

export default styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${({ theme, invert }) => (invert ? theme.COLOR_WHITE_DARK : theme.COLOR_WHITE)};

  &:nth-child(2n) {
    background-color: ${({ theme, invert }) => (invert ? theme.COLOR_WHITE : theme.COLOR_WHITE_DARK)};
  }

  ${({ theme, chunk }) => chunk && below(theme.MOBILE_LARGE)`
    flex-direction: column;
    background-color: ${theme.COLOR_WHITE};

    &:nth-child(2n) {
      background-color: ${theme.COLOR_WHITE};
    }
  `}
`;
