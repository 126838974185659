import React from 'react';
import PropTypes from 'prop-types';

import { Text, ListItem } from './CampsiteFeature.style';
import CampsiteFeatureIcon from './CampsiteFeatureIcon';

const CampsiteFeatureLink = ({ onClick, label }) => (
  <ListItem onClick={onClick}>
    <CampsiteFeatureIcon />
    <Text>{ label }</Text>
  </ListItem>
);

CampsiteFeatureLink.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

CampsiteFeatureLink.defaultProps = {
  onClick: undefined,
};

export default CampsiteFeatureLink;
