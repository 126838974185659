import { util } from 'node-forge';
import theme from '../styles/config/theme';

export default function CreateCluster(size = 53, backgroundColor = theme.COLOR_LAKE_GREEN_DARK) {
  const padding = 4;

  const svg = `<?xml version="1.0" encoding="UTF-8"?>
    <svg width="${size}px" height="${size}px" viewBox="0 0 ${size + padding} ${size + padding}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <circle id="path-1" cx="${(size / 2) + (padding / 2)}" cy="${(size / 2) + (padding / 2)}" r="${size / 2}"></circle>
        <filter x="-8.8%" y="-11.2%" width="127.5%" height="127.5%" filterUnits="objectBoundingBox" id="filter-2">
          <feMorphology radius="1.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
          <feOffset dx="2" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
          <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
          <feColorMatrix values="0 0 0 0 0.57254902   0 0 0 0 0.57254902   0 0 0 0 0.57254902  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
      </defs>
      <g id="New-direction" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Oval-4-Copy-10">
          <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
          <use stroke="#FFFFFF" stroke-width="3" fill="${backgroundColor}" fill-rule="evenodd" xlink:href="#path-1"></use>
        </g>
      </g>
    </svg>
  `;

  return `data:image/svg+xml;charset=UTF-8;base64,${util.encode64(svg)}`;
}
