import React, { useState } from 'react';
import { withTheme } from 'styled-components';

import ClusteringPanelWrapper, {
  FormGroup,
  Label,
  ReloadButton,
  ErrorMessage,
  Input,
} from './ClusteringPanel.style';

export const toInt = (str, defaultValue) => {
  try {
    const integer = parseInt(str, 10);
    if (Number.isNaN(integer)) return defaultValue;
    return integer;
  } catch (error) {
    return defaultValue;
  }
};

const ClusteringPanel = () => {
  const [error, setError] = useState(null);
  const [clusterState, setClusterState] = useState({
    gridSize: sessionStorage.getItem('gridSize') ?? 84,
    minimumClusterSize: sessionStorage.getItem('minimumClusterSize') ?? 3,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClusterState({
      ...clusterState,
      [name]: toInt(value),
    });
  };

  const handleReload = () => {
    const { gridSize, minimumClusterSize } = clusterState;

    if (gridSize <= 0 || minimumClusterSize <= 0) {
      setError('Please enter only positive values.');
      return;
    }
    sessionStorage.setItem('gridSize', toInt(gridSize));
    sessionStorage.setItem('minimumClusterSize', toInt(minimumClusterSize));
    window.location.reload();
  };

  return (
    <ClusteringPanelWrapper>
      <h3>Clustering Controls</h3>
      <FormGroup>
        <Label htmlFor="gridSize">Grid Size</Label>
        <Input
          type="number"
          name="gridSize"
          onChange={handleInputChange}
          value={clusterState.gridSize}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="minimumClusterSize">Min. Cluster Size</Label>
        <Input
          type="number"
          name="minimumClusterSize"
          onChange={handleInputChange}
          value={clusterState.minimumClusterSize}
        />
      </FormGroup>
      {error &&
        <ErrorMessage>{error}</ErrorMessage>
      }
      <ReloadButton type="button" onClick={handleReload}>
        Reload
      </ReloadButton>
    </ClusteringPanelWrapper>
  );
};

export default withTheme(ClusteringPanel);
