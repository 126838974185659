import {
  isValid, isBefore, startOfDay, addDays,
} from 'date-fns';
import { formatToHyphenFormat } from '../format';
import { dictionaryItem } from '../../hocs/withDictionary';

export function validateDates(startDate, endDate, availabilityMaxDate, offsetDays = 2) {
  const errors = [];
  const isStartValid = isValid(new Date(formatToHyphenFormat(startDate)));
  const isEndValid = isValid(new Date(formatToHyphenFormat(endDate)));

  if ((startDate && !isStartValid) || (endDate && (!startDate || !isStartValid))) {
    errors.push({ type: 'startDate', message: dictionaryItem('CampsiteSearchForm', 'Error', 'StartDate') });
  }

  if (startDate && (!endDate || !isEndValid)) {
    errors.push({ type: 'endDate', message: dictionaryItem('CampsiteSearchForm', 'Error', 'EndDate') });
  }

  if (startDate && endDate && isStartValid && isEndValid) {
    // Validate if dates are before today
    if (
      isBefore(
        new Date(formatToHyphenFormat(startDate)),
        startOfDay(addDays(new Date(), offsetDays)),
      ) || isBefore(
        new Date(formatToHyphenFormat(endDate)),
        startOfDay(addDays(new Date(), offsetDays)),
      )) {
      errors.push({ type: 'dp', message: dictionaryItem('SearchFormCampsite', 'Error', 'DateBeforeMinumumDate') });
    }

    if (isBefore(new Date(availabilityMaxDate), new Date(formatToHyphenFormat(startDate))) ||
      isBefore(new Date(availabilityMaxDate), new Date(formatToHyphenFormat(endDate)))) {
      errors.push({ type: 'dp', message: dictionaryItem('SearchFormCampsite', 'Error', 'DateAfterMaxDate') });
    }

    if (!isBefore(
      new Date(formatToHyphenFormat(startDate)), new Date(formatToHyphenFormat(endDate)),
    )) {
      errors.push({ type: 'dp', message: dictionaryItem('SearchFormCrossings', 'Error', 'DateOrder') });
    }
  }

  return errors;
}

export default null;
