import React from 'react';
import styled, { withTheme, css } from 'styled-components';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import testingAttr from '../../lib/testingAttr';
import Checkbox from '../ui/Form/Checkbox';
import Label from '../ui/Form/Label';
import { dictionaryItem } from '../../hocs/withDictionary';
import { ids as campsiteTypes } from '../../config/campsiteTypes';
import { parseTypes } from '../../lib/campsiteTypes';
import updateRouterQuery from '../../lib/updateRouterQuery';
import routes from '../../constants/routes';

const customStyled = css`
  border: 1px solid ${({ theme }) => theme.COLOR_LAKE_GREEN_DARK};
  border-radius: 0.25rem;
`;

const CheckboxFormGroup = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const CheckboxLabel = styled(Label)`
  font-size: 0.875rem;
  margin-left: 0.5rem;
  padding-top: 0.125rem;
`;

const IncludeCertificatedLocationsToggle = ({ theme, onQueryChange }) => {
  const router = useRouter();
  const isOverseas = router?.query?.isOverseas === 'true';

  const checked = parseTypes(router.query?.types).includes(campsiteTypes.CERTIFICATED_LOCATIONS);

  const onChange = async () => {
    const { query } = router;
    const activeFilters = { types: parseTypes(query.types) };
    if (!checked) {
      activeFilters.types.push(campsiteTypes.CERTIFICATED_LOCATIONS);
    } else {
      activeFilters.types = activeFilters.types.filter(
        t => t !== campsiteTypes.CERTIFICATED_LOCATIONS,
      );
    }

    await updateRouterQuery(routes.sites, { ...activeFilters });
  };

  return (
    <>
      {!isOverseas &&
      <CheckboxFormGroup>
        <Checkbox
          customStyled={customStyled}
          checked={checked}
          name="includeCertificatedLocations"
          onChange={onChange}
          {...testingAttr('include-certificated-locations__checkbox')}
        />
        <CheckboxLabel
          addColon={false}
          color={theme.COLOR_WHITE}
          dictionary={dictionaryItem('CampsiteSearchForm', 'IncludeCertificatedLocationsToggle')}
          htmlFor="includeCertificatedLocations"
        />
      </CheckboxFormGroup>
      }
    </>
  );
};

IncludeCertificatedLocationsToggle.propTypes = {
  theme: PropTypes.shape({
    COLOR_WHITE: PropTypes.string,
  }),
  onQueryChange: PropTypes.func.isRequired,
};

IncludeCertificatedLocationsToggle.defaultProps = {
  theme: {},
};

export default withTheme(IncludeCertificatedLocationsToggle);
