import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';
import { withRouter } from 'next/router';
import { withTheme } from 'styled-components';

import SiteCard from '../../SiteCard';

import DrawerCampsiteAvailabilityStyle from './DrawerCampsiteAvailability.style';

import updateRouterQuery from '../../../lib/updateRouterQuery';
import IbePropTypes from '../../../IbePropTypes';
import routes from '../../../constants/routes';

class DrawerCampsiteAvailability extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    theme: PropTypes.shape({
      COLOR_WHITE: PropTypes.string,
    }),
    toggleBasket: PropTypes.func.isRequired,
    router: PropTypes.shape(IbePropTypes.router).isRequired,
    additionalCampingPitch: PropTypes.bool,
    onToggleAdditionalCampingPitch: PropTypes.func,
  }

  static defaultProps = {
    children: null,
    additionalCampingPitch: false,
    onToggleAdditionalCampingPitch: null,
    theme: {},
  }

  state = {
    headerOffset: 0,
  };

  el = React.createRef();

  componentDidMount() {
    if ('IntersectionObserver' in window) {
      this.TARGET = document.querySelector('.header');
      if (this.TARGET) {
        this.HEADER_HEIGHT = this.TARGET.getBoundingClientRect().height;
        this.OBSERVER = new IntersectionObserver(this.observerCallback, {
          threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
        });

        this.setState({ headerOffset: this.HEADER_HEIGHT });
        this.OBSERVER.observe(this.TARGET);
      }
    }
  }

  componentWillUnmount() {
    if ('IntersectionObserver' in window && this.OBSERVER && this.TARGET) {
      this.OBSERVER.unobserve(this.TARGET);
    }
  }

  observerCallback = (entries) => {
    const [entry] = entries;
    this.setState({ headerOffset: this.HEADER_HEIGHT * entry.intersectionRatio });
  }

  render() {
    const {
      children,
      theme,
      router: { query },
      toggleBasket,
      additionalCampingPitch,
      onToggleAdditionalCampingPitch,
    } = this.props;
    const { headerOffset } = this.state;

    const onRequestChange = () => {
      if (additionalCampingPitch) {
        onToggleAdditionalCampingPitch();
      } else {
        updateRouterQuery(
          routes.sites, { ...this.props.router.query, siteCode: null, componentId: null },
        );
      }
    };

    const handleNext = () => {
      if (!additionalCampingPitch) {
        return;
      }
      const addToBasket = document.querySelector('#addToBasket');
      if (addToBasket) {
        addToBasket.click();
      }
    };

    return (
      <DrawerCampsiteAvailabilityStyle
        useMarginForAnimation
        direction="left"
        inView={!!query.siteCode}
        open={!!query.siteCode}
        forwardRef={this.el}
        onRequestChange={onRequestChange}
        offset={headerOffset}
      >
        <SiteCard
          backgroundColor={theme.COLOR_WHITE}
          handleCheckoutToggle={toggleBasket}
          toggleBasket={toggleBasket}
          handleNext={additionalCampingPitch ? handleNext : undefined}
        >
          {!!query.siteCode && children}
        </SiteCard>
      </DrawerCampsiteAvailabilityStyle>
    );
  }
}

export default compose(withRouter, withTheme)(DrawerCampsiteAvailability);
