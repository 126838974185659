import React from 'react';
import PropTypes from 'prop-types';

import StyledFlexCell from './FlexCell.style';

const defaultProps = {
  colDesktop: null,
  colMobile: null,
  flex: null,
};

const propTypes = {
  colDesktop: PropTypes.number,
  colMobile: PropTypes.number,
  flex: PropTypes.number,
  children: PropTypes.node.isRequired,
};

function FlexCell(props) {
  return (
    <StyledFlexCell {...props}>
      {props.children}
    </StyledFlexCell>
  );
}

FlexCell.defaultProps = defaultProps;
FlexCell.propTypes = propTypes;

export default FlexCell;
