import gql from 'graphql-tag';
import { stringify } from 'query-string';

import connectionType from '../../../lib/connectionType';

/* eslint-disable camelcase */
function shouldSkip({
  ne_lat,
  ne_lng,
  sw_lat,
  sw_lng,
}) {
  return !ne_lat || !ne_lng || !sw_lat || !sw_lng;
}
/* eslint-enable camelcase */

export function getCampsiteListingOptions({ query }) {
  function pathBuilder({ args }) {
    return `campsite/search?${stringify(args)}`;
  }

  return {
    notifyOnNetworkStatusChange: true,
    variables: {
      pathBuilder,
      topLeftLat: query.ne_lat,
      topLeftLon: query.sw_lng,
      bottomRightLat: query.sw_lat,
      bottomRightLon: query.ne_lng,
      features: query.features,
      types: query.types,
      page: 0,
      pageSize: connectionType(),
      skip: shouldSkip(query),
    },
  };
}

export default gql`
  query ListingData(
    $pathBuilder: any,
    $topLeftLat: Float!,
    $topLeftLon: Float!,
    $bottomRightLat: Float!,
    $bottomRightLon: Float!,
    $features: [Float],
    $types: [Float],
    $page: Int!,
    $pageSize: Int!,
    $suggestedUkItineraryId: String,
    $skip: Boolean,
    $sortType: Boolean,
    $searchLat: Float,
    $searchLon: Float,
  ) {
    campsiteListing(
      topLeftLat: $topLeftLat,
      topLeftLon: $topLeftLon,
      bottomRightLat: $bottomRightLat,
      bottomRightLon: $bottomRightLon,
      suggestedUkItineraryId: $suggestedUkItineraryId,
      features: $features,
      types: $types
      page: $page,
      pageSize: $pageSize,
      sortType: $sortType,
      searchLat: $searchLat,
      searchLon: $searchLon,
    ) @rest(
      type: "CampsiteListing",
      pathBuilder: $pathBuilder
    ) @skip(if: $skip) {
      aggregations
      count
      data {
        address {
          addressLines
          country
          county
          postCode
        }
        distanceMeters
        hasLowAvailability
        hasOffers
        id
        gallery
        isFeatured
        name
        numberOfReviews
        placeEvent
        rating
        topFeatures {
          id
          name
          type
          description
          iconUrl
        }
        type
        usp
      }
    }
  }
`;
