import styled, { css } from 'styled-components';
import { above } from '../../styles/libs';

import { Row as RowComponent, Col as ColComponent } from '../ui/Grid';

export const SpecialOffersWrapper = styled.div``;

export const Row = styled(RowComponent)``;

export const Col = styled(ColComponent)`
  flex: 0 0 20%;

  ${({ theme }) => above(theme.TABLET)`
    flex: 0 0 20%;
  `}
`;

export const Button = styled.button`
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  border: 2px solid transparent;
  border-radius: 5px;
  color: ${({ color, theme }) => color || theme.COLOR_TEXT_BODY};
  display: flex;
  cursor: pointer;
  line-height: 1.4;
  ${({ maxWidth }) => maxWidth && css`max-width: ${maxWidth};`}
  padding: 0;
  text-transform: uppercase;
  transition: all 250ms ease;
  user-select: none;
  ${({ block }) => (block ? 'width: 100%;' : null)}


  & + & {
    margin-left: 1rem;
  }
`;

export const Image = styled.img`
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  height: 100px;
`;

export default null;
