import styled, { css } from 'styled-components';

import Note from '../Note';
import RatingComponent from '../Rating';
import Text from '../ui/Text';
import Title from '../ui/Title';

import { above, below } from '../../styles/libs';
import { divide } from '../../styles/libs/calculate';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border-bottom: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  border-right: 0.25rem solid transparent;
  padding: 0.75rem;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  ${({ loading }) => loading === 'true' && css`opacity: 0.5;`}
  pointer-events: ${({ loading }) => (loading === 'true' ? 'none' : 'auto')};

  &:hover {
    background-color: ${({ theme, onClick }) => (onClick ?
    theme.COLOR_GRAY_LIGHTEST : theme.COLOR_WHITE)};
  }

  ${({ featured, theme }) => featured && css`
    background-color: ${theme.COLOR_GRAY_LIGHTEST};
    border: 3px solid ${theme.COLOR_GRAY_LIGHT};
  `}

  ${({ selected, selectedColor, theme }) => selected && css`
    background-color: ${theme.COLOR_GRAY_LIGHTEST};
    border-right-color: ${selectedColor};
  `}

  ${({ theme }) => css`
    ${above(theme.TABLET)`
      padding: ${theme.PADDING};
    `}

    ${above(theme.DESKTOP)`
      padding: ${theme.PADDING_LARGE};
    `}
  `}
`;

export const Image = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 6rem;

  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ${({ theme }) => theme.RATIO_5_3};
  }

  ${({ theme }) => above(theme.MOBILE_LARGE)`
    width: 8rem;
  `}

  ${({ theme }) => above(theme.TABLET)`
    width: 10rem;
  `}
`;

export const Img = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  object-fit: cover;
`;

export const MissingImg = styled(Img)`
  background-color: ${({ theme }) => theme.COLOR_TEXT_MUTED};
  background-size: 2rem;
  opacity: 0.15;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${({ wrap }) => wrap && css`
    flex-wrap: ${wrap};
  `}

  ${({ theme }) => css`
    margin: 0 -${divide(theme.PADDING_SMALL, 2)};

    ${above(theme.TABLET)`
      margin: 0 -${divide(theme.PADDING, 2)};
    `}

    ${above(theme.DESKTOP)`
      margin: 0 -${divide(theme.PADDING_LARGE, 2)};
    `}
  `}
`;

export const Col = styled.div`
  ${({ theme }) => css`
    padding: 0 ${divide(theme.PADDING_SMALL, 2)};

    ${above(theme.TABLET)`
      padding: 0 ${divide(theme.PADDING, 2)};
    `}

    ${above(theme.DESKTOP)`
      padding: 0 ${divide(theme.PADDING_LARGE, 2)};
    `}
  `}

  ${({ onClick }) => onClick && css`
    cursor: pointer;
  `}
`;

export const LeftCol = styled(Col)`
  flex: 0;
`;

export const RightCol = styled(Col)`
  flex: 1;
  position: relative;
`;

export const Footer = styled.div`
  position: relative;
  margin-top: 0.25rem;

  ${({ theme }) => above(theme.TABLET)`
    display: flex;
  `}
`;

export const Pricing = styled.div`
  font-size: 0.625rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  min-height: 1.875rem;

  ${({ theme, align }) => above(theme.TABLET)`
    margin-left: ${align === 'left' ? 0 : 'auto'};
    font-size: 1rem;
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ItemTitle = styled(Title)`
  font-weight: 600;
  font-size: 0.875rem;
  margin-bottom: 0.125rem;

  ${({ theme }) => above(theme.TABLET)`
    font-size: 1rem;
    padding-right: 1rem;
  `}
`;

export const Rating = styled(RatingComponent)`
  margin-left: auto;

  ${({ hidden, theme }) => below(theme.TABLET)`
    display: ${hidden === 'mobile' ? 'none' : 'flex'};
  `}

  ${({ hidden, theme }) => above(theme.TABLET)`
    display: ${hidden === 'desktop' ? 'none' : 'flex'};
  `}
`;

export const Description = styled.div`
  display: block;
  font-size: 0.6875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 200px;

  ${({ full }) => full && css`
    display: -webkit-box;
    width: 100%;
    white-space: normal;
    word-wrap: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  `}

  ${({ theme }) => above(theme.TABLET)`
    font-size: 0.875rem;
  `}
`;

export const SubHeading = styled.div`
  font-size: 0.6875rem;

  span {
    font-weight: bold;
  }

  ${({ theme }) => above(theme.TABLET)`
     span {
      font-size: 0.875rem;
    }
  `}
`;

export const Featured = styled(Text)`
  color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY};
  font-weight: 600;
  margin-bottom: 0.875rem;

  ${({ theme }) => above(theme.TABLET)`
    font-size: 1rem;
  `}
`;

export const Features = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0.25rem 0;
`;

export const Feature = styled.div`
  background-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY};
  border-radius: 50%;
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.COLOR_WHITE};
  font-size: 0.625rem;

  @media only screen and (min-width: ${({ theme }) => theme.DESKTOP}px) {
    font-size: 0.75rem;
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const USP = styled.div`
  display: none;
  font-size: 0.6875rem;

  ${({ theme }) => above(theme.TABLET)`
    display: block;
    font-size: 0.875rem;
  `}
`;

export const OffersNote = styled(Note)`
  min-height: 0;
  padding: 0;

  ${({ theme }) => above(theme.TABLET)`
    margin-bottom: -0.25em;
  `}
`;

export const LowAvailability = styled(Text)`
  position: absolute;
  top: calc(100% - 0.25rem);
  right: 0;
  color: ${({ theme }) => theme.COLOR_LAKE_GREEN_DARK};
  font-size: 0.85rem;
`;

export const ToursActions = styled.div`
  width: 100%;
  padding: 1rem 0.5rem;
  padding-bottom: 0;
`;

export const ListingEventDistanceContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
  align-items: start;
  padding: 0 0.5rem;
`;

export const Distance = styled.div`
  font-size: 0.85rem;
  opacity: 0.8;
`;
