const scrollEvent = () => {
  if (!document || !window) {
    return;
  }
  let event;

  if (typeof Event === 'function') {
    event = new Event('scroll');
  } else {
    // IE 11 support
    event = document.createEvent('Event');
    event.initEvent('scroll', true, true);
  }

  window.dispatchEvent(event);
};

export default scrollEvent;
