import React, { memo } from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import ListingBookNow from './ListingBookNow';
import ListingResultPrice from './ListingResultPrice';
import Rating from '../Rating';
import { Row, Col } from '../ui/Grid';
import Text from '../ui/Text';

import testingAttr from '../../lib/testingAttr';
import { dictionaryItem, mapDictionaryItems } from '../../hocs/withDictionary';
import IbePropTypes from '../../IbePropTypes';

import {
  Button,
  ColCTA,
  BookingDetails,
  LowAvailability,
} from './ListingResult.style';

function PricesReviewsBookNow({
  activeQuote,
  campsiteType,
  client,
  error,
  handleBookNowClick,
  handleReviewsClick,
  hasLowAvailability,
  id,
  memberPrice,
  noOfReviews,
  rating,
  reviewsAreDisabled,
  siteCode,
  standardPrice,
  type,
  selectedEvent,
  isTours,
  loading,
  priceSuffix,
}) {
  const {
    cheapestMemberPrice,
    cheapestStandardPrice,
  } = selectedEvent ?? {};
  const dictionaryValues = mapDictionaryItems(client, {
    text: 'ListingBookNow__CallToBook__Button',
    phoneNumber: 'CamcCallCentrePhoneNumber__Text',
    toursPhoneNumber: 'CamcCallCentreToursPhoneNumber__Text',
    priceSuffix: `ListingResult__PriceSuffix${isTours ? '__Tours' : ''}__Text`,
  });
  return (
    <BookingDetails>
      <Row align="center" size="small">
        <Col>
          <ListingResultPrice
            error={error}
            loading={loading}
            standardPrice={standardPrice ?? cheapestStandardPrice}
            memberPrice={memberPrice ?? cheapestMemberPrice}
            priceSuffix={dictionaryValues.priceSuffix}
            hidePence={isTours}
          />
          {!isTours ?
            <Button
              disabled={reviewsAreDisabled}
              onClick={handleReviewsClick}
            >
              <Rating
                noOfReviews={noOfReviews}
                rating={rating}
                size="0.625rem"
              />
            </Button> :
            <Text size="0.875rem">{priceSuffix}</Text>}
          {hasLowAvailability &&
            <LowAvailability dictionary={dictionaryItem('ListingResult', 'LowAvailability')} />
          }
        </Col>
        {(siteCode && campsiteType) &&
          <ColCTA>
            <ListingBookNow
              callToBook={dictionaryValues}
              campsiteType={type}
              disabled={activeQuote}
              isTours={isTours}
              onClick={handleBookNowClick ? () => handleBookNowClick(siteCode) : undefined}
              {...testingAttr('listing-result__book-now-btn')}
            />
          </ColCTA>
        }
      </Row>
    </BookingDetails>
  );
}

PricesReviewsBookNow.propTypes = {
  activeQuote: PropTypes.bool,
  campsiteType: PropTypes.number,
  client: PropTypes.shape(IbePropTypes.client),
  error: PropTypes.shape({}),
  handleBookNowClick: PropTypes.func,
  handleReviewsClick: PropTypes.func,
  hasLowAvailability: PropTypes.bool,
  id: PropTypes.string,
  isTours: PropTypes.bool,
  memberPrice: PropTypes.number,
  noOfReviews: PropTypes.number,
  priceSuffix: PropTypes.string,
  rating: PropTypes.number,
  reviewsAreDisabled: PropTypes.bool,
  siteCode: PropTypes.string,
  standardPrice: PropTypes.number,
  type: PropTypes.string,
  loading: PropTypes.bool,
  selectedEvent: PropTypes.shape(IbePropTypes.event),
};

PricesReviewsBookNow.defaultProps = {
  activeQuote: false,
  campsiteType: null,
  client: null,
  error: null,
  handleBookNowClick: () => {},
  handleReviewsClick: () => {},
  hasLowAvailability: false,
  id: '',
  isTours: false,
  memberPrice: 0,
  noOfReviews: null,
  priceSuffix: '',
  rating: 0,
  reviewsAreDisabled: false,
  siteCode: null,
  standardPrice: 0,
  type: '',
  selectedEvent: null,
  loading: false,
};

export default withApollo(memo(PricesReviewsBookNow));
