import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { DisplayToRootContext } from '../../context/DisplayToRootProvider';

const DisplayToRoot = ({ children }) => {
  const context = useContext(DisplayToRootContext);

  useEffect(() => {
    context.setChildren(children);
  }, [children, context]);

  return <></>;
};

DisplayToRoot.propTypes = {
  children: PropTypes.node,
};

DisplayToRoot.defaultProps = {
  children: null,
};

export default DisplayToRoot;
