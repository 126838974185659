import React, { memo } from 'react';
import PropTypes from 'prop-types';

import {
  Header,
  CampsiteDetails,
  CampsiteTitleContainer,
  CampsiteIconsContainer,
  CampsiteIcon,
  CampsiteTitle,
  Address,
  Ref,
} from './ListingResult.style';

import testingAttr from '../../lib/testingAttr';
import formatAddress from '../../lib/formatAddress';
import { ButtonLink } from '../ui/Button';
import Text from '../ui/Text';

import ToursEvent from '../../static/images/icons/ToursEvent.svg';
import CampingEvent from '../../static/images/icons/CampingEvent.svg';
import GlampingEvent from '../../static/images/icons/GlampingEvent.svg';
import IbePropTypes from '../../IbePropTypes';
import PLACE_EVENT_TYPES,
{ SINGLE_EVENT_TYPE } from '../../config/eventTypes';

function ListingResultHeader(props) {
  const {
    contactName,
    emailAddress,
    id,
    name,
    address,
    placeCode,
    telephoneNumber,
    pitches,
    isTours,
    numberOfNights,
    numNightsSuffix,
    websiteUrl,
  } = props;
  const hasEventType = (eventType) => !!pitches.find(
    (eventItem) => eventItem.eventType === eventType,
  );
  return (
    <Header>
      <CampsiteDetails>
        <CampsiteTitleContainer>
          <CampsiteTitle {...testingAttr(`listing-result-${id}__title`)}>
            {name}
          </CampsiteTitle>
          {!isTours &&
          <CampsiteIconsContainer>
            {(hasEventType(PLACE_EVENT_TYPES.TOURING.id) ||
              hasEventType(SINGLE_EVENT_TYPE.id)) && <CampsiteIcon
                icon={ToursEvent}
                largeIcon
              />}
            {hasEventType(PLACE_EVENT_TYPES.CAMPING.id) && <CampsiteIcon
              icon={CampingEvent}
            />}
            {hasEventType(PLACE_EVENT_TYPES.GLAMPING.id) && <CampsiteIcon
              icon={GlampingEvent}
            />}
          </CampsiteIconsContainer>}
        </CampsiteTitleContainer>
        {!!numberOfNights &&
          <Address>
            <Text fontSize="0.875rem">
              {numberOfNights} {numNightsSuffix}
            </Text>
          </Address>
        }
        {props.revealAddress &&
          <Address>
            {address && !isTours && (
              <>
                {formatAddress(address)}
              </>
            )}
            {contactName &&
              <Text fontSize="0.875rem">
                {contactName}
              </Text>
            }
            {telephoneNumber &&
              <div>
                <ButtonLink noPadding href={`tel:${telephoneNumber}`}>{` ${telephoneNumber}`}</ButtonLink>
              </div>
            }
            {emailAddress &&
              <div>
                <ButtonLink textTransform="none" noPadding href={`mailto:${emailAddress}`}>{` ${emailAddress}`}</ButtonLink>
              </div>
            }
            {websiteUrl &&
              <div>
                <ButtonLink textTransform="none" noPadding href={websiteUrl} target="_blank">{` ${websiteUrl}`}</ButtonLink>
              </div>
            }
            {placeCode &&
              <Ref>{` (REF: ${placeCode})`}</Ref>
            }
          </Address>
        }

      </CampsiteDetails>
    </Header>
  );
}

ListingResultHeader.propTypes = {
  contactName: PropTypes.string,
  emailAddress: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.shape({}),
  revealAddress: PropTypes.bool.isRequired,
  placeCode: PropTypes.string,
  telephoneNumber: PropTypes.string,
  events: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.event)),
  pitches: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.pitch)),
  isTours: PropTypes.bool,
  numberOfNights: PropTypes.number,
  numNightsSuffix: PropTypes.string,
  websiteUrl: PropTypes.string,
};

ListingResultHeader.defaultProps = {
  address: null,
  contactName: null,
  emailAddress: null,
  placeCode: null,
  telephoneNumber: null,
  events: [],
  pitches: [],
  isTours: false,
  numberOfNights: 0,
  numNightsSuffix: '',
  websiteUrl: '',
};

export default memo(ListingResultHeader);
