export function metersToMilesFormatter(
  meters,
  showALongWayWhenGreaterThanDistanceDecimal,
  showOneDecimalPlaceWhenLessThanDistanceDecimal,
  milesText,
  longWayText,
) {
  let formattedMiles;
  const miles = meters * 0.000621371192;

  if (miles <= showOneDecimalPlaceWhenLessThanDistanceDecimal) {
    // show with one decimal place
    formattedMiles = `${Math.abs(miles).toFixed(1)} ${milesText}`;
  } else if (miles > showOneDecimalPlaceWhenLessThanDistanceDecimal &&
    miles <= showALongWayWhenGreaterThanDistanceDecimal) {
    // show as whole number, n decimal places
    formattedMiles = `${Math.round(miles)} ${milesText}`;
  } else {
    // show as "a long way"
    formattedMiles = longWayText;
  }

  return formattedMiles;
}

export default { metersToMilesFormatter };
