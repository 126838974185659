import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../ui/Icon';

import { Back } from './SiteCard.style';

import svgArrowLeft from '../../static/images/icons/ArrowLeft.svg';

const propTypes = {
  handleClick: PropTypes.func.isRequired,
};

function SiteCardBack({ handleClick }) {
  return (
    <Back
      align="left"
      type="button"
      onClick={handleClick}
    >
      <Icon
        icon={svgArrowLeft}
        style={{ marginRight: '1rem' }}
      />
      <span>Back</span>
    </Back>
  );
}

SiteCardBack.propTypes = propTypes;

export default SiteCardBack;
