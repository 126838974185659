import React from 'react';
import { compose, graphql } from 'react-apollo';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';

import IbePropTypes from '../../IbePropTypes';
import queryHelper from '../../lib/queryHelper';
import GET_CAMPSITE, {
  getCampsiteOptions,
} from '../../config/graphql/getCampsite';
import { getCampsiteFeatureByTag } from '../../lib/helpers/campsites';
import FEATURE_TYPES from '../../config/featureTypes';
import { getDefaultPlaceEvent, mergeListingAndPrices } from '../Search/CampsiteListAndPriceQueryHelpers';
import { checkOpen } from '../../lib/helpers/availability';
import GET_CAMPSITE_PRICE, { campsitePriceOptions } from '../../config/graphql/getCampsitePrice';
import PLACE_EVENT_TYPES from '../../config/eventTypes';
import GET_CONFIGURATION from '../../config/graphql/getConfiguration';

import ListingItem from './ListingItem';
import { useGoogle } from '../../context/GoogleProvider';

const SpecialListingItem = ({
  router,
  campsite,
  pricesData,
  loadingPrice,
  eventType,
  isOverseas,
  activePin,
  isTours,
  start,
  end,
  isUkItinerary,
  onLoadImage,
  onResultClick,
  loadingCampsites,
  loadingPrices,
}) => {
  if (!campsite) {
    return <></>;
  }

  // do not render if not part of enabled types
  if (campsite.type) {
    const pinTypesArr = Array.isArray(router.query.types)
      ? router.query.types
      : [router.query.types];
    const pinTypes = pinTypesArr.map(Number);
    if (!pinTypes.includes(Number(campsite.type))) {
      return <></>;
    }
  }

  // Ensure we're ready to use the Google Maps JS Library
  const { scriptReady } = useGoogle();

  // do not render if pin is not in current map
  if (campsite.location && scriptReady) {
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend({ lat: parseFloat(router.query.ne_lat), lng: parseFloat(router.query.sw_lng) });
    bounds.extend({ lat: parseFloat(router.query.sw_lat), lng: parseFloat(router.query.ne_lng) });

    const campsitePin = new google.maps.LatLng(campsite.location.lat, campsite.location.lon);
    if (!bounds.contains(campsitePin)) {
      return <></>;
    }
  }

  // mimic merge of data in CampsiteListAndPriceQuery
  const mergedCampsiteAndPrice = mergeListingAndPrices(
    [campsite],
    [...pricesData?.campsitePrice?.data || []],
    eventType,
    isOverseas,
  ).pop();

  // format campsite for display in ListingResult
  mergedCampsiteAndPrice.gallery = mergedCampsiteAndPrice?.events?.length > 0
    ? getCampsiteFeatureByTag(
      mergedCampsiteAndPrice.events[0].features,
      FEATURE_TYPES.GALLERY,
      false,
    ).map((f) => ({
      url: f.value,
      alternativeFormats: f.alternativeImages,
    })) : [];

  // limited to 7 to avoid clipping
  mergedCampsiteAndPrice.topFeatures = getCampsiteFeatureByTag(
    mergedCampsiteAndPrice.features,
    FEATURE_TYPES.TOP_TEN,
  ).slice(0, 7);

  const defaultEvent = getDefaultPlaceEvent(
    { events: mergedCampsiteAndPrice.placeEvent },
    eventType,
    isOverseas,
  );

  return (
    <ListingItem
      {...mergedCampsiteAndPrice}
      activePin={activePin}
      eventType={eventType}
      isOpen={checkOpen(defaultEvent?.openDates, start, end)}
      isTours={isTours}
      isUkItinerary={isUkItinerary}
      loadingCampsites={loadingCampsites}
      loadingPrices={loadingPrice || loadingPrices}
      memberPrice={mergedCampsiteAndPrice.memberPrice ?? 0}
      onLoadImage={onLoadImage}
      onResultClick={onResultClick}
    />
  );
};

SpecialListingItem.propTypes = {
  router: PropTypes.shape(IbePropTypes.router).isRequired,
  campsite: PropTypes.shape(IbePropTypes.campsite).isRequired,
  pricesData: PropTypes.shape({
    networkStatus: PropTypes.number,
    campsitePrice: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.price)),
    })]),
  }),
  loadingPrice: PropTypes.bool,
  eventType: PropTypes.number,
  isOverseas: PropTypes.bool,
  activePin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isTours: PropTypes.bool,
  start: PropTypes.string,
  end: PropTypes.string,
  isUkItinerary: PropTypes.bool,
  onLoadImage: PropTypes.func,
  onResultClick: PropTypes.func.isRequired,
  loadingCampsites: PropTypes.bool,
  loadingPrices: PropTypes.bool,
};

SpecialListingItem.defaultProps = {
  pricesData: {
    networkStatus: 0,
    campsitePrice: 0,
  },
  loadingPrice: false,
  eventType: PLACE_EVENT_TYPES.TOURING.id,
  isOverseas: false,
  isTours: false,
  start: null,
  end: null,
  isUkItinerary: false,
  onLoadImage: () => {},
  loadingCampsites: false,
  loadingPrices: false,
};

export default compose(
  withRouter,
  queryHelper(GET_CONFIGURATION),
  queryHelper(GET_CAMPSITE, getCampsiteOptions),
  graphql(GET_CAMPSITE_PRICE, {
    name: 'pricesData',
    props: (data) => ({
      loadingPrice: data.loading,
      ...data,
    }),
    options: campsitePriceOptions,
    skip: ({ isTours, campsiteId }) => {
      // skip as may be set to a tour id
      const isNotGenuineCampsiteId = !Number.isNaN(Number(campsiteId)) && campsiteId !== undefined;
      return !!isTours || isNotGenuineCampsiteId;
    },
  }),
)(SpecialListingItem);
