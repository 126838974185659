import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';

import { Marker, OverlayView, MarkerClusterer } from '@react-google-maps/api';

import { useRouter } from 'next/router';
import createCampsiteMarker from '../../lib/createCampsiteMarker';
import createCluster from '../../lib/createCluster';
import formatPrice from '../../lib/formatPrice';
import detectIE from '../../lib/detectIE';
import IbePropTypes from '../../IbePropTypes';
import { checkOpen } from '../../lib/helpers/availability';

import asyncComponent from '../../hocs/asyncComponent';
import TourMapMarkers from './TourMapMarkers';

import theme from '../../styles/config/theme';
import { ids as campsiteTypes } from '../../config/campsiteTypes';
import { toInt } from '../Search/ClusteringPanel';
import CAMPSITE_STATUS from '../../constants/campsiteStatus';
import { MARKER_Z_INDEX } from './constants';
import { MAX_SITES_VISIBLE_FOR_PRICES } from '../../lib/constants';

const clusterSizes = [
  42,
  48,
  54,
  60,
  66,
];

const AsyncInfoWindow = asyncComponent(() => import('./InfoWindow'));

function CampsiteMapMarkers(props) {
  const router = useRouter();
  const {
    activePin,
    campsiteId,
    count,
    loading,
    goToTop,
    isMobile,
    pois,
    results,
    start,
    end,
    selectedEvent,
    isTours,
    ukItinerary,
  } = props;

  const gridSize = toInt(sessionStorage.getItem('gridSize'), 50);
  const minClusterSize = toInt(sessionStorage.getItem('minimumClusterSize'), 8);

  const pricesVisible = count <= MAX_SITES_VISIBLE_FOR_PRICES;

  const clustererRef = useRef();
  const poiClustererRef = useRef();

  useEffect(() => {
    if (clustererRef && clustererRef.current) {
      clustererRef.current.repaint();
    }
  }, [results.length]);

  useEffect(() => {
    if (poiClustererRef && poiClustererRef.current) {
      poiClustererRef.current.repaint();
    }
  }, [pois.length]);

  return (
    <>
      {!ukItinerary &&
        <MarkerClusterer
          enableRetinaIcons
          gridSize={gridSize}
          minimumClusterSize={minClusterSize}
          styles={clusterSizes.map(size => ({
            textColor: 'white',
            height: size,
            url: createCluster(size, theme.COLOR_GRANITE_GRAY),
            width: size,
          }))}
          key="poi-markers-clusterer"
          onLoad={clusterer => { poiClustererRef.current = clusterer; }}
        >
          {cluster => pois.map((placeOfInterest) => (
            <Marker
              icon={{
                url: placeOfInterest.features?.[0]?.iconUrl,
                scaledSize: { width: 36, height: 36 },
              }}
              key={placeOfInterest.id}
              noClustererRedraw
              onClick={() => props.onMarkerClick(placeOfInterest)}
              position={{
                lat: placeOfInterest.lat,
                lng: placeOfInterest.lon,
              }}
              clusterer={cluster}
              zIndex={pricesVisible
                ? MARKER_Z_INDEX.CAMPSITE_MARKER
                : MARKER_Z_INDEX.CAMPSITE_MARKER_NO_PRICE
              }
              options={{
                optimized: false,
              }}
            >
              {!isMobile && activePin === placeOfInterest.id && (
              <OverlayView
                position={{
                  lat: placeOfInterest.lat,
                  lng: placeOfInterest.lon,
                }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <AsyncInfoWindow
                  campsiteId={placeOfInterest.id}
                  campsiteName={placeOfInterest.name}
                  goToTop={goToTop}
                  hasOffers={false}
                  isPoi
                  lat={placeOfInterest.lat}
                  lng={placeOfInterest.lon}
                  onCampsiteSearch={props.onCampsiteSearch}
                  onMoreDetailsClick={props.onMoreDetailsClick}
                  onQueryChange={props.onQueryChange}
                  selectedCampsiteId={campsiteId}
                  type={placeOfInterest.type}
                  zoomLevel={placeOfInterest.zoomLevel}
                  router={router}
                />
              </OverlayView>
              )}
            </Marker>
          ))}
        </MarkerClusterer>}
      {!isTours ? (
        <MarkerClusterer
          enableRetinaIcons
          gridSize={gridSize}
          minimumClusterSize={minClusterSize}
          styles={clusterSizes.map(size => ({
            textColor: 'white',
            height: size,
            url: createCluster(size),
            width: size,
          }))}
          key="campsite-markers-clusterer"
          onLoad={clusterer => { clustererRef.current = clusterer; }}
        >
          {cluster => results.map((campsite) => {
            const {
              cheapestMemberPrice,
              cheapestStandardPrice,
              offers,
              openDates,
            } = (campsite?.defaultEvent) ?? {};

            const active = [campsiteId, activePin].includes(campsite.id);
            let text = '';
            let length = 0;
            const isIE = detectIE();

            if (pricesVisible) {
              const poundHtmlEntity = '&#163;'; // Required for SVG
              const isOpen = checkOpen(openDates, start, end);
              const isCL = [
                campsiteTypes.UK_AFFILIATED_SITES,
                campsiteTypes.CERTIFICATED_LOCATIONS,
              ].includes(campsite.type);
              const defaultOpenText = isCL ? 'VIEW' : '...';
              const isFull = !isCL && !loading && cheapestMemberPrice === 0;
              text = isOpen ? (cheapestMemberPrice || (isFull ? CAMPSITE_STATUS.FULL : defaultOpenText)) : `${CAMPSITE_STATUS.CLOSED} `;
              length = text.length;

              if (cheapestMemberPrice && isOpen) {
                text = formatPrice(cheapestMemberPrice, poundHtmlEntity);
                length = `£${Number(cheapestMemberPrice).toFixed(2)}`.length;
              }
            }

            return (
              <Marker
                icon={createCampsiteMarker({
                  active,
                  color: theme[`COLOR_CAMPSITE_TYPE_${campsite.type}`],
                  loading: !cheapestMemberPrice && pricesVisible && loading,
                  length,
                  text,
                  isIE,
                })}
                key={campsite.id}
                noClustererRedraw
                onClick={() => props.onMarkerClick(campsite)}
                position={{
                  lat: campsite.lat,
                  lng: campsite.lon,
                }}
                title={typeof campsite.name === 'string' ? campsite.name : ''}
                clusterer={cluster}
                zIndex={pricesVisible
                  ? MARKER_Z_INDEX.CAMPSITE_MARKER
                  : MARKER_Z_INDEX.CAMPSITE_MARKER_NO_PRICE
                }
                options={{
                  optimized: false,
                }}
              >
                {!isMobile && activePin === campsite.id && (
                <OverlayView
                  position={{
                    lat: campsite.lat,
                    lng: campsite.lon,
                  }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <AsyncInfoWindow
                    campsiteId={campsite.id}
                    campsiteName={campsite.name}
                    goToTop={goToTop}
                    hasOffers={offers ? !!offers.length : false}
                    isPoi={false}
                    lat={campsite.lat}
                    lng={campsite.lon}
                    memberPrice={cheapestMemberPrice}
                    selectedEvent={selectedEvent}
                    onCampsiteSearch={props.onCampsiteSearch}
                    onMoreDetailsClick={props.onMoreDetailsClick}
                    onQueryChange={props.onQueryChange}
                    standardPrice={cheapestStandardPrice || cheapestMemberPrice}
                    router={router}
                    selectedCampsiteId={campsiteId}
                    type={campsite.type}
                    zoomLevel={campsite.zoomLevel}
                  />
                </OverlayView>
                )}
              </Marker>
            );
          })}
        </MarkerClusterer>) :
        <TourMapMarkers {...props} />}
    </>
  );
}

CampsiteMapMarkers.propTypes = {
  activePin: PropTypes.string,
  campsiteId: PropTypes.string,
  count: PropTypes.number,
  goToTop: PropTypes.func,
  isMobile: PropTypes.bool,
  loading: PropTypes.bool,
  onCampsiteSearch: PropTypes.func.isRequired,
  onMarkerClick: PropTypes.func.isRequired,
  onMoreDetailsClick: PropTypes.func.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  pois: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.poi)),
  results: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    defaultEvent: PropTypes.shape(IbePropTypes.event),
    type: PropTypes.number,
    lat: PropTypes.number,
    lng: PropTypes.number,
    name: PropTypes.string,
    zoomLevel: PropTypes.number,
  })),
  start: PropTypes.string,
  end: PropTypes.string,
  selectedEvent: PropTypes.shape(IbePropTypes.event),
  defaultEvent: PropTypes.shape(IbePropTypes.event),
  tours: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.tour)),
  isTours: PropTypes.bool,
  client: PropTypes.shape(IbePropTypes.client),
  ukItinerary: PropTypes.string,
};

CampsiteMapMarkers.defaultProps = {
  activePin: '',
  campsiteId: '',
  count: null,
  goToTop() { },
  isMobile: false,
  loading: false,
  results: [],
  pois: [],
  tours: [],
  isTours: false,
  start: null,
  end: null,
  selectedEvent: null,
  defaultEvent: null,
  client: null,
  ukItinerary: '',
};

export default memo(withApollo(CampsiteMapMarkers));
