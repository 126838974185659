import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'next/router';
import { stringify } from 'query-string';

import SearchOffline from './SearchOffline';
import CampsiteSearchError from './CampsiteSearchError';

import { MessageWarning } from '../ui/Message';

import CampsitesDatePickerWrapper from '../SearchForm/Campsites/CampsitesDatePickerWrapper';
import CampsitesRadioGroupWrapper from '../SearchForm/Campsites/CampsitesRadioGroupWrapper';
import CampsitesSearchInputWrapper from '../SearchForm/Campsites/CampsitesSearchInputWrapper';

import ToursInputs from '../SearchForm/Tours/ToursInputs';

import {
  FormGroup,
  FormGroupItem,
} from '../SearchForm/SearchForm.style';

import { Form } from './CampsiteSearchForm.style';

import GET_CAMPSITE_NAMES from './graphql/getCampsiteNames.gql';
import GET_CONFIGURATION from '../../config/graphql/getConfiguration';
import GET_CAMPSITE, { getCampsiteOptions } from '../../config/graphql/getCampsite';

import CampsitesButtonsWrapper from '../SearchForm/Campsites/CampsitesButtonsWrapper';

import ErrorTypeMessage from '../ErrorTypeMessage';
import { validateDates } from '../../lib/validation/campsiteSearchForm';
import { getBookableDaysFromToday, parseTypes } from '../../lib/campsiteTypes';
import queryHelper from '../../lib/queryHelper';
import { ids as campsiteTypes } from '../../config/campsiteTypes';
import PLACE_EVENT_TYPES, { SINGLE_EVENT_TYPE } from '../../config/eventTypes';
import IbePropTypes from '../../IbePropTypes';
import { FEATURE_FLAGS, getFeatureFlag } from '../../config/featureFlags';
import IncludeCertificatedLocationsToggle from './IncludeCertificatedLocationsToggle';
import { USER_LOCATION_PERMISSIONS } from '../../lib/location';

class CampsiteSearchForm extends PureComponent {
  static propTypes = {
    campsite: PropTypes.shape({
      type: PropTypes.number,
    }),
    campsites: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.shape(IbePropTypes.error),
      campsiteNames: PropTypes.arrayOf(PropTypes.shape()),
    }),
    data: PropTypes.shape({
      configuration: PropTypes.shape(IbePropTypes.configuration),
      loading: PropTypes.bool,
      error: PropTypes.shape(IbePropTypes.error),
    }),
    duration: PropTypes.string,
    error: PropTypes.shape(IbePropTypes.error),
    isTours: PropTypes.bool,
    searchError: PropTypes.string,
    handleDateClear: PropTypes.func.isRequired,
    handleDateChange: PropTypes.func.isRequired,
    handleDateChangeByInput: PropTypes.func.isRequired,
    handleFiltersClick: PropTypes.func.isRequired,
    handleKeyDownDate: PropTypes.func.isRequired,
    handleLocationChange: PropTypes.func.isRequired,
    handleLocationSelect: PropTypes.func.isRequired,
    handleSearchByToggle: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleTypeaheadChange: PropTypes.func.isRequired,
    handleDepartureMonthSelect: PropTypes.func.isRequired,
    handleDurationSelect: PropTypes.func.isRequired,
    query: PropTypes.shape(IbePropTypes.query).isRequired,
    searchBy: PropTypes.string.isRequired,
    departureMonth: PropTypes.string,
    size: PropTypes.string,
    onQueryChange: PropTypes.func.isRequired,
    handleMyLocationSelect: PropTypes.func,
    locationPermission: PropTypes.string,
  };

  static defaultProps = {
    campsite: undefined,
    campsites: {
      loading: true,
      campsiteNames: [],
      error: false,
    },
    departureMonth: '',
    duration: '',
    data: {
      loading: false,
      error: null,
      configuration: {
        availabilityMaxDate: '',
      },
    },
    error: null,
    isTours: false,
    size: 'medium',
    searchError: undefined,
    handleMyLocationSelect: () => {},
    locationPermission: USER_LOCATION_PERMISSIONS.NOT_KNOWN,
  };

  state = {
    dateErrors: [],
    queryErrors: [],
  }

  // This allows memo to work in CampsitesButtonsWrapper.
  handleSubmit = (e) => {
    e.preventDefault();

    const { campsite, data, query } = this.props;
    const bookableDaysFromToday =
      getBookableDaysFromToday(
        campsite?.type,
        data.configuration.products,
        query.isOverseas === 'true',
      );
    const errors = validateDates(
      this.props.query.start,
      this.props.query.end,
      this.props.data.configuration.availabilityMaxDate,
      bookableDaysFromToday,
    );

    if (errors.length) {
      this.setState({
        queryErrors: errors,
        dateErrors: [],
      });
      return;
    }

    this.props.handleSubmit();
  }

  onError = (errors) => {
    this.setState({
      dateErrors: errors,
    });
  }

  handleDateChange = (value) => {
    this.setState({
      queryErrors: [],
      dateErrors: [],
    }, () => this.props.handleDateChange(value));
  }

  handleDateChangeByInput = (name, value) => {
    this.setState({
      queryErrors: [],
      dateErrors: [],
    }, () => this.props.handleDateChangeByInput(name, value));
  }

  render() {
    const campsiteNamesEmpty = !this.props.campsites.campsiteNames?.length;
    const dataLoading = this.props.data.loading;
    const loading = dataLoading || this.props.campsites.loading;

    // Search input is disabled when loading, so there's no need to hide the entire component
    if (campsiteNamesEmpty || dataLoading) return null;

    if (this.props.data.error) {
      return (
        <MessageWarning>
          {this.props.data.error.message}
        </MessageWarning>
      );
    }

    if (this.props.campsites.error) {
      return (
        <MessageWarning>
          {this.props.campsites.error.message}
        </MessageWarning>
      );
    }

    const {
      campsite,
      campsites,
      data,
      departureMonth,
      duration,
      error,
      searchError,
      handleDateClear,
      handleDepartureMonthSelect,
      handleDurationSelect,
      handleLocationChange,
      handleLocationSelect,
      handleSearchByToggle,
      handleTypeaheadChange,
      handleMyLocationSelect,
      query,
      searchBy,
      size,
      isTours,
      locationPermission,
    } = this.props;

    const isOffline = !navigator.onLine;
    const isOverseas = query.isOverseas === 'true';
    const bookableDaysFromToday =
      getBookableDaysFromToday(
        campsite?.type,
        data.configuration.products,
        isOverseas,
      );

    const eventType = Number(query.eventType || PLACE_EVENT_TYPES.TOURING.id);
    const filterCampsiteNames = campsites.campsiteNames.filter(
      campsiteName => campsiteName.eventTypes?.includes(eventType) ||
        (campsiteName.eventTypes.includes(SINGLE_EVENT_TYPE.id)),
    );

    const isBookingWidget = query.bookingWidget === 'true';

    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <FormGroupItem>
            <CampsitesRadioGroupWrapper
              handleSearchByToggle={handleSearchByToggle}
              searchBy={searchBy}
              size={size}
              isTours={isTours}
            />

            <CampsitesSearchInputWrapper
              campsiteName={query.campsiteName}
              campsiteNames={(filterCampsiteNames?.length || !isOverseas) ?
                filterCampsiteNames : campsites.campsiteNames}
              excludeGeoLocationTypes={data.configuration?.excludeGeoLocationTypes}
              handleLocationChange={handleLocationChange}
              handleLocationSelect={handleLocationSelect}
              handleTypeaheadChange={handleTypeaheadChange}
              location={query.location}
              loading={loading}
              minLocationSearchRadiusMilesDecimal={
                data.configuration?.configurableValues?.minLocationSearchRadiusMilesDecimal
              }
              searchBy={searchBy}
              size={size}
              isTours={isTours}
              isOverseas={isOverseas}
              handleMyLocationSelect={handleMyLocationSelect}
              locationPermission={locationPermission}
              showUseMyLocation={!isBookingWidget}
            />

          </FormGroupItem>
        </FormGroup>

        {campsites.error &&
          <CampsiteSearchError text={campsites.error.responseText} />
        }

        {isTours ? (
          <ToursInputs
            handleDepartureMonthSelect={handleDepartureMonthSelect}
            handleDurationSelect={handleDurationSelect}
            departureMonth={departureMonth}
            duration={duration}
            durations={data?.configuration?.escortedToursDurationTypes ?? []}
          />) :
          <CampsitesDatePickerWrapper
            start={query.start}
            end={query.end}
            dateErrors={this.state.dateErrors}
            handleDateInput={this.handleDateInput}
            availabilityMaxDate={data.configuration.availabilityMaxDate}
            handleDateChange={this.handleDateChange}
            handleDateChangeByInput={this.handleDateChangeByInput}
            handleKeyDownDate={this.props.handleKeyDownDate}
            handleClear={handleDateClear}
            onError={this.onError}
            size={size}
            offsetDays={bookableDaysFromToday}
          />}

        {this.state.dateErrors.map(err => (
          <ErrorTypeMessage marginBottom dictionary={err.message} />
        ))}

        {!isBookingWidget &&
          <IncludeCertificatedLocationsToggle onQueryChange={this.props.onQueryChange} />
        }

        <CampsitesButtonsWrapper
          handleFiltersClick={this.props.handleFiltersClick}
          handleSubmit={this.handleSubmit}
          query={query}
          size={size}
          isTours={isTours}
        />

        {error &&
          <MessageWarning marginBottom>{error}</MessageWarning>
        }
        {searchError &&
          <MessageWarning dictionary={searchError} marginBottom />
        }

        {this.state.queryErrors.map(err => (
          <ErrorTypeMessage marginBottom dictionary={err.message} />
        ))}

        {isOffline &&
          <SearchOffline />
        }
      </Form>
    );
  }
}

export default compose(
  withRouter,
  queryHelper(GET_CAMPSITE, getCampsiteOptions),
  graphql(GET_CONFIGURATION),
  graphql(GET_CAMPSITE_NAMES, {
    name: 'campsites',
    options: ({ router, data }) => {
      const { configuration } = data ?? {};
      const isOverseas = router.query.isOverseas === 'true';
      let defaultTypes = isOverseas ?
        configuration?.overseasSitesTabDefaultSearchPlaceTypes :
        configuration?.ukSitesTabDefaultSearchPlaceTypes;
      if (!getFeatureFlag(FEATURE_FLAGS.UK_SITES)) {
        defaultTypes = [campsiteTypes.OVERSEAS_SITE];
      }
      return {
        variables: {
          pathBuilder: ({ args }) => `campsite/names?${stringify(args)}`,
          types: parseTypes(router.query.types, defaultTypes).filter(
            type => Number(type) !== campsiteTypes.POINT_OF_INTEREST,
          ),
          skip: !router.query.types?.length && !defaultTypes?.length,
        },
      };
    },
  }),
)(CampsiteSearchForm);
