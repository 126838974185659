import { util } from 'node-forge';

const createBase64 = svg => `data:image/svg+xml;charset=UTF-8;base64,${util.encode64(svg)}`;

export default function CreateCampsiteMarker(
  {
    length,
    text = '',
    color = '#808080',
    active = false,
    isIE,
    big = false,
  },
) {
  if (text) {
    const scale = 1.25;

    const charWidth = big ? 8 : 7;
    const paddingWidth = big ? 12 : 10;
    const width = Math.max(24, length * charWidth + (paddingWidth * 2));

    const cLength = 3;
    const strokeWidth = 2;

    const pointSize = big ? 5 : 4;

    const fontSize = big ? 16 : 14;
    const lineHeight = big ? 30 : 24;

    const dy = 2;
    const stdDeviation = 2;

    const viewHeight = lineHeight + (strokeWidth * 2) + pointSize + stdDeviation;
    const viewWidth = width + (strokeWidth * 2) + cLength + (stdDeviation * 2);

    let svgWidth = viewWidth;
    let svgHeight = viewHeight;

    if (active) {
      svgWidth *= scale;
      svgHeight *= scale;
    }

    return createBase64(`
      <svg
        width="${svgWidth}"
        height="${svgHeight}"
        viewBox="0 0 ${viewWidth} ${viewHeight}"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <filter id="shadow">
            <feDropShadow dy="${dy}" dx="0" stdDeviation="${stdDeviation}" flood-opacity="0.5" />
          </filter>
        </defs>

        <path d="
            m ${(strokeWidth * 2)} ${strokeWidth}
            h ${width - cLength}
            q ${cLength}, 0 ${cLength}, ${cLength}
            v ${lineHeight - (cLength * 2)}
            q 0, ${cLength} -${cLength}, ${cLength}
            h -${((width - (cLength * 2)) / 2) - pointSize}
            l -${pointSize} ${pointSize}
            l -${pointSize} -${pointSize}
            h -${((width - (cLength * 2)) / 2) - pointSize}
            q -${cLength}, 0 -${cLength}, -${cLength}
            v -${lineHeight - (cLength * 2)}
            q 0, -${cLength} ${cLength}, -${cLength}
          "
          stroke="${active ? 'white' : 'none'}"
          stroke-width="${strokeWidth}"
          fill="${color}"
          filter="${!isIE ? 'url(#shadow)' : ''}"
        />

        <text
          x="50%"
          dy="-${pointSize / 2}"
          y="${!isIE ? '50%' : '60%'}"
          alignment-baseline="middle"
          text-anchor="middle"
          font-family="Arial"
          font-size="${fontSize}"
          fill="white"
        >
          ${text}
        </text>
      </svg>
    `);
  }

  return createBase64(`
    <svg
      width="${active ? 25 : 18}"
      height="${active ? 25 : 18}"
      viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m12.7 0h-10.4a2.31 2.31 0 0 0 -2.3 2.3v8.33a2.31 2.31 0 0 0 2.3 2.3h3.19l1.74 2.45a.38.38 0 0 0 .54 0l1.74-2.45h3.19a2.31 2.31 0 0 0 2.3-2.3v-8.33a2.31 2.31 0 0 0 -2.3-2.3z" fill="${color}" />
    </svg>
  `);
}
