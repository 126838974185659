import React from 'react';
import PropTypes from 'prop-types';
import { size as getSize } from 'lodash';

import testingAttr from '../../lib/testingAttr';
import alwaysArray from '../../lib/alwaysArray';

import SearchButton, { Badge, Icon as SearchIcon } from './FiltersButton.style';

import svgFilter from '../../static/images/icons/Filter.svg';
import { queryType } from '../../IbePropTypes';

function FiltersButton({
  disabled,
  onClick,
  size,
  query,
}) {
  const features = alwaysArray(query.features);

  const length = getSize(features);

  return (
    <SearchButton
      disabled={disabled}
      align="center"
      block
      onClick={onClick}
      size={size}
      type="button"
      {...testingAttr('filters__filter-btn')}
    >
      <SearchIcon icon={svgFilter} />
      <span>Filter</span>
      <span>
        {!!length && <Badge>{length}</Badge>}
      </span>
    </SearchButton>
  );
}

FiltersButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  query: PropTypes.shape(queryType),
  size: PropTypes.string,
};

FiltersButton.defaultProps = {
  disabled: false,
  query: {
    feature: '',
    types: '',
  },
  size: 'medium',
};

export default FiltersButton;
