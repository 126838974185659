import styled, { css } from 'styled-components';

import Message from '../ui/Message';

export default styled.div``;

export const CampsitePriceWrapper = styled.div`
  display: flex;
  margin: 0 -0.25rem;
`;

export const CampsitePrice = styled.div`
  border-radius: 3px;
  margin: 0 0.25rem;
  padding: 0.5rem;
  flex: 1;
`;

export const ToursPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
`;

export const Price = styled.div`
  font-size: 0.9375rem;
  line-height: 1.125rem;
  padding-top: 0.125rem;

  ${({ inline }) => inline && css`
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    font-weight: bold;
  `}
`;

export const CampsiteMembershipPrice = styled(CampsitePrice)`
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHTER};
`;

export const Closed = styled(Message)`
  background-color: ${({ theme }) => theme.COLOR_WARNING};
  color: ${({ theme }) => theme.COLOR_WHITE};
`;

export const Error = styled(Message)`
  background-color: ${({ theme }) => theme.COLOR_DANGER};
  color: ${({ theme }) => theme.COLOR_WHITE};
`;

export const Full = styled(Message)`
  background-color: ${({ theme }) => theme.COLOR_INFO};
  color: ${({ theme }) => theme.COLOR_WHITE};
`;
