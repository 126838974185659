import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import { LoadingBlockButton, LoadingBlock } from '../ui/Loading';
import Note from '../Note';

// Styles
import {
  CampsiteHeader,
  CampsiteImg,
  CampsiteTitle,
  CampsiteContent,
  Footer,
} from './InfoWindow.style';

import {
  CampsitePriceWrapper,
  CampsiteMembershipPrice,
  CampsitePrice,
  Price,
} from './InfoWindowPrices.style';

const propTypes = {
  theme: PropTypes.shape(),
};

const defaultProps = {
  theme: {},
};

function InfoWindowLoading({ theme }) {
  return (
    <Fragment>
      <CampsiteHeader>
        <CampsiteImg />
        <CampsiteTitle>
          <LoadingBlock />
        </CampsiteTitle>
      </CampsiteHeader>

      <CampsiteContent>
        <CampsitePriceWrapper>
          <CampsiteMembershipPrice>
            <LoadingBlock />
            <Price>
              <LoadingBlock />
            </Price>
          </CampsiteMembershipPrice>
          <CampsitePrice>
            <LoadingBlock />
            <Price>
              <LoadingBlock />
            </Price>
          </CampsitePrice>
        </CampsitePriceWrapper>
      </CampsiteContent>

      <Footer>
        <Note backgroundColor={theme.COLOR_GRAY_LIGHTER} />
        <LoadingBlockButton
          block
          size="small"
          style={{
            marginTop: '1rem',
          }}
        />
      </Footer>
    </Fragment>
  );
}

InfoWindowLoading.propTypes = propTypes;
InfoWindowLoading.defaultProps = defaultProps;

export default withTheme(InfoWindowLoading);
