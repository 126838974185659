import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../ui/Icon';

import { Close } from './SiteCard.style';

import svgClose from '../../static/images/icons/Close.svg';

const propTypes = {
  handleClick: PropTypes.func.isRequired,
};

function SiteCardBack({ handleClick }) {
  return (
    <Close
      align="left"
      type="button"
      onClick={handleClick}
    >
      <Icon
        icon={svgClose}
        style={{ marginRight: '1rem' }}
      />
      <span>Close</span>
    </Close>
  );
}

SiteCardBack.propTypes = propTypes;

export default SiteCardBack;
