import React from 'react';
import PropTypes from 'prop-types';
import testingAttr from '../../../lib/testingAttr';

import { FiltersButton } from '../../Filters';

import { ButtonIcon as SearchIcon } from '../../ui/Button';

import svgSearch from '../../../static/images/icons/Search.svg';

import {
  FormGroup,
  FormGroupItem,
} from '../SearchForm.style';
import { SearchButton } from './CampsitesButtonsWrapper.style';

function CampsitesButtonsWrapper({
  disabled,
  handleFiltersClick,
  handleSubmit,
  query,
  size,
  isTours,
}) {
  return (
    <FormGroup>
      {!isTours &&
      <FormGroupItem flex={1}>
        <FiltersButton
          ariaLabel="Filter"
          onClick={handleFiltersClick}
          query={query}
          size={size}
        />
      </FormGroupItem>}
      <FormGroupItem flex={2}>
        <SearchButton
          ariaLabel="Search"
          type="button"
          block
          onClick={handleSubmit}
          size={size}
          disabled={disabled}
          {...testingAttr('search__search-btn')}
        >
          <SearchIcon icon={svgSearch} />
          <span>Search</span>
        </SearchButton>
      </FormGroupItem>
    </FormGroup>
  );
}

CampsitesButtonsWrapper.propTypes = {
  disabled: PropTypes.bool,
  handleFiltersClick: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  query: PropTypes.shape({}).isRequired,
  size: PropTypes.string,
  isTours: PropTypes.bool,
};

CampsitesButtonsWrapper.defaultProps = {
  disabled: false,
  size: 'medium',
  isTours: false,
};

export default CampsitesButtonsWrapper;
