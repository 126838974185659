import styled from 'styled-components';
import { below } from '../../styles/libs';
import zIndex from '../../config/zIndex';

export default styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: ${zIndex.MODAL};
  max-width: 175px;
  padding: 1rem;
  background: white;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  text-align: center;

  ${({ theme }) => below(theme.TABLET)`
    position: fixed;
    top: 40%;
    right: 0%;
    transform: translate(0, -50%);
  `}
`;

export const Label = styled.label``;

export const FormGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1rem;
`;

export const ReloadButton = styled.button`
  width: 100%;
  padding: 0.5rem 1rem;
  border: white;
  color: white;
  background: #4b938e;
  cursor: pointer;
`;

export const ErrorMessage = styled.div`
  color: red;
`;

export const Input = styled.input`
  width: 75px;
  border: 1px solid grey;
  text-align: center;
`;
