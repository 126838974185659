import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { withTheme } from 'styled-components';

import { dictionaryItem } from '../../../hocs/withDictionary';

import testingAttr from '../../../lib/testingAttr';

import Radio from '../../ui/Form/Radio';
import {
  RadioGroup,
  RadioGroupItem,
} from './CampsitesRadioGroupWrapper.style';

import {
  Label,
} from '../SearchForm.style';
import { searchByType } from '../../../constants/search';

function CampsitesRadioGroupWrapper({
  theme, searchBy, handleSearchByToggle, isTours,
}) {
  return (
    <RadioGroup>
      <RadioGroupItem>
        <Label
          dictionary={dictionaryItem('CampsiteSearchForm', isTours ? 'Location' : 'SearchBy')}
          noPadding={isTours}
        />
      </RadioGroupItem>
      {!isTours &&
      <RadioGroupItem>
        <Radio
          color={theme.COLOR_WHITE}
          checked={searchBy === searchByType.CAMPSITE}
          label="Campsite"
          name="searchBy"
          onChange={handleSearchByToggle}
          id="search-by-site"
          value="campsite"
          {...testingAttr('search__campsite-radio')}
        />
      </RadioGroupItem>}
      {!isTours &&
      <RadioGroupItem>
        <Radio
          color={theme.COLOR_WHITE}
          checked={searchBy === searchByType.LOCATION}
          label="Location"
          name="searchBy"
          onChange={handleSearchByToggle}
          id="search-by-location"
          value="location"
          {...testingAttr('search__location-radio')}
        />
      </RadioGroupItem>}
    </RadioGroup>
  );
}

CampsitesRadioGroupWrapper.propTypes = {
  theme: PropTypes.shape({
    COLOR_WHITE: PropTypes.string,
  }),
  handleSearchByToggle: PropTypes.func.isRequired,
  searchBy: PropTypes.string,
  isTours: PropTypes.bool,
};

CampsitesRadioGroupWrapper.defaultProps = {
  theme: {
    COLOR_WHITE: '',
  },
  searchBy: '',
  isTours: false,
};

export default compose(
  memo,
  withTheme,
)(CampsitesRadioGroupWrapper);
