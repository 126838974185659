import styled, { css } from 'styled-components';

import InputComponent from '../../ui/Form/Input';
import SelectComponent from '../../ui/Form/Select';
import LabelComponent from '../../ui/Form/Label';
import TitleComponent from '../../ui/Title';

export const cssInput = css`
  justify-content: center;
  width: 100%;
  min-height: 3.125rem;
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border: 0;
  border-radius: 0.25rem;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 -0.375rem;
  margin-bottom: 1rem;
`;

export const FormGroupItem = styled.div`
  flex: 1;
  min-width: 0;
  margin-bottom: 1.25rem;
  padding: 0 0.375rem;
`;

export const Input = styled(InputComponent)`
  ${cssInput}
`;

export const Select = styled(SelectComponent)`
  ${cssInput}
`;

export const Title = styled(TitleComponent)`
  color: ${({ theme }) => theme.COLOR_WHITE};
`;

export const Label = styled(LabelComponent)`
  padding: 0 0 0.5rem;
  color: ${({ theme }) => theme.COLOR_WHITE};
`;
