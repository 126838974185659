import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ButtonLink from '../Button/ButtonLink';

class ReadMore extends Component {
  static defaultProps = {
    limit: 120,
    text: '',
  };

  static propTypes = {
    limit: PropTypes.number,
    text: PropTypes.string,
  };

  constructor() {
    super();

    this.state = {
      expand: false,
    };

    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle() {
    this.setState({ expand: !this.state.expand });
  }

  render() {
    const { expand } = this.state;
    const { limit, text } = this.props;

    if (!text || text.length <= limit) return null;

    // Get last char index
    const lc = /[\d\w]\b/.exec(text.slice(limit));
    const lci = lc ? limit + lc.index + 1 : limit;

    function Toggle({ onClick }) {
      return (
        <ButtonLink onClick={onClick}>Read {expand ? 'less' : 'more'}</ButtonLink>
      );
    }

    const limitedText = expand ?
      this.props.text :
      this.props.text.slice(0, lci).trim();

    return (
      <p style={{ fontSize: '0.9375rem' }}>{limitedText}{!expand && '...'} <Toggle onClick={this.handleToggle} /></p>
    );
  }
}

export default ReadMore;
