import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withApollo, compose, graphql } from 'react-apollo';
import moment from 'moment';

import formatDate, { formatToHyphenFormat } from '../../lib/format';
import { isDesktopOrSmaller } from '../../lib/helpers/layout';

import FiltersButton from '../Filters/FiltersButton';
import SearchButton from './SearchButton';
import SearchSummary from '../SearchSummary';

import { dictionaryItem, getDictionaryItem } from '../../hocs/withDictionary';

import { DATE_FORMAT_DISPLAY } from '../../config/locale';
import GET_CONFIGURATION from '../../config/graphql/getConfiguration';

import {
  ActionsRow, ActionsCol, SearchFormWrapper, Summary, SearchText,
} from './CampsiteSearchSummary.style';

import {
  Col, Row, Text, Title,
} from '../SearchSummary/SearchSummary.style';
import IbePropTypes from '../../IbePropTypes';
import { MY_LOCATION_SEARCH_KEY } from '../../constants/search';

function CampsiteSearchSummary({
  handleChangeSearch,
  handleFiltersClick,
  onHeightChange,
  isMobileMapOpen,
  query,
  renderSearchForm,
  isTours,
  client,
  data,
}) {
  const arrive = formatDate(new Date(formatToHyphenFormat(query.start)), DATE_FORMAT_DISPLAY);
  const depart = formatDate(new Date(formatToHyphenFormat(query.end)), DATE_FORMAT_DISPLAY);

  // Persist search form on desktop when there is no location in search query
  const persistForm = !isDesktopOrSmaller() && !query.location && !isTours && !query.ukItinerary;
  const locationLabel = getDictionaryItem(client, 'CampsiteSearchForm__Location__Label');
  const departureMonthLabel = getDictionaryItem(client, 'CampsiteSearchForm__DepartureMonth__Label');
  const durationLabel = getDictionaryItem(client, 'CampsiteSearchForm__Duration__Label');
  const showFirstColumn = !query.ukItinerary && (query.location || (
    (query.departureMonth || query.duration) && isTours));
  const isOverseas = query.isOverseas === 'true';
  const durations = data?.configuration?.escortedToursDurationTypes ?? [];
  const durationValue = durations
    .find((durationOption) => durationOption.key === Number(query.duration))?.value;
  // Use client dictionary item for location if its a 'my-location' search
  const location = (!!query.location && query.location === MY_LOCATION_SEARCH_KEY)
    ? getDictionaryItem(client, 'CampsiteSearch__MyLocation__Text') : query.location;

  return (
    <SearchSummary
      defaultMinimize={isMobileMapOpen && isDesktopOrSmaller()}
      onHeightChange={onHeightChange}
      renderSummary={
        !persistForm ?
          (
            <Summary>
              <Title dictionary={dictionaryItem('SearchSummary')} tag={2} size={4} />
              <Row>
                {showFirstColumn &&
                  <Col>
                    {!!location &&
                      <Text block>{locationLabel}: <b>{location}</b></Text>}
                    {!!query.departureMonth &&
                      <Text block>{departureMonthLabel}: <b>{moment(query.departureMonth, 'M').format('MMMM')}</b></Text>}
                    {!!query.duration && <Text block>{durationLabel}: <b>{durationValue}</b></Text>}
                  </Col>
                }
                {(query.start && query.end && !isTours) &&
                  <Col>
                    <Text>
                      Arrive: <b>{arrive}</b>
                    </Text>
                    <Text>
                      Depart: <b>{depart}</b>
                    </Text>
                  </Col>
                }
              </Row>
              {!isTours &&
              <SearchText
                dictionary={dictionaryItem(
                  'CampsiteSearchSummary',
                  'PostSearch',
                  isOverseas ? 'BasedOnOverseas' : 'BasedOnUk',
                )}
                marginTop
                marginBottom
              />}
            </Summary>
          ) : (
            <SearchFormWrapper>
              {renderSearchForm()}
              <SearchText
                dictionary={dictionaryItem(
                  'CampsiteSearchSummary',
                  'PostSearch',
                  isOverseas ? 'BasedOnOverseas' : 'BasedOnUk',
                )}
              />
            </SearchFormWrapper>
          )
      }
      renderActions={!persistForm && (
        <ActionsRow>
          {!isTours &&
          <ActionsCol>
            <FiltersButton
              onClick={handleFiltersClick}
              query={{
                features: query.features,
                types: query.types,
              }}
            />
          </ActionsCol>}
          <ActionsCol>
            <SearchButton onClick={handleChangeSearch} />
          </ActionsCol>
        </ActionsRow>
      )}
      sticky={!isMobileMapOpen}
    />
  );
}

CampsiteSearchSummary.propTypes = {
  client: PropTypes.shape(IbePropTypes.client).isRequired,
  handleChangeSearch: PropTypes.func.isRequired,
  handleFiltersClick: PropTypes.func.isRequired,
  isMobileMapOpen: PropTypes.bool.isRequired,
  onHeightChange: PropTypes.func,
  query: PropTypes.shape(IbePropTypes.query).isRequired,
  renderSearchForm: PropTypes.func.isRequired,
  isTours: PropTypes.bool,
  data: PropTypes.shape({
    configuration: PropTypes.shape(IbePropTypes.configuration),
  }),
};

CampsiteSearchSummary.defaultProps = {
  onHeightChange: () => {},
  isTours: false,
  data: null,
};

export default compose(
  withApollo,
  graphql(GET_CONFIGURATION),
)(memo(CampsiteSearchSummary));
