import gql from 'graphql-tag';
import { stringify } from 'query-string';

import connectionType from '../../../lib/connectionType';

/* eslint-disable camelcase */
function shouldSkip({
  ne_lat,
  ne_lng,
  sw_lat,
  sw_lng,
}) {
  return !ne_lat || !ne_lng || !sw_lat || !sw_lng;
}
/* eslint-enable camelcase */

export function getAggregationsVariables(query) {
  function pathBuilder({ args }) {
    return `campsite/search?${stringify(args)}`;
  }

  const { ukItinerary } = query;

  return {
    pathBuilder,
    ukItinerary,
    topLeftLat: ukItinerary ? '' : query.ne_lat,
    topLeftLon: ukItinerary ? '' : query.sw_lng,
    bottomRightLat: ukItinerary ? '' : query.sw_lat,
    bottomRightLon: ukItinerary ? '' : query.ne_lng,
    features: query.features,
    types: query.types,
    page: 0,
    pageSize: connectionType(),
    skip: shouldSkip(query),
  };
}

export default gql`
  query Filters(
    $pathBuilder: any,
    $topLeftLat: Float!,
    $topLeftLon: Float!,
    $bottomRightLat: Float!,
    $bottomRightLon: Float!,
    $features: [Float],
    $types: [Float],
    $page: Int!,
    $pageSize: Int!,
    $ukItinerary: String,
    $skip: Boolean
  ) {
    results: campsiteListing(
      topLeftLat: $topLeftLat,
      topLeftLon: $topLeftLon,
      bottomRightLat: $bottomRightLat,
      bottomRightLon: $bottomRightLon,
      features: $features,
      types: $types
      page: $page,
      pageSize: $pageSize,
      suggestedUkItineraryId: $ukItinerary,
    ) @rest(
      type: "Aggregations",
      pathBuilder: $pathBuilder
    ) @skip(if: $skip) {
      aggregations
      count
    }
  }
`;
