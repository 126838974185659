import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';

import queryHelper from '../../lib/queryHelper';

import GET_CONFIGURATION from '../../config/graphql/getConfiguration';

const defaultProps = {
  configuration: {
    campsiteTypes: [],
  },
};

const propTypes = {
  configuration: PropTypes.shape({
    campsiteTypes: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.number,
      value: PropTypes.string,
    })),
  }),
  type: PropTypes.number.isRequired,
};

function CampsiteType(props) {
  const { configuration, type } = props;

  if (!configuration.campsiteTypes.length) return null;

  const campsiteType = configuration.campsiteTypes.find(t => t.key === type);

  if (!campsiteType) return null;

  return (
    <Fragment>
      {campsiteType.value}
    </Fragment>
  );
}

CampsiteType.defaultProps = defaultProps;
CampsiteType.propTypes = propTypes;

export default compose(queryHelper(GET_CONFIGURATION))(CampsiteType);
