import React, { Component } from 'react';
import PropTypes from 'prop-types';

function withRef(WrappedComponent) {
  class WithRef extends Component {
    static propTypes = {
      forwardRef: PropTypes.shape(),
    }

    static defaultProps = {
      forwardRef: null,
    }

    render() {
      const { forwardRef, ...rest } = this.props;
      return <WrappedComponent {...rest} ref={forwardRef} />;
    }
  }

  const forwardRef = (props, ref) => <WithRef forwardRef={ref} {...props} />;

  return React.forwardRef(forwardRef);
}

export default withRef;
