import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';

import { Marker, MarkerClusterer, OverlayView } from '@react-google-maps/api';
import { useRouter } from 'next/router';

import createCamspiteMarker from '../../lib/createCampsiteMarker';
import createCluster from '../../lib/createCluster';
import detectIE from '../../lib/detectIE';
import IbePropTypes from '../../IbePropTypes';

import asyncComponent from '../../hocs/asyncComponent';

import theme from '../../styles/config/theme';
import { ids as campsiteTypes } from '../../config/campsiteTypes';
import { MARKER_Z_INDEX } from './constants';

const DEFAULT_TOUR_ZOOM_LEVEL = 13;

const clusterSizes = [
  42,
  48,
  54,
  60,
  66,
];

const AsyncInfoWindow = asyncComponent(() => import('./InfoWindow'));

const TourMapMarkers = ({
  activePin,
  campsiteId,
  tourCode,
  count,
  loading,
  goToTop,
  isMobile,
  tours,
  client,
  ...props
}) => {
  const router = useRouter();

  const renderItinerary = () => {
    const activeTour = tours.find((tour) => tour.id?.toString() === tourCode);
    if (!activeTour) {
      return null;
    }

    return activeTour.itinerary.map((itineraryItem, index) => {
      const mapPinText = itineraryItem.mapPinText ?? '';
      const { length } = itineraryItem.mapPinText ?? '';
      const isIE = detectIE();

      // Replace non utf-8 hyphen for base64 encoding
      const formattedText = mapPinText.replace('–', '-');

      return (
        <Marker
          icon={createCamspiteMarker({
            active: false,
            color: theme[`COLOR_CAMPSITE_TYPE_${campsiteTypes.OVERSEAS_TOUR}`],
            length,
            text: formattedText,
            isIE,
          })}
          key={`${formattedText}-index`}
          noRedraw
          position={{
            lat: itineraryItem.location?.lat,
            lng: itineraryItem.location?.lon,
          }}
          title={formattedText}
          zIndex={MARKER_Z_INDEX.CAMPSITE_MARKER}
          options={{
            optimized: false,
          }}
        />
      );
    });
  };

  return (
    <>
      {!tourCode ?
        <MarkerClusterer
          enableRetinaIcons
          gridSize={84}
          minimumClusterSize={3}
          styles={clusterSizes.map(size => ({
            textColor: 'white',
            height: size,
            url: createCluster(size),
            width: size,
          }))}
        >
          {clusterer => tours.map((tour) => {
            const active = [campsiteId, activePin].includes(tour.id?.toString());
            const isIE = detectIE();

            const lat = tour.location?.lat;
            const lng = tour.location?.lon;

            return (
              <Marker
                icon={createCamspiteMarker({
                  active,
                  color: theme[`COLOR_CAMPSITE_TYPE_${campsiteTypes.OVERSEAS_TOUR}`],
                  loading: false,
                  length: tour.name?.length,
                  text: tour.name,
                  big: true,
                  isIE,
                })}
                key={tour.id}
                noRedraw
                onClick={() => props.onMarkerClick(tour)}
                position={{ lat, lng }}
                title={typeof tour.name === 'string' ? tour.name : ''}
                clusterer={clusterer}
                zIndex={MARKER_Z_INDEX.CAMPSITE_MARKER}
                options={{
                  optimized: false,
                }}
              >
                {!isMobile && activePin === tour.id?.toString() && (
                <OverlayView
                  position={{ lat, lng }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <AsyncInfoWindow
                    campsiteId={tour.id}
                    campsiteName={tour.name}
                    goToTop={goToTop}
                    hasOffers={false}
                    isPoi={false}
                    lat={lat}
                    lng={lng}
                    memberPrice={tour.fromPrice}
                    onCampsiteSearch={props.onCampsiteSearch}
                    onMoreDetailsClick={props.onMoreDetailsClick}
                    onQueryChange={props.onQueryChange}
                    standardPrice={tour.fromPrice}
                    selectedCampsiteId={campsiteId}
                    type={campsiteTypes.OVERSEAS_TOUR}
                    zoomLevel={DEFAULT_TOUR_ZOOM_LEVEL}
                    router={router}
                    isTours
                    tour={tour}
                    client={client}
                  />
                </OverlayView>
                )}
              </Marker>
            );
          })}
        </MarkerClusterer> : renderItinerary()
      }
    </>
  );
};

TourMapMarkers.propTypes = {
  activePin: PropTypes.string,
  campsiteId: PropTypes.string,
  count: PropTypes.number,
  goToTop: PropTypes.func,
  isMobile: PropTypes.bool,
  loading: PropTypes.bool,
  onCampsiteSearch: PropTypes.func.isRequired,
  onMarkerClick: PropTypes.func.isRequired,
  onMoreDetailsClick: PropTypes.func.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  tours: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.tour)),
  tourCode: PropTypes.string,
  client: PropTypes.shape(IbePropTypes.client),
};

TourMapMarkers.defaultProps = {
  activePin: '',
  campsiteId: '',
  count: null,
  goToTop() { },
  isMobile: false,
  loading: false,
  tours: [],
  tourCode: '',
  client: null,
};

export default memo(withApollo(TourMapMarkers));
