export function parseBounds(bounds) {
  if (!bounds) return {};

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  return {
    ne_lat: ne.lat(),
    ne_lng: ne.lng(),
    sw_lat: sw.lat(),
    sw_lng: sw.lng(),
  };
}

const EARTH_RADIUS_KM = 6378.137;
const MILES_TO_KM_MULTIPLIER = 1.609344;

export function haversineDistanceInKm(position1, position2) {
  const rLat1 = position1.lat() * (Math.PI / 180); // Convert degrees to radians
  const rLat2 = position2.lat() * (Math.PI / 180); // Convert degrees to radians
  const diffLat = rLat2 - rLat1; // Radian difference (latitudes)
  const diffLon =
  (position2.lng() - position1.lng()) * (Math.PI / 180); // Radian difference (longitudes)

  const d = 2 * EARTH_RADIUS_KM * Math.asin(Math.sqrt(
    Math.sin(diffLat / 2)
    * Math.sin(diffLat / 2) + Math.cos(rLat1)
    * Math.cos(rLat2) * Math.sin(diffLon / 2)
    * Math.sin(diffLon / 2),
  ));
  return d;
}

export function getDimensionsFromBounds(bounds) {
  const top = bounds.getNorthEast().lng();
  const left = bounds.getSouthWest().lat();
  const right = bounds.getNorthEast().lat();
  const bottom = bounds.getSouthWest().lng();

  const center = new window.google.maps.LatLng({
    lat: left + ((right - left) / 2),
    lng: top + ((bottom - top) / 2),
  });

  const topLeft = new window.google.maps.LatLng({ lat: left, lng: top });
  const topRight = new window.google.maps.LatLng({ lat: right, lng: top });
  const bottomLeft = new window.google.maps.LatLng({ lat: left, lng: bottom });
  const bottomRight = new window.google.maps.LatLng({ lat: right, lng: bottom });

  const width = haversineDistanceInKm(topLeft, topRight);
  const height = haversineDistanceInKm(topLeft, bottomLeft);

  return {
    bottom,
    bottomLeft,
    bottomRight,
    center,
    height,
    left,
    right,
    top,
    topLeft,
    topRight,
    width,
  };
}

export function scaleBounds(bounds, scalerX, scalerY) {
  const {
    top,
    left,
    right,
    bottom,
    center,
  } = getDimensionsFromBounds(bounds);

  const north = center.lng() + ((top - center.lng()) * scalerY);
  const south = center.lng() + ((bottom - center.lng()) * scalerY);
  const east = center.lat() + ((right - center.lat()) * scalerX);
  const west = center.lat() + ((left - center.lat()) * scalerX);

  return new window.google.maps.LatLngBounds({ lat: west, lng: south }, { lat: east, lng: north });
}

export function canBoundsFitInToKmDiameter(bounds, km) {
  const { width, height } = getDimensionsFromBounds(bounds);
  return width <= km && height <= km;
}

export function scaleBoundsToFitIntoKmDiameter(bounds, km) {
  const { width, height } = getDimensionsFromBounds(bounds);
  const scalerX = km / width;
  const scalerY = km / height;
  return scaleBounds(bounds, scalerX, scalerY);
}

function kmPerDegree() {
  return (1 / (((2 * Math.PI) / 360) * EARTH_RADIUS_KM)); // 1 km in degree;
}

export function getLatitude(distanceInKm, position) {
  return position.lat() + (distanceInKm * kmPerDegree());
}

export function getLongitude(distanceInKm, position) {
  return position.lng() + (distanceInKm * kmPerDegree())
    / Math.cos(position.lat() * (Math.PI / 180));
}

export function getBoundsAroundCentreWithRadius(position, radiusInKm) {
  const north = getLatitude(-radiusInKm, position);
  const east = getLongitude(radiusInKm, position);
  const south = getLatitude(radiusInKm, position);
  const west = getLongitude(-radiusInKm, position);
  const bounds = new window.google.maps.LatLngBounds(
    { lat: west, lng: south },
    { lat: east, lng: north },
  );

  return bounds;
}

export function convertMilesToKm(miles) {
  return miles * MILES_TO_KM_MULTIPLIER;
}

export function locationWithinBounds(
  location,
  bounds,
) {
  const {
    sw_lat: swLat,
    sw_lng: swLng,
    ne_lat: neLat,
    ne_lng: neLng,
  } = bounds ?? {};
  const {
    lat,
    lng,
  } = location ?? {};
  if (!swLat || !swLng || !neLng || !neLat || !lat || !lng || !window.google?.maps?.LatLngBounds) {
    return true;
  }
  const boundsInstance = new window.google.maps.LatLngBounds(
    { lat: swLat, lng: swLng },
    { lat: neLat, lng: neLng },
  );
  return boundsInstance.contains({ lat, lng });
}

// avoid bounds update due to small ui changes in mobile mode
export const shouldBoundsUpdate = (currentBounds, newBounds, differenceThreshold = 0.05) => {
  if (!currentBounds?.sw_lat || !newBounds?.sw_lat) return true;
  const {
    sw_lat: swLat,
    sw_lng: swLng,
    ne_lat: neLat,
    ne_lng: neLng,
  } = currentBounds;
  const {
    sw_lat: newSwLat,
    sw_lng: newSwLng,
    ne_lat: newNeLat,
    ne_lng: newNeLng,
  } = newBounds;

  // When we check the movement of the bounds we need to check relative to the bounds size,
  // therefore a relative movement of 0.5 (5%) registers an update of bounds.
  const latThreshold = Math.abs(Number(swLat) - Number(neLat)) * differenceThreshold;
  const lngThreshold = Math.abs(Number(swLng) - Number(neLng)) * differenceThreshold;

  const swLatDiff = Math.abs(Number(swLat) - Number(newSwLat));
  const swLngDiff = Math.abs(Number(swLng) - Number(newSwLng));
  const neLatDiff = Math.abs(Number(neLat) - Number(newNeLat));
  const neLngDiff = Math.abs(Number(neLng) - Number(newNeLng));
  if (
    swLatDiff > latThreshold ||
    swLngDiff > lngThreshold ||
    neLatDiff > latThreshold ||
    neLngDiff > lngThreshold
  ) {
    return true;
  }
  return false;
};

export const hasMapMoved = (cur, prev) => {
  if (!cur || !prev) {
    return true;
  }

  return cur.lat !== prev.lat ||
    cur.lng !== prev.lng ||
    cur.zoom !== prev.zoom;
};
