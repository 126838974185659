import styled, { css } from 'styled-components';

import MessageComponent from '../ui/Message';
import { Col as ActionCol } from '../ui/Grid';
import StyledButtonBordered from '../ui/Button/ButtonBordered.style';
import StyledButtonBrand from '../ui/Button/ButtonBrand.style';
import Modal from '../ui/Modal';

import { above, below } from '../../styles/libs';
import { multiply } from '../../styles/libs/calculate';

const cssPadding = css`
  ${({ theme }) => css`
    padding: ${theme.PADDING_SMALL};

    ${above(theme.MOBILE_LARGE)`
      padding: ${theme.PADDING};
    `}

    ${above(theme.TABLET)`
      padding: ${theme.PADDING} ${multiply(theme.PADDING, 3)};
    `}

    ${above(theme.DESKTOP)`
      padding: ${theme.PADDING_LARGE} ${multiply(theme.PADDING, 6)};
    `}
  `}
`;

export default styled(Modal)`
  top: 0;
  width: 100%;

  ${({ theme }) => css`
    background-color: ${theme.COLOR_GRAY_LIGHTER};
  `}
`;

export const Header = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  text-align: right;
  z-index: 1;
  ${cssPadding}
`;

export const Body = styled.div`
  ${cssPadding}
`;

export const Close = styled.button`
  position: relative;
  border: 0;
  width: 3rem;
  height: 3rem;
  background-color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
  border-radius: 5px;
  cursor: pointer;
  outline: 0;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: ${({ theme }) => theme.COLOR_WHITE};
    width: 45%;
    height: 0.15rem;
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  ${({ theme }) => css`
    ${above(theme.DESKTOP)`
      width: 3.5rem;
      height: 3.5rem;
      top: 0;
      right: 0;
    `}
  `}
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  min-height: 100%;
  width: 100%;
`;

export const ButtonContainer = styled.div`
    width: 100%;
    margin: 0 auto;
`;

export const Container = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.DESKTOP}px;
  margin: 0 auto;
`;

export const Footer = styled.div`
  position: sticky;
  bottom: 0;

  ${({ theme }) => below(theme.DESKTOP)`
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 1;
    box-shadow: 0px -20px 21px 0px ${theme.COLOR_WARM_BACKGROUND};
  `}

  ${({ theme }) => css`
    background-color: ${theme.COLOR_GRAY_LIGHTER};
  `}

  ${cssPadding}
`;

export const Col = styled(ActionCol)`
  &:first-child {
    display: flex;
    justify-content: flex-end;
  }
`;

const FooterButton = css`
  max-width: 15rem;
`;

export const ButtonClear = styled(StyledButtonBordered)`
  ${FooterButton}
`;

export const ButtonSubmit = styled(StyledButtonBrand)`
  ${FooterButton}
`;

export const Message = styled(MessageComponent)`
  width: fit-content;

  ${({ theme }) => css`
    margin: -${theme.PADDING_SMALL} auto 1.5rem;

    ${above(theme.MOBILE_LARGE)`
      margin: -${theme.PADDING} auto 1.5rem;
    `}

    ${above(theme.DESKTOP)`
      margin: -${theme.PADDING_LARGE} auto 1.5rem;
    `}
  `}
`;
