import styled from 'styled-components';

export default styled.div`
  color: ${({ color }) => color};
`;

export const PriceAmount = styled.span`
  font-size: 1.125rem;
  font-weight: 600;

  @media only screen and (min-width: ${({ theme }) => theme.DESKTOP}px) {
    font-size: ${({ size }) => size};
  }
`;

export const PriceText = styled.span`
  font-size: 0.875rem;

  @media only screen and (min-width: ${({ theme }) => theme.DESKTOP}px) {
    font-size: 0.875rem;
  }
`;
