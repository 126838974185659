import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ListingHighlight from '../Listing/ListingHighlight';
import { List } from '../CampsiteFeature/CampsiteFeature.style';
import {
  Body, Image, Title, TabContentContainer,
  TabContainer, Tabs, Tab, TabContent,
} from './TourItinerary.style';
import Text from '../ui/Text';
import IbePropTypes from '../../IbePropTypes';

const TourItineraryItem = ({ itinerary }) => {
  const [full, setFull] = useState(false);
  const toggleFull = () => setFull(!full);

  const renderItineraryDays = (itineraryDays = []) => itineraryDays
    .map((itineraryDay) => (
      <TabContentContainer>
        <Text weight="bold">{itineraryDay.titleText}</Text>
        <Text>{itineraryDay.description}</Text>
      </TabContentContainer>
    ));
  return (
    <Body>
      <Image
        url={itinerary.imageUrl}
      />
      <Title>{itinerary.titleText}</Title>
      <TabContainer>
        <Tabs>
          <Tab active={!full} onClick={toggleFull}>Overview</Tab>
          <Tab active={full} onClick={toggleFull}>Full itinerary</Tab>
        </Tabs>
        {!full ? (
          <TabContent>
            <List>
              {itinerary.descriptionPoints?.map((descriptionPoint) => (
                <ListingHighlight label={descriptionPoint} small />
              ))}
            </List>
          </TabContent>) :
          (
            <TabContent>
              {renderItineraryDays(itinerary.itineraryDays)}
            </TabContent>)}
      </TabContainer>
    </Body>
  );
};

TourItineraryItem.propTypes = {
  itinerary: PropTypes.shape(IbePropTypes.tourItinerary).isRequired,
};

export default TourItineraryItem;
