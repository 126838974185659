import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import withPhase from '../../hocs/withPhase';
import { dictionaryItem } from '../../hocs/withDictionary';

import Filter from './Filter';
import { ids as campsiteTypeIds } from '../../config/campsiteTypes';
import { FEATURE_FLAGS, getFeatureFlag } from '../../config/featureFlags';

import StyledFiltersList, {
  FilterGroup,
  FilterItem,
  GroupTitle,
} from './FiltersList.style';

const propTypes = {
  aggregations: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    count: PropTypes.number,
  })),
  activeFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeTypes: PropTypes.arrayOf(PropTypes.number).isRequired,
  campsiteFilters: PropTypes.shape().isRequired,
  handleToggleFilter: PropTypes.func.isRequired,
  query: PropTypes.shape({}),
  isPoi: PropTypes.bool,
  isOverseas: PropTypes.bool,
  disabled: PropTypes.bool,
};

const defaultProps = {
  aggregations: [],
  query: {},
  isPoi: false,
  isOverseas: false,
  disabled: false,
};

function FiltersList({
  activeFeatures,
  activeTypes,
  aggregations,
  campsiteFilters,
  handleToggleFilter,
  isPoi,
  isOverseas,
  disabled,
}) {
  const noAggregations = isEmpty(aggregations);

  function getAggregation(featureId) {
    const foundAggregation = aggregations.find(aggregation => aggregation.key === featureId);
    if (foundAggregation) {
      return foundAggregation.count;
    }

    return 0;
  }
  return isPoi ? (
    <StyledFiltersList>
      <GroupTitle
        branded
        dictionary={dictionaryItem('Filters', 'PointsOfInterest')}
      />
      <FilterGroup>
        {campsiteFilters.pointsOfInterestFilterableFeatures.map(({ name, iconUrl, id }) => {
          const active = activeFeatures.some(feature => feature === id);

          return (
            <FilterItem key={id}>
              <Filter
                active={active}
                count={0}
                disabled={disabled}
                handleToggleFilter={handleToggleFilter}
                iconUrl={iconUrl}
                id={id}
                key={id}
                name={name}
                type="features"
              />
            </FilterItem>
          );
        })}
      </FilterGroup>
    </StyledFiltersList>
  ) : (
    <StyledFiltersList>
      {getFeatureFlag(FEATURE_FLAGS.UK_SITES) && (
        <Fragment>
          <GroupTitle>Types</GroupTitle>
          <FilterGroup>
            {campsiteFilters.campsiteTypes.map(({ key, value }) => (
              <FilterItem key={key}>
                <Filter
                  active={activeTypes.indexOf(key) > -1}
                  handleToggleFilter={handleToggleFilter}
                  id={key}
                  name={value}
                  type="types"
                  disabled={disabled || (isOverseas ?
                    key === campsiteTypeIds.OVERSEAS_SITE :
                    key === campsiteTypeIds.UK_CLUB_SITES)}
                />
              </FilterItem>
            ))}
          </FilterGroup>
        </Fragment>
      )}
      {campsiteFilters.onSiteFilterableFeatures.length > 0 &&
        <GroupTitle dictionary={dictionaryItem('Filters', 'OnSite')} />}
      <FilterGroup>
        {campsiteFilters.onSiteFilterableFeatures.map(({ name, iconUrl, id }) => {
          const active = activeFeatures.some(feature => feature === id);
          const count = aggregations ? getAggregation(id) : 0;
          const filterDisabled = !noAggregations && !active && !count;

          return (
            <FilterItem key={id}>
              <Filter
                active={active}
                count={count}
                disabled={disabled || filterDisabled}
                handleToggleFilter={handleToggleFilter}
                iconUrl={iconUrl}
                id={id}
                key={id}
                name={name}
                type="features"
              />
            </FilterItem>
          );
        })}
      </FilterGroup>
      {campsiteFilters.nearbyFilterableFeatures.length > 0 &&
        <GroupTitle dictionary={dictionaryItem('Filters', 'Nearby')} />}
      <FilterGroup>
        {campsiteFilters.nearbyFilterableFeatures.map(({ name, iconUrl, id }) => {
          const active = activeFeatures.some(feature => feature === id);
          const count = aggregations ? getAggregation(id) : 0;
          const filterDisabled = !noAggregations && !active && !count;

          return (
            <FilterItem key={id}>
              <Filter
                active={active}
                count={count}
                disabled={disabled || filterDisabled}
                handleToggleFilter={handleToggleFilter}
                iconUrl={iconUrl}
                id={id}
                key={id}
                name={name}
                type="features"
              />
            </FilterItem>
          );
        })}
      </FilterGroup>
      {campsiteFilters.disabledFilterableFeatures.length > 0 &&
        <GroupTitle dictionary={dictionaryItem('Filters', 'DisabledFacilities')} />}
      <FilterGroup>
        {campsiteFilters.disabledFilterableFeatures.map(({ name, iconUrl, id }) => {
          const active = activeFeatures.some(feature => feature === id);
          const count = aggregations ? getAggregation(id) : 0;
          const filterDisabled = !noAggregations && !active && !count;

          return (
            <FilterItem key={id}>
              <Filter
                active={active}
                count={count}
                disabled={disabled || filterDisabled}
                handleToggleFilter={handleToggleFilter}
                iconUrl={iconUrl}
                id={id}
                key={id}
                name={name}
                type="features"
              />
            </FilterItem>
          );
        })}
      </FilterGroup>
    </StyledFiltersList>
  );
}

FiltersList.propTypes = propTypes;
FiltersList.defaultProps = defaultProps;

export default withPhase(FiltersList, FEATURE_FLAGS.BOOK_UK_CAMPSITES);
