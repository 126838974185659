import styled, { css } from 'styled-components';

export const Article = styled.article`
  border-top: 1px solid ${props => props.theme.COLOR_GRAY_LIGHT};
  margin: 1rem 0;
  padding: 2rem 0 0;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75em;
`;

export const TitleAndDate = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1.5em;
  flex: 1;
  ${props => props.loading && css`
    width: 100%;
    max-width: 55%;
  `}
`;

export const Author = styled.a.attrs(props => ({
  rel: 'author',
}))`
  display: block;
  font-weight: bold;
  margin-top: 0.6em;
`;

export const Main = styled.main``;
