import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';

import queryHelper from '../../lib/queryHelper';

import Rating from '../Rating';
import ReviewArticles from './ReviewArticles';
import ReviewPagination from './ReviewPagination';
import { LoadingBlock } from '../ui/Loading';

import {
  ReviewsWrapper,
  Header,
  Main,
  Footer,
} from './Reviews.style';

import GET_CAMPSITE, { getCampsiteOptions } from '../../config/graphql/getCampsite';

class Reviews extends Component {
  static propTypes = {
    campsiteId: PropTypes.string,
    campsite: PropTypes.shape(),
    scrollWrapper: PropTypes.shape(),
    // NOTE: props used for child state but not needed in view
    /* eslint-disable react/no-unused-prop-types */
    visible: PropTypes.bool,
    /* eslint-enable react/no-unused-prop-types */
  };

  static defaultProps = {
    scrollWrapper: {},
    campsiteId: '',
    campsite: {},
    visible: true,
  };

  state = {
    page: 0,
    pageSize: 6,
    numberOfPages: 0,
    count: 0,
    loading: true,
  };

  setReviewCount = (count) => {
    if (this.state.count === count) return;

    this.setState({
      count,
      page: 0,
      numberOfPages: Math.ceil(count / this.state.pageSize),
      loading: false,
    });
  }

  setLoadingComplete = (loading) => {
    if (this.state.loading === loading) return;

    this.setState({
      loading,
    });
  }

  handlePageChange = (page) => {
    if (this.state.page === page) return;

    this.setState({
      page,
    }, () => {
      if (this.props.scrollWrapper.current) {
        this.props.scrollWrapper.current.scrollTop = 0;
      }
    });
  }

  render() {
    if (!this.props.campsiteId) return null;

    return (
      <ReviewsWrapper>
        {!!this.props.campsite.rating && (
          <Header>
            {this.state.loading ? (
              <Fragment>
                <LoadingBlock width="9rem" />
                <LoadingBlock width="7rem" style={{ marginBottom: '0.25rem' }} />
              </Fragment>
            ) : (
              <Fragment>
                <div>
                  Overall Rating: <strong>{this.props.campsite.rating} / 5</strong>
                </div>
                <Rating
                  noOfReviews={this.props.campsite.numberOfReviews}
                  rating={this.props.campsite.rating}
                  header
                />
              </Fragment>
            )}
          </Header>
        )}
        <Main>
          <ReviewArticles
            campsiteId={this.props.campsiteId}
            page={`${this.state.page}`}
            pageSize={`${this.state.pageSize}`}
            reviewCount={this.setReviewCount}
            prevCount={this.state.count}
            parentLoading={this.state.loading}
            onLoadingComplete={this.setLoadingComplete}
          />
        </Main>
        <Footer>
          <ReviewPagination
            numberOfPages={this.state.numberOfPages}
            currentPage={this.state.page}
            onPageChange={this.handlePageChange}
            parentLoading={this.state.loading}
          />
        </Footer>
      </ReviewsWrapper>
    );
  }
}

export default compose(queryHelper(GET_CAMPSITE, getCampsiteOptions))(Reviews);
