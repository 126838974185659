import { createBase64 } from '../components/Map/MapCrossings/Markers';

const createMyLocationMarker = ({
  color = '#000000',
}) => createBase64(`
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 7 7" fill="${color}" style="pointer-events: none;">
      <animate
          xlink:href="#back"
          attributeName="r"
          from="2.5"
          to="3.5"
          dur="2s"
          begin="0s"
          repeatCount="indefinite"
          id="circ-anim"
      />
      <animate
          xlink:href="#back"
          attributeType="CSS"
          attributeName="opacity"
          from="1"
          to="0"
          dur="2s"
          begin="0s"
          repeatCount="indefinite"
          id="circ-anim"
      />
      <circle id="back" cx="3.5" cy="3.5" r="2" stroke-width="1"/>
      <circle class="front" cx="3.5" cy="3.5" r="2.5" fill="#FFF" />
      <circle id="back" cx="3.5" cy="3.5" r="2" fill="${color}" stroke-width="1"/>
  </svg>
`);

export default createMyLocationMarker;
