import React from 'react';
import PropTypes from 'prop-types';

import { dictionaryItem } from '../../hocs/withDictionary';

import Price from '../Price';

import Text from '../ui/Text';

// TODO: Re-add standard pricing
const propTypes = {
  error: PropTypes.shape({}),
  memberPrice: PropTypes.number,
  loading: PropTypes.bool,
  priceSuffix: PropTypes.string,
  hidePence: PropTypes.bool,
};

const defaultProps = {
  error: null,
  memberPrice: null,
  loading: false,
  priceSuffix: '',
  hidePence: false,
};

function ListingResultPrice(props) {
  if (props.error || !props.memberPrice) {
    return (
      <Text
        dictionary={dictionaryItem('ListingResultPrice', 'PromptCall')}
        size="0.875rem"
        marginBottom
      />
    );
  }

  return (
    <Price
      prefix="From"
      amount={props.memberPrice}
      suffix={props.priceSuffix}
      testingId="listing-result-price__price"
      loading={props.loading}
      hidePence={props.hidePence}
    />
  );
}

ListingResultPrice.propTypes = propTypes;
ListingResultPrice.defaultProps = defaultProps;

export default ListingResultPrice;
