import styled, { css } from 'styled-components';

import { above, below } from '../../styles/libs';

import Icon from '../ui/Icon';
import { Col } from '../ui/Grid';

import { Body as StyledBody } from '../SiteCard/SiteCard.style';
import TitleComponent from '../ui/Title';
import Text from '../ui/Text';
import IconComponent from '../ui/Icon/Icon';
import ButtonBrandStyled from '../ui/Button/ButtonBrand.style';

export default styled.div`
  min-height: 100%;

  ${({
    inView,
    theme,
    loading,
    offset,
  }) => css`
    ${above(theme.TABLET)`
      max-width: 600px;
      min-height: ${loading && '175rem'};
      width: ${theme.TWO_PANEL_WEIGHTED_RIGHT_2};
    `};

    ${above(theme.DESKTOP)`
      width: ${theme.TWO_PANEL_WEIGHTED_RIGHT_1};
      height: calc(100vh - ${offset}px);
    `};

    ${below(theme.DESKTOP)`
      position: ${inView ? 'relative' : 'absolute'};
      max-height: ${inView ? 'none' : '100vh'};
      overflow: ${inView ? 'visible' : 'hidden'};
      top: 0;
    `}
  `}
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.COLOR_WHITE};
`;

export const Body = StyledBody;

export const Padding = styled.div`
  padding: 0.75rem 1.5rem;

  @media only screen and (min-width: ${({ theme }) => theme.DESKTOP}px) {
    padding: 0.875rem 2rem;
  }
`;

export const CampsiteDetails = styled(Padding)`
  border-bottom: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
`;

export const CampsiteTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CampsiteIconsContainer = styled.div`
  display: flex;
  ${({ marginTop }) => marginTop && css`
    margin-top: 0.5rem;
  `}
  ${({ wrap }) => wrap && css`
    flex-wrap: wrap;
  `}
`;

export const CampsiteIcon = styled(IconComponent)`
  color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
  font-size: 2rem;

  ${({ largeIcon }) => largeIcon && css`
    font-size: 4rem;
    max-height: 2rem;
  `}
`;

export const CampsiteTitle = styled(TitleComponent)`
  font-size: 1.375rem;
  font-weight: 600;
`;

export const Address = styled.div`
  display: block;
`;

export const Ref = styled.span`
  font-size: 0.75rem;
`;

export const BookingDetails = styled(Padding)`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border-bottom: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  z-index: 1;
`;

export const Price = styled.div`
  font-size: 0.75rem;
  flex: 1;

  ${({ theme }) => above(theme.MOBILE_LARGE)`
    font-size: 0.875rem;
  `}
`;

export const PriceValue = styled.span`
  font-size: 1.25rem;
  font-weight: 600;
`;

export const ColCTA = styled(Col)`
  ${({ theme }) => below(theme.MOBILE_LARGE)`
    flex: 0 0 7rem;
  `}
`;

export const Tiles = styled.div`
  display: flex;
  width: 100%;
  margin: 0 -1px;
  padding-bottom: 1rem;
`;

export const Tile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  flex: 1;
  margin: 0 1px;
  padding: 0.75rem 0;

  @media only screen and (min-width: ${({ theme }) => theme.DESKTOP}px) {
    padding: 1.5rem 0;
  }
`;

export const TileIcon = styled(Icon)`
  font-size: 2rem;

  @media only screen and (min-width: ${({ theme }) => theme.DESKTOP}px) {
    font-size: 3rem;
  }
`;

export const TileText = styled.div`
  font-size: 0.875rem;
`;

export const TileNumber = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  color: ${({ theme }) => theme.COLOR_BRAND_PRIMARY_DARK};
`;

export const Title = styled(TitleComponent)`
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: center;

  ${({ theme }) => above(theme.DESKTOP)`
    text-align: left;
  `}
`;

export const Button = styled.button`
  display: block;
  height: 1.125rem;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  outline: none;

  &:not(:disabled) {
    cursor: pointer;
  }
`;

export const LowAvailability = styled(Text)`
  left: 0;
  margin-bottom: -0.5rem;
  font-size: 0.85rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.COLOR_LAKE_GREEN_DARK};
`;

export const ViewOnMap = styled(ButtonBrandStyled)`
  ${({ marginTop }) => marginTop && css`
    margin-top: 0.5rem;
  `}
  ${({ theme }) => above(theme.TABLET)`
    display: none;
  `}
`;
