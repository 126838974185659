import React from 'react';
import PropTypes from 'prop-types';

import { Text, ListItem } from '../CampsiteFeature/CampsiteFeature.style';
import CampsiteFeatureIconStyled from '../CampsiteFeature/CampsiteFeatureIcon.style';
import CampsiteFeatureIcon from '../CampsiteFeature/CampsiteFeatureIcon';
import checkSVG from '../../static/images/icons/ListItemTick.svg';

const ListingHighLight = ({
  onClick, label, small, check, compact, labelSize, icon,
}) => (
  <ListItem onClick={onClick} spaced={!!check} compact={compact}>
    {check ? (
      <CampsiteFeatureIconStyled
        icon={icon || checkSVG}
        small={small}
        marginRight="1rem"
      />
    ) : (
      <CampsiteFeatureIcon small={small} />
    )}
    <Text size={labelSize}>{ label }</Text>
  </ListItem>
);

ListingHighLight.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  check: PropTypes.bool,
  compact: PropTypes.bool,
  labelSize: PropTypes.string,
  icon: PropTypes.string,
};

ListingHighLight.defaultProps = {
  onClick: undefined,
  small: false,
  check: undefined,
  compact: false,
  labelSize: '',
  icon: '',
};

export default ListingHighLight;
