import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import { ApolloConsumer } from 'react-apollo';

import { uniq } from 'lodash';

import { dictionaryItem } from '../../hocs/withDictionary';

import updateRouterQuery from '../../lib/updateRouterQuery';

import SearchResultsEmptyStyled, { Text } from './SearchResultsEmpty.style';
import { ButtonBrand } from '../ui/Button';
import ErrorTypeMessage from '../ErrorTypeMessage/ErrorTypeMessage';

import { getNearestCampsitesOptions, GET_NEAREST_BOUNDARIES } from '../../lib/nearestCampsites';
import routes from '../../constants/routes';
import { searchByType } from '../../constants/search';
import IbePropTypes from '../../IbePropTypes';

class SearchResultsEmpty extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    query: PropTypes.shape({}).isRequired,
    router: PropTypes.shape(IbePropTypes.router).isRequired,
  }

  state = {
    loading: false,
    error: null,
  }

  handleAlternativeSearch = async (client) => {
    // Reset error and loading state
    this.setState({ error: null, loading: true });

    // Get query options
    const { variables, ...options } = getNearestCampsitesOptions(this.props.query);

    // Fetch boundaries for nearest campsite
    const { data, ...response } = await client.query({
      query: GET_NEAREST_BOUNDARIES,
      variables,
      ...options,
      fetchPolicy: 'network-only',
    });

    const { loading } = response;
    let { error } = response;

    // Check response has returned data
    if (!data.nearestBoundaries) {
      error = new Error('There was an error fetching nearby campsites.');
    }

    // Check lat/lng values are all unique
    const values = Object.values(data.nearestBoundaries);
    if (uniq(values).length !== values.length) {
      error = new Error('There was an error fetching nearby campsites.');
    }

    // Handle client and remote errors
    if (error) {
      this.setState({ error, loading });
      return;
    }

    const query = {
      ne_lat: data.nearestBoundaries.bottomRightLat,
      ne_lng: data.nearestBoundaries.topLeftLon,
      sw_lat: data.nearestBoundaries.topLeftLat,
      sw_lng: data.nearestBoundaries.bottomRightLon,
      location: null,
      searchBy: searchByType.LOCATION,
      isNearby: true,
    };

    // If the current search is based on location, retain the center lat/lng and location value
    if (this.props.router.query.location) {
      query.centerLat = this.props.router.query.centerLat;
      query.centerLng = this.props.router.query.centerLng;
      query.location = this.props.router.query.location;
    }

    // Update the query with the new boundaries
    await updateRouterQuery(routes.sites, query);
    this.props.onSubmit(query);
  }

  render() {
    const { error, loading } = this.state;
    return (
      <SearchResultsEmptyStyled>
        <Text dictionary={dictionaryItem('SearchResultsEmpty')} />

        <ApolloConsumer>
          {client => (
            <ButtonBrand
              disabled={loading}
              onClick={() => this.handleAlternativeSearch(client)}
              size="large"
              dictionary={dictionaryItem('SearchResultsEmpty')}
            />
          )}
        </ApolloConsumer>

        {error &&
          <ErrorTypeMessage error={error} marginTop>
            {error.message}
          </ErrorTypeMessage>
        }
      </SearchResultsEmptyStyled>
    );
  }
}

export default withRouter(SearchResultsEmpty);
