import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import LoadingSpinner from '../ui/Loading/LoadingSpinner';
import SearchResultsEmptyStyled from '../SearchResults/SearchResultsEmpty.style';
import { ButtonBrand } from '../ui/Button';
import Text from '../ui/Text';
import { ListingItem } from '../Listing';

import { dictionaryItem } from '../../hocs/withDictionary';
import IbePropTypes from '../../IbePropTypes';
import updateRouterQuery from '../../lib/updateRouterQuery';
import { parseBounds } from '../../lib/query';
import routes from '../../constants/routes';
import { dataLayerManager } from '../../lib/dataLayer';
import { searchByType } from '../../constants/search';
import { scrollToActive } from '../../lib/helpers/campsites';

function CampsiteTours(props) {
  const {
    onNearby,
    tours,
    loading,
    query,
    activePin,
    isMobile,
  } = props;

  const locationSearch = !!query.location;
  const monthSearch = !!query.departureMonth;
  const durationSearch = !!query.duration;

  const divWrapperRef = useRef(null);

  useEffect(() => {
    scrollToActive(divWrapperRef, isMobile, activePin);
  }, [activePin]);

  useEffect(() => {
    if (!locationSearch && (monthSearch || durationSearch)) {
      if (window.google.maps?.LatLngBounds) {
        const bounds = new window.google.maps.LatLngBounds();
        tours.slice(0, 2).forEach((tourItem) => {
          if (tourItem.location?.lat && tourItem.location?.lon) {
            bounds.extend({
              lat: tourItem.location?.lat,
              lng: tourItem.location?.lon,
            });
          }
        });
        updateRouterQuery(routes.sites, {
          ...parseBounds(bounds),
          searchBy: searchByType.LOCATION,
          zoomLevel: null,
        });
      }
    }
  }, [query.departureMonth, query.duration]);

  useEffect(() => {
    if (tours?.length) {
      const impressionData = tours.map(({ id, fromPrice, name }) => {
        const data = {
          id,
          price: fromPrice,
          name,
          subCategory: dataLayerManager.subCategory.EUROPEAN_TOUR,
          ...query,
        };
        return data;
      });
      dataLayerManager.pushImpressions(dataLayerManager.subCategory.EUROPEAN_TOUR, impressionData);
    }
  }, [tours]);

  const handleResultClick = (tour, index) => {
    dataLayerManager.pushProductClick(
      dataLayerManager.subCategory.EUROPEAN_TOUR, {
        ...query,
        ...tour,
        subCategory: dataLayerManager.subCategory.EUROPEAN_TOUR,
      }, index,
    );
    props.onResultClick(tour);
  };

  let emptyLabel = 'Location';

  if (locationSearch) {
    emptyLabel = 'Location';
  } else if (monthSearch) {
    emptyLabel = 'Month';
  } else if (durationSearch) {
    emptyLabel = 'Duration';
  }

  if (loading) {
    return <LoadingSpinner dictionary={dictionaryItem('SearchResults', 'Campsite', 'Fetching')} />;
  }
  if (!loading && !tours.length) {
    return (
      <SearchResultsEmptyStyled>
        <Text dictionary={dictionaryItem('SearchResultsEmpty', emptyLabel)} marginBottom="1rem" />
        {!monthSearch && !durationSearch &&
        <ButtonBrand
          disabled={loading}
          onClick={onNearby}
          size="large"
          dictionary={dictionaryItem('SearchResultsEmpty', 'Tours')}
        />}
      </SearchResultsEmptyStyled>
    );
  }

  return (
    <div ref={divWrapperRef}>
      {tours.map((item, index) => (
        <ListingItem
          {...item}
          index={index}
          key={item?.id}
          loadingCampsites={loading}
          onResultClick={(tour) => handleResultClick(tour, index)}
          activePin={activePin}
          tour={item}
          isTours
          showDates={!!query.departureMonth}
          loadingPrices={false}
          isFeatured={false}
          departureMonth={query.departureMonth}
        />
      ))}
    </div>
  );
}

CampsiteTours.propTypes = {
  toggleBasket: PropTypes.func.isRequired,
  onResultClick: PropTypes.func.isRequired,
  onNearby: PropTypes.func.isRequired,
  activePin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onProductTypeMismatch: PropTypes.func,
  tours: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.tour)),
  loading: PropTypes.bool,
  departureMonth: PropTypes.string,
  query: PropTypes.shape(IbePropTypes.query),
  isMobile: PropTypes.bool,
};

CampsiteTours.defaultProps = {
  tours: [],
  loading: false,
  onProductTypeMismatch: null,
  activePin: null,
  departureMonth: '',
  query: {},
  isMobile: false,
};

export default CampsiteTours;
