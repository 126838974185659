import styled from 'styled-components';
import StyledText from '../ui/Text';
import { above } from '../../styles/libs';

export const Text = styled(StyledText)`
  margin-bottom: 1rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;

  ${({ theme }) => above(theme.TABLET)`
    flex-flow: row nowrap;
  `}
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex: 0 0 4.75rem;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  ${({ src }) => (src ? `background-image: url(${src});` : null)}
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  height: 100px;
`;

export const ContentWrapper = styled.div`
  margin: 1rem 0;

  ${({ theme }) => above(theme.TABLET)`
    margin: 1rem 2rem;
  `}
`;

export const TermsLink = styled.a`
  font-size: 0.75rem;
  text-align: left;
  text-decoration: underline;
`;

export default null;
