import styled from 'styled-components';

import TextStyled from '../ui/Text/Text.style';

export default styled.div`
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled(TextStyled)`
  color: ${({ theme }) => theme.COLOR_TEXT_MUTED};
  font-size: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  max-width: 18rem;
`;
