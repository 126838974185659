import styled, { css } from 'styled-components';

import { above, below } from '../../styles/libs';
import detectIE from '../../lib/detectIE';

import ButtonBordered from '../ui/Button/ButtonBordered.style';

export default styled.div`
  ${({ backgroundImage, plainStyling, theme }) => !plainStyling && backgroundImage && css`
    background-color: ${theme.COLOR_GRAY_DARK};
    background-image: url(${backgroundImage});
    background-position: center;
    background-size: cover;
  `}
  ${({ plainStyling }) => plainStyling && css`
    border: 1px solid ${({ theme }) => theme.COLOR_WHITE};
    border-radius: 5px;
    margin: 4.25rem 0 1.25rem;
    ${({ theme }) => above(theme.DESKTOP)`
      margin: 5.5rem 0 0;
    `}
  `}
  color: ${({ theme }) => theme.COLOR_WHITE};
  display: flex;
  flex-flow: column;
  padding: ${({ theme }) => theme.PADDING};
  ${({ theme }) => css`
    ${above(theme.TABLET)`
      flex-flow: row;
    `}
  `}
`;

export const Details = styled.div`
  ${({ theme, plainStyling }) => css`
    ${!plainStyling && above(theme.TABLET)`
      padding-right: ${theme.PADDING};
    `}
    ${!plainStyling && above(theme.DESKTOP)`
      padding-right: ${theme.PADDING_LARGE};
    `}
  `}
`;

export const CTA = styled.div`
  display: flex;
  align-items: flex-end;
  ${({ theme }) => css`
    ${below(theme.TABLET)`
      padding-top: ${theme.PADDING};
    `}
    ${above(theme.TABLET)`
      padding-left: ${theme.PADDING};
    `}
    ${above(theme.DESKTOP)`
      padding-left: ${theme.PADDING_LARGE};
    `}
  `}
  ${!!detectIE() && `
    min-width: 140px;
  `}
`;

export const Button = styled(ButtonBordered)`
  white-space: nowrap;
`;
